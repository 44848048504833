import { Menu, MenuItem, Typography } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import classNames from 'classnames'
import React, { useState } from 'react'

import { useUserData } from '../../../context/UserContext'
import { ListFavorites } from '../../Product/ListFavorites'
import useStyles from '../styles'

const Favorites = () => {

    const classes = useStyles()
    const [favoritesMenu, setFavoritesMenu] = useState(null)
    const min900 = useMediaQuery('(min-width:900px)')
    const { isAnonymous } = useUserData()
    const isAuthenticated = !isAnonymous


    if (!isAuthenticated) {
        return null
    }

    return (
        min900 && <>
            <MenuItem
                onClick={e => setFavoritesMenu(e.currentTarget)}
                className={classNames(
                    classes.profileMenuItem,
                    classes.headerMenuItem
                )}
            >
                <FavoriteBorderIcon className={classes.profileMenuIcon} />
                <Typography style={{ color: '#333' }}>Mis favoritos</Typography>
            </MenuItem>
            <Menu
                id="favorites-menu"
                open={Boolean(favoritesMenu)}
                anchorEl={favoritesMenu}
                onClose={() => setFavoritesMenu(null)}
                className={classes.headerMenu}
                classes={{ paper: classes.profileMenu }}
                disableAutoFocusItem
            >
                <div>
                    <ListFavorites />
                </div>
            </Menu>
        </>
    )
}

export default Favorites
