import classnames from 'classnames'
import { SnackbarProvider } from 'notistack'
import React, { useMemo } from 'react'
// import { useEffect } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'

// import { useHistory } from 'react-router-dom'
import config from '../../config/config'
// context
import {
  HideButtonShowProducts,
  HideFooter,
  useLayoutState,
} from '../../context/LayoutContext'
// import { useStores } from '../../context/UserContext'
import Categories from '../../pages/categories/categories'
// pages
import Homepage from '../../pages/homepage'
import MyOrders from '../../pages/myorders'
import Order from '../../pages/myorders/Order'
import MyStores from '../../pages/myStores/MyStores'
import NewStore from '../../pages/newStore'
import BuyProcess from '../../pages/orderProcess'
import Product from '../../pages/product'
import Products from '../../pages/products/Products'
import Profile from '../../pages/profile'
import Stores from '../../pages/stores'
import Footer from '../Footer'
// components
import Header from '../Header'
import StoreDetails from '../Store/StoreDetails'
import { useChangeTitle } from '../Utils/useChangeTitle'
import ScrollToTop from './ScrollToTop'
// styles
import useStyles from './styles'

function Layout() {
  const classes = useStyles()

  // global
  const layoutState = useLayoutState()
  // const history = useHistory()

  // const stores = useStores()

  // useEffect(() => {
  //   if (
  //     stores?.length &&
  //     config.show_prices_only_validated_store &&
  //     !stores?.some((a) => a.validated)
  //   ) {
  //     history.push('/app/newStore')
  //   }
  // }, [stores])

  return (
    <>
      <div className={classes.root}>
        <>
          <Header />

          <div className={classnames(classes.content, classes.contentShift)}>
            <div className={classes.fakeToolbar} />

            {layoutState.showSearchBar && (
              <div className={classes.fakeSearchBar} />
            )}
            <SnackbarProvider maxSnack={3}>
              <ScrollToTop />
              <Switch>
                <Route path="/app/homepage"
                  component={Homepage} />
                <Route path="/app/products"
                  component={ProductsPage} />
                <Route path="/app/product/:name/:productId"
                  component={ProductsPage} />
                <Route path="/app/product/:productId"
                  component={ProductsPage} />
                <Route path="/app/buy"
                  component={BuyProcess} />
                <Route path="/app/myorders"
                  component={MyOrders} />
                <Route path="/app/profile"
                  component={Profile} />
                <Route path="/app/newStore"
                  component={NewStore} />
                <Route path="/app/myStores"
                  component={MyStores} />
                <Route path="/app/stores"
                  component={Stores} />
                <Route path="/app/storesDetail"
                  component={StoreDetails} />
                <Route path="/app/categories"
                  component={Categories} />
                <Route
                  path="/app/order/:orderId"
                  component={({
                    match: {
                      params: { orderId },
                    },
                  }) => Order({ orderId })}
                />
              </Switch>
            </SnackbarProvider>

            {layoutState.showFooter && (
              <>
                <div className={classes.fakeFooter} />
                <Footer />
              </>
            )}
          </div>
        </>
      </div>
    </>
  )
}

function ProductsPage({ location }) {
  HideFooter(location.pathname === '/app/products')
  HideButtonShowProducts(location.pathname === '/app/products')
  const products = useMemo(() => <Products />, [])
  config?.plant_web_view === true
    ? useChangeTitle('Productos - Plant')
    : useChangeTitle('Productos - Plant business')
  return (
    <>
      {location.pathname.includes('/app/product/') && <Product />}
      <div
        style={{
          display: location.pathname.includes('/app/products')
            ? 'block'
            : 'none',
        }}
      >
        {products}
      </div>
    </>
  )
}

export default withRouter(Layout)
