import { Box, Paper, Tab, Tabs } from '@material-ui/core'
//components
import Button from '@material-ui/core/Button'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import LoginModal from '../../../components/loginModal'
import { numberWithCommas } from '../../../components/Utils/NumberWithCommas'
import { useCartContext } from '../../../context/CartContext'
import { usePriceList } from '../../../context/PriceListContext'
//context
import { useUserData } from '../../../context/UserContext'
import CartForLater from './cartForLater'
import CartList from './CartList'
import CuadroCarrito from './CuadroCarrito'
import { MessageFeedbackCart } from './messageFeedbackCart'
// styles
import useStyles from './styles'

export default function Cart() {
    const classes = useStyles()
    const cart = useCartContext()
    const history = useHistory()
    const priceList = usePriceList()
    const { isAnonymous, cartForLater } = useUserData()
    const isAuthenticated = !isAnonymous
    const [CartListEl, totals] = CartList()
    const [section, setSection] = useState(0)

    const handleChangeSection = (event, newValue) => {
        setSection(newValue)
    }



    const iva_included = priceList?.iva_included
    const total = iva_included ? totals?.totalProducts : (totals?.totalProducts - totals?.iva)
    const shipping_cost = priceList?.purchasemin > total ? priceList?.shipping_cost : 0
    const purchaseMinRequired_KO = !total || (((priceList?.purchaseMinRequired && priceList?.purchasemin) || 0) >= total)
    const matches = useMediaQuery('(min-width:600px)')

    const shippingEl =
        <div className={classes.shippingCardContainer}>
            <Paper className={classes.shippingCard} >
                <div className={classes.shipping_time} >
                    <div className={classes.summary_sticky}>
                        <div className={classes.shipping_cost}>Envío: {shipping_cost ? <>$ {shipping_cost}</> : 'Gratis'}
                            {!!shipping_cost &&
                                <span style={{ fontSize: 12 }}>
                                    <br />
                                    (Comprando $ {numberWithCommas(priceList?.purchasemin || 0, 0)}{!priceList?.iva_included && ' +IVA'} o más obtenes ENVÍO GRATIS)
                                </span>
                            }
                        </div>
                    </div>
                </div>
            </Paper>
        </div>
    const priceListNotes = priceList?.cartNotes &&
        <div className={classes.priceListNotes}>{priceList?.cartNotes}</div>
    const summary = <div style={{ position: 'sticky' }}>
        {shippingEl}
        {priceListNotes}
        <div className={classes.summary_sticky}>
            <Total shipping_cost={shipping_cost}
                classes={classes}
                totals={totals} />

            <div className={classes.cartElement}>
                {(!isAuthenticated || false) ? <LoginModal fullWidth
                    text={'Iniciar Sesión para Continuar'} /> :
                    <>
                        {!!total &&
                            purchaseMinRequired_KO
                            && <div className={classes.purchaseMinRequired}>Mínimo de compra: $ {numberWithCommas(priceList?.purchasemin, 0)}</div>}
                        {priceList.name === 'Empleados' ?
                            <Button style={{ backgroundColor: 'rgb(141 187 54)', color: '#fff' }}
                                disabled={(!Object.keys(cart || {}).length) || purchaseMinRequired_KO}
                                onClick={() => {
                                    history.push('/app/buy/takeaway')
                                }}
                                fullWidth
                                variant="contained"
                            >Continuar</Button> :
                            <Button style={{ backgroundColor: 'rgb(141 187 54)', color: '#fff' }}
                                disabled={(!Object.keys(cart || {}).length) || purchaseMinRequired_KO}
                                onClick={() => {
                                    history.push('/app/buy/addressselect')
                                }}
                                fullWidth
                                variant="contained"
                            >Continuar</Button>
                        }
                    </>
                }
            </div>
        </div>
    </div>

    return <div style={{ width: '100%' }}>
        <Tabs style={{ maxWidth: 750, margin: '0 auto' }}
            value={section}
            onChange={handleChangeSection}
            aria-label="simple tabs example">
            <Tab label="Carrito"
                {...a11yProps(0)} />
            <Tab label="Guardados"
                {...a11yProps(1)} />
        </Tabs>
        <TabPanel section={section}
            index={0}>
            {cart && Object.keys(cart).length ?
                matches ?
                    <div className={classes.root}
                        style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between', minHeight: '28vh' }}>
                        <div style={{ width: '42%', margin: '25px' }}>
                            <CuadroCarrito />
                        </div>
                        <div style={{ width: '55%' }}>
                            {CartListEl}
                        </div>
                    </div> :
                    <div className={classes.root}>
                        {/* Cuando hay +5 arriba */}
                        {cart && Object.keys(cart).length >= 3 && summary}
                        {CartListEl}
                        {cart && Object.keys(cart).length < 3 && summary}
                    </div>
                :
                <MessageFeedbackCart />

            }
        </TabPanel>
        <TabPanel section={section}
            index={1}>
            {cartForLater && Object.keys(cartForLater).length ? <CartForLater /> : <MessageFeedbackCart cartForLater={true} />}
        </TabPanel>
    </div>
}

export function Total({ classes, totals, title, shipping_cost = 0 }) {
    const priceList = usePriceList()
    const iva_included = priceList.iva_included
    const total = (iva_included ? totals?.totalProducts : (totals?.totalProducts - totals?.iva))

    return <div className={classes.priceTotalRoot}>
        <div className={classes.priceTotalTitle}>{title || 'TOTAL'}</div>
        <div className={classes.priceTotal}>
            <span>
                $ {numberWithCommas(Math.floor(total), 0)}
            </span>
            <span className={classes.priceTotalDecimals}>
                {pad(((total % 1) * 100).toFixed(0), 2)}
            </span>
            {!iva_included && <span className={classes.priceTotalPlusIVA}>
                +IVA
            </span>
            }
            {!!shipping_cost && <span className={classes.priceTotalPlusIVA}>
                +envío
            </span>
            }
        </div>
    </div>
}

function pad(num, size) {
    let s = num + ''
    while (s.length < size) s = '0' + s
    return s
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

function TabPanel(props) {
    const { children, section, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={section !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {section === index && (
                <Box>{children}</Box>
            )}
        </div>
    )
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    section: PropTypes.any.isRequired,
}


// function ProductCard({hit,classes,inCart}) {
//
//     const cartUpdate = useCartDataSetContext();
//
//     const content = useMemo(()=>
//         <Grid item xs={4} className={classes.cardMediaContent}>
//             <Link className={classes.link} to={'/app/product/'+hit.objectID} >
//             <CardMedia
//                 className={classes.thumbnail}
//                 image={hit.thumbnail}
//                 title={hit.name}
//             />
//         <CardMedia
//             className={classes.media}
//             image={hit.image}
//             title={hit.name}
//         />
//             </Link>
//         </Grid>
//     ,[hit,classes]);
//
//
//
//     const actionButtons = <>
//         {   !inCart
//             : <div className={classes.btnBuyed}>
//                 <div>{inCart}</div>
//             </div>
//         }
//     </>;
//
//     return (<div className={classes.cardContent}>
//             <div className={classes.deleteProduct} onClick={() => cartUpdate({product:hit,q:0})} ><FontAwesomeIcon icon={faTimesCircle} /></div>
//             <Grid container spacing={2}>
//                 {content}
//             <Grid item xs={8}>
//                     <div className={classes.titleCard}>
//                         <Typography className={classes.title} fontWeight={500} color="primary" component="p">
//                             {hit.name.toUpperCase()}
//                         </Typography>
//                         <Typography variant="subtitle2" color="textSecondary" component="p">
//                             {hit.taste}
//                         </Typography>
//                     </div>
//                 <PricesContent hit={hit} classes={classes}/>
//                 <div className={classes.bottomElements}>
//                     <Grid container>
//                         <Grid item xs={5}>
//                             {actionButtons}
//                         </Grid>
//                         <Grid item xs={7}>
//                             <div className={classes.productTotal}>
//                             <PriceTotalPerProduct hit={hit} q={inCart}/>
//                             </div>
//                         </Grid>
//                     </Grid>
//                 </div>
//             </Grid>
//         </Grid>
//         </div>
//     );
// }

// function PricesContent({hit,classes}) {
//
//     const prices = GetPrices({ product:hit });
//     return <div className={classes.price}>
//                 <span className={classNames('MuiTypography-colorPrimary')}>
//                         ${(prices.price).toFixed(2).replace('.',',')}
//                 </span>
//         {prices.suggested ?
//             <span className={classes.priceSuggested}>
//                         <span>Sugerido al Público: </span><br/>
//                     ${(prices.suggested).toFixed(0).replace('.',',')}
//                 </span>
//             :
//             prices.original &&
//             <span className={classes.priceStrikethrough}>
//                     ${(prices.original).toFixed(0).replace('.',',')}
//                 </span>}
//     </div>
// }
