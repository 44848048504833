import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import { makeStyles } from '@material-ui/styles'

import config from '../../config/config'
import { useUserData, useUserDataSet } from '../../context/UserContext'
import { useStores } from '../../context/UserContext'
import { FieldValue } from '../../firebase/database'

const useStyles = makeStyles(() => ({
  favorite: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    padding: '.2rem',
    top: '8px',
    left: '8px',
    zIndex: 100,
  },
}))

export const AddFavorite = ({ product, pointer }) => {
  const stores = useStores()

  const { isAnonymous, favorites } = useUserData()

  const showFavorites = !isAnonymous && (!config.show_prices_only_validated_store || stores?.some?.((s) => s.validated))

  const userDataSet = useUserDataSet()

  const isFavorite = !!favorites?.[product.objectID]
  const favoriteHandler = (productID) => {
    !isAnonymous && userDataSet({ ['favorites.' + productID]: isFavorite ? FieldValue.delete() : (new Date() / 1000) | 0 }, 'update')
  }
  const classes = useStyles()
  return (
    <>
      {showFavorites ? (
        isFavorite ? (
          <div
            className={classes.favorite}
            style={{ cursor: `${pointer}` }}
          >
            <FavoriteIcon
              style={{ color: '#FF5014' }}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                favoriteHandler(product.objectID)
              }}
            />
          </div>
        ) : (
          <div
            className={classes.favorite}
            style={{ cursor: `${pointer}` }}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              favoriteHandler(product.objectID)
            }}
          >
            <FavoriteBorderIcon style={{ color: '#FF5014' }} />
          </div>
        )
      ) : (
        <></>
      )}
    </>
  )
}
