import React from 'react'

import config from '../../config/config'
import whatsappLogo from '../../images/whatsapp-logo.svg'


export default function WhatsappButton() {
        const phoneNumber = config.phoneNumber || '541125233691'

        return <div onClick={() => window.open(`https://wa.me/${phoneNumber}`, '_blank')}
                style={{ cursor: 'pointer', marginTop: '7px', marginRight: '10px' }}>
                <img alt={'whatsappLogo'}
                        src={whatsappLogo}
                        style={{ height: '25px', width: '25px' }}
                />
        </div>
}
