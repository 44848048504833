import { Dialog, DialogContent, useMediaQuery } from '@material-ui/core'

import imgurWebp from '../../../components/Utils/imgurWebp'
import config from '../../../config/config'
import { useUserData } from '../../../context/UserContext'
import { updateDoc } from '../../../firebase/database'

export const ModalNotification = ({hit}) => {
  const { sawModalNotification, uid } = useUserData()
  const media640 = useMediaQuery('(min-width:640px)')

  const handleToggle = async () => {
    await updateDoc({
      indexName: 'users',
      id: uid,
      hit: {
        sawModalNotification: hit.name,
      },
    })
  }

  if (!uid || config.plant_web_view || sawModalNotification === hit.name || !hit.image) {
    return null
  }

  return (
    <Dialog
      fullWidth={true}
      onClose={handleToggle}
      maxWidth={media640 ? 'sm' : 'xs'}
      aria-labelledby="simple-dialog-title"
      open={true}
    >
      <DialogContent
        style={{
          paddingBottom: 0,
          paddingTop: 0,
        }}
      >
        <div
          style={{
            marginLeft: media640 ? 20 : 0,
            backgroundImage: `url(${imgurWebp(hit.image)})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'top left',
            height: !media640 ? 250 : 500,
            width: !media640 ? 250 : 500,
          }}
        ></div>
      </DialogContent>
    </Dialog>
  )
}
