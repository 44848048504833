import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

// import { Button } from '../../../../components/Wrappers'
import { useUserData } from '../../../../context/UserContext'
import { db } from '../../../../firebase'
import pinIcon from '../../../../images/pin.svg'
import useStyles from './styles'

export default function ConfirmNewAddress({ address, addressDispatch, isPushDisable = false, close }) {
    const classes = useStyles()
    const { uid } = useUserData()

    useEffect(() => {
        const google = window.google
        if (google) {
            const map = new google.maps.Map(document.getElementById('map'), {
                center: { lat: -34.7833, lng: -58.4 },//center {lat: -34.7729, lng: -58.3984},
                zoom: 13,
                mapTypeId: 'roadmap'
            })

            var icon = {
                url: pinIcon,
                size: new google.maps.Size(71, 71),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(17.5, 35),
                scaledSize: new google.maps.Size(35, 35)
            }

            let markers = []
            // Create a marker for each place.
            markers.push(new google.maps.Marker({
                map: map,
                icon: icon,
                draggable: false,
                animation: google.maps.Animation.DROP,
                title: address.name,
                position: address.geometry.location
            }))

            const bounds = new google.maps.LatLngBounds()

            if (address.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(address.geometry.viewport)
            } else {
                bounds.extend(address.geometry.location)
            }

            map.fitBounds(bounds)

            // const searchBox = new window.google.maps.places.Autocomplete(
            //     document.getElementById('inpMap'), {
            //         componentRestrictions: {country: 'ar'}
            //     });
            //
            // searchBox.addListener('place_changed', function() {
            //
            //
            //     const place = searchBox.getPlace();
            //     const newAddress = JSON.parse(JSON.stringify(place));
            //     console.log(newAddress);
            //     setInpMapValue(newAddress.formatted_address);
            // })
        }
    }, [address.geometry, address.name])
    const [details, setDetails] = useState('')

    const GoogleMap = useMemo(() => <div className={classes.map}><div id="map"
        className={classes.googleMap} /></div>, [classes.googleMap, classes.map])

    const history = useHistory()

    const confirmAddress = () => {
        !isPushDisable && history.push('/app/buy/shipoptionselect')
        let addressDoc = { ...address, details }
        addressDoc.users = [uid]
        addressDoc.last_used = new Date()
        db.collection('addresses').add(addressDoc).then((doc) => {
            // app.addresses.data[doc.id]=addressDoc;
            // setShippingAddress('addresses',doc.id)
            addressDispatch({ ...address, id: doc.id, details })
        })
    }


    return <>
        {GoogleMap}

        < TextField multiline
            label={'Detalles'}
            value={details}
            onChange={e => setDetails(e.target.value)}
            className={classes.detailsField}
            fullWidth
            onKeyPress={e => e.key === 'Enter' && e.target.blur()}
            helperText={'Piso, Depto, Local o Cualquier informacion que nos ayude a encontrar la dirección'}
            autoFocus

            // onBlur={e=>e.target.value!==hit[f.field]&&update({field:f.field,value:e.target.value})}
            rowsMax="4"
            variant="outlined" />
        <div className={classes.confirmButton}>
            <Button startIcon={<FontAwesomeIcon icon={faTimes} />}
                onClick={() => isPushDisable ? close() : history.goBack}
                variant="contained"
                color="warning">Cancelar</Button>
            <Button startIcon={<FontAwesomeIcon icon={faCheck} />}
                onClick={confirmAddress}
                variant="contained"
                color="secondary">Confirmar</Button>
        </div>

    </>
}
