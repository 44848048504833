/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-console */
/* eslint-disable no-undef */
import React, { createContext, useEffect, useState } from 'react'
import {useGoogleMapsApi} from 'react-hook-google-maps'

import config from '../config'
import { db } from '../firebase'
import LoadingPage from '../pages/loading'
import { checkContainsLocation } from '../pages/orderProcess/address'
import { useSelectedAddress, useUserData, useUserDataSet } from './UserContext'

const MarketContext = createContext()

function MarketProvider({ children }) {
    const [marketData, setMarketData] = useState(JSON.parse(localStorage?.getItem('marketData') || '{ "seller": "v", "loading": true }'))
    const user = useUserData()
    const marketconfig = user.marketconfig || 'v'
    const market_v = marketData.market_v
    config.onlyDry && useOnlyDryCheck({ shippings: marketData?.shippings })
    useGoogleMapsApi(
      process.env.REACT_APP_MAPS_API + '&libraries=places,geometry,drawing'
    )

    useEffect(() => {
        console.log(process.env.NODE_ENV)
        if (process.env.NODE_ENV === 'production' && market_v && Number(market_v) > Number(process.env.REACT_APP_MARKET_V)) {
            localStorage?.clear()
            navigator?.serviceWorker?.ready?.then((registration) => {
                registration.unregister().then(() => {
                    window.location.reload()
                })
            })
        }
    }, [market_v])

    useEffect(() => {
        let active = true
        let unsubscribe = db.collection('marketconfig').doc(marketconfig).onSnapshot(d => {
            const data = d.data()
            if (active && data.seller) {
                setMarketData({ ...d.data() })
                localStorage?.setItem('marketData', JSON.stringify({ ...d.data() }))
            }
        }
        )
        return () => { active = false; unsubscribe() }
    }
        , [marketconfig])

    return !marketData.loading && marketData.seller ? <MarketContext.Provider value={marketData}>
        {children}
    </MarketContext.Provider>
        : <LoadingPage />
}

function useOnlyDryCheck({ shippings }) {
    const { address } = useSelectedAddress()
    const { ipData, onlyDry, selectedAddress } = useUserData()
    const [cShippings, setCShippings] = useState(false)
    const updateUser = useUserDataSet()

    const { lat, lng } = selectedAddress ? address?.geometry?.location || {} : { lat: ipData?.latitude, lng: ipData?.longitude }

    useEffect(() => {
        const google = window?.google
        let point = google && lat && new google.maps.LatLng(lat, lng)
        point && shippings && setCShippings(checkContainsLocation(point, shippings))
    }, [ lat, lng, shippings])


    useEffect(() => {
        lat && lng && cShippings && (!cShippings.length !== !!onlyDry) && updateUser({ onlyDry: !onlyDry }, 'update')
    }, [lat, lng, cShippings, onlyDry])
}


function useMarketContext() {
    const context = React.useContext(MarketContext)
    if (context === undefined) {
        throw new Error('MarketContext must be used within a MarketProvider')
    }
    return context
}


export { MarketProvider, useMarketContext }
