/* eslint-disable no-undef */
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import ErrorIcon from '@material-ui/icons/Error'
import RoomIcon from '@material-ui/icons/Room'
import React from 'react'
import { useHistory } from 'react-router-dom'

import config from '../../../config/config'
import { useStores } from '../../../context/UserContext'
import { useChangeTitle } from '../../Utils/useChangeTitle'
import { StoreOrders } from '../StoreOrders'
import useStyles from './styles'

export default function StoreDetails() {
  const stores = useStores()
  const history = useHistory()
  const locId = history.location.pathname.split('/').pop()
  const store = stores.find((element) => element.id === locId)
  const classes = useStyles()
  config?.plant_web_view === true
    ? useChangeTitle(store?.storeName + ' - ')
    : useChangeTitle(store?.storeName + ' - Plant business')
  //al agregar un usuario no se actualiza store.data.users

  return !store?.id ? (
    <></>
  ) : (
    <>
      <Box key={store.id}
        className={classes.listContainerWrap}>
        <Box>
          <Box className={classes.listBox}>
            <List className={classes.list}>
              <ListItem className={classes.listItemsName}>
                <Box>
                  <ListItemText>
                    <p className={classes.listNameStore}>{store?.storeName}</p>
                  </ListItemText>
                  <ListItemText>
                    {store?.validated ? (
                      ''
                    ) : (
                      <Box
                        display={'flex'}
                        p={2}
                        className={classes.errorValidated}
                      >
                        <ErrorIcon color="secondary" />
                        <p className={classes.listNameValidatedMessage}>
                          Ya registramos tu Tienda y pronto será habilitada por
                          uno de nuestros representante de ventas.
                        </p>
                      </Box>
                    )}
                  </ListItemText>
                </Box>
                <ListItemIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push(`/app/stores/${locId}`)}
                >
                  <Avatar>
                    <EditIcon />
                  </Avatar>
                </ListItemIcon>
              </ListItem>
              <Divider />
              {/*<ListItem className={classes.listItems}>*/}
              {/*    <ListItemText primary='Horarios'/>*/}
              {/*    <ListItemText className={classes.listItemsInfo} primary={store?.horarios}/>*/}
              {/*</ListItem>*/}
              <ListItem className={classes.listItems}>
                <ListItemText primary="Razon social" />
                <ListItemText
                  className={classes.listItemsInfo}
                  primary={store?.razonsocial}
                />
              </ListItem>
              <ListItem className={classes.listItems}>
                <ListItemText primary="Cuit" />
                <ListItemText
                  className={classes.listItemsInfo}
                  primary={store?.cuit}
                />
              </ListItem>
              <ListItem className={classes.listItems}>
                <ListItemText primary="Email" />
                <ListItemText
                  className={classes.listItemsInfo}
                  primary={store?.email}
                />
              </ListItem>
              {/*<ListItem className={classes.listItems}>*/}
              {/*    <ListItemText primary='Usuarios'/>*/}
              {/*    <ListItemText className={classes.listItemsInfo} primary={_.chain(store?.data?.users).values().map(x=>x.name + "/").value()}/>*/}
              {/*</ListItem>*/}
              {/*<ListItem className={classes.listItems}>*/}
              {/*    <ListItemText primary='Agregar usuario a esta tienda'/>*/}
              {/*    <ListItemText className={classes.listItemsInfo} primary={Form({store, locId})}/>*/}
              {/*</ListItem>*/}
            </List>
          </Box>
          <Box className={classes.listBox}>
            <List className={classes.listAddress}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <RoomIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText />
              </ListItem>
              <ListItem>
                <ListItemText
                  className={classes.listItemsInfoShipping}
                  primary={store?.address?.formatted_address}
                />
              </ListItem>
            </List>
            <List className={classes.listMap}>
              {store?.address?.geometry && (
                <img
                  alt={'map'}
                  src={
                    'https://maps.googleapis.com/maps/api/staticmap?zoom=15&size=600x300&maptype=roadmap' +
                    '&markers=color:blue%7C%7C' +
                    store?.address?.geometry.location.lat +
                    ',' +
                    store?.address?.geometry.location.lng +
                    '&key=' +
                    process.env.REACT_APP_MAPS_API
                  }
                  style={{ padding: '1rem', maxWidth: 800 }}
                />
              )}
              <ListItem>
                <ListItemText primary={store?.address?.details} />
              </ListItem>
            </List>
          </Box>
        </Box>
        <Box>
          <Box className={classes.listBox}>
            <p className={classes.listNameStore}>Mis Compras</p>
          </Box>
          <StoreOrders />
        </Box>
      </Box>
    </>
  )
}

// function Form({ store, locId }) {
//   const refDB = db.collection('users')
//   const [valorInput, setValorInput] = useState('')
//   const inputRef = useRef(null)
//   const users = store.users

//   const addNewUser = (newUserID) => {
//     updateDoc({
//       indexName: 'stores',
//       id: locId,
//       hit: {
//         ['users']: _.uniq([...users, newUserID]),
//       },
//     })
//   }
//   const handleSubmit = (event) => {
//     event.preventDefault()
//     setValorInput(inputRef.current.value)
//   }
//   return (
//     <form onSubmit={handleSubmit}>
//       <label>
//         Número:
//         <input //mask={['(', /[\+]/, /[1-9]/, /[1-9]/, ')', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
//           placeholder="+54 9 11 XXXX-XXXX"
//           type="text"
//           ref={inputRef}
//         />
//       </label>
//       <button
//         type="submit"
//         onClick={() => {
//           if (isValidPhoneNumber(valorInput))
//             refDB
//               .where('phoneNumber', '==', `${valorInput}`)
//               .where('isAnonymous', '==', false)
//               .get()
//               .then((querySnapshot) => {
//                 querySnapshot.forEach((doc) => {
//                   addNewUser(doc.id)
//                 })
//               })
//               .catch((error) => {
//                 console.log('Error getting documents: ', error)
//               })
//           else console.log('')
//         }}
//       >
//         Agregar
//       </button>
//     </form>
//   )
// }
