import { Avatar, Box, Typography } from '@material-ui/core/'
import { Divider } from '@material-ui/core/'
import { Person } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { memo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { useUserData } from '../../context/UserContext'
import { db } from '../../firebase'
import { navBarOptions } from './navbarOptions'
import useStyles from './styles'

export default function ProfileNav() {
  const classes = useStyles()
  const [profileData, setProfileData] = useState({ name: '', image: '' })
  const { uid } = useUserData()

  useEffect(() => {
    let active = true
    let snap
    uid &&
      (snap = db
        .collection('users')
        .doc(uid)
        .onSnapshot((d) => active && setProfileData(d.data())))
    return () => {
      active = false
      snap && snap()
    }
  }, [uid])

  return (
    <sidebar className={classes.profileNav}
      style={{ width: '242px' }}>
      {/* Main links */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '85vh',
        }}
      >
        <div className={classes.mainLinks}>
          {navBarOptions.map(({ label, link, Icon }, index) => (
            <MenuLink key={index}
              label={label}
              link={link}
              Icon={Icon} />
          ))}
        </div>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
          <MenuLink
            style={{ left: 15, position: 'relative' }}
            label={'Mi cuenta'}
            link={'profile'}
            Icon={Person}
          />
          <Divider />
          <span style={{ display: 'flex', gap: 12 }}>
            <Avatar
              style={{ width: '42px', height: '42px' }}
              alt={profileData.name}
              src={profileData.image}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography>{profileData.name}</Typography>
              <Typography>{profileData.phoneNumber}</Typography>
            </div>
          </span>
        </Box>
      </div>
      {/* <div>
        <Avatar
          histor
          style={{ width: 100, height: 100, marginTop: 30, marginBottom: 30 }}
          alt={profileData.name}
          src={profileData.image}
        />
      </div>
      <nav>
        <Link to="/app/profile">
          <button className={classes.buttons}>
            <div className={classes.listItem}>
              <div className={classes.icon}>
                <AccountIcon />
              </div>
              Mi Cuenta
            </div>
          </button>
        </Link>
        <Link to="/app/myorders">
          <button className={classes.buttons}>
            <div className={classes.listItem}>
              <div className={classes.icon}>
                <ShoppingBasketIcon />
              </div>
              Mis Compras
            </div>
          </button>
        </Link>
        <Link to="/app/newStore">
          <button className={classes.buttons}>
            <div className={classes.listItem}>
              <div className={classes.icon}>
                <ContactMailIcon />
              </div>
              Soy Vendedor
            </div>
          </button>
        </Link>
        <Link to="/app/myStores">
          <button className={classes.buttons}>
            <div className={classes.listItem}>
              <div className={classes.icon}>
                <StoreIcon />
              </div>
              Mis Comercios
            </div>
          </button>
        </Link>
      </nav> */}
    </sidebar>
  )
}

const MenuLink = memo(({ label, link, Icon, style = {} }) => {
  const classes = useStyles()
  const location = useLocation()

  const isActive = location.pathname.includes(link)

  return (
    <Box sx={{ display: 'flex' }}>
      {isActive ? (
        <Box
          sx={{
            width: '12px',
            position: 'absolute',
            left: 5,
            height: '32px',
            color: '#565656',
            backgroundColor: 'black',
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
          }}
        ></Box>
      ) : null}
      <NavLink
        style={{
          textDecoration: 'none',
          color: '#444444',
          backgroundColor: isActive ? 'black' : 'inherit',
          borderRadius: '10px',
          ...style,
        }}
        to={link}
      >
        <Box
          className={classes.link}
          sx={{
            display: 'flex',
            gap: 20,
            justifyContent: 'space-between',
            color: isActive ? '#fff' : '#444444',
          }}
        >
          <Icon />
          <Typography
            sx={{
              fontSize: '14px',
              transition: 'opacity 0.25s ease-in-out',
            }}
          >
            {label}
          </Typography>
        </Box>
      </NavLink>
    </Box>
  )
})
