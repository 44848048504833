import { IconButton, Menu, MenuItem } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { Store as StoreIcon } from '@material-ui/icons'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useStores } from '../../../context/UserContext'
import { Typography } from '../../Wrappers/Wrappers'
import useStyles from '../styles'

const Stores = () => {
  const classes = useStyles()
  const [storesMenu, setStoresMenu] = useState(null)
  const stores = useStores()
  let history = useHistory()

  function clickAndClose() {
    history.push('/app/newStore')
  }

  return (
    <>
      {
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => stores?.length ? setStoresMenu(e.currentTarget) : history.push('/app/newStore')}
        >
          <StoreIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
      }

      {!!stores.length && (
        <Menu
          id="stores-menu"
          open={Boolean(storesMenu)}
          anchorEl={storesMenu}
          onClose={() => setStoresMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          MenuListProps={{
            disablePadding: true,
          }}
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4"
              weight="medium">
              {stores.length > 1 ? 'Mis Comercios' : 'Mi Comercio'}
            </Typography>
          </div>
          {stores.map((store) => (
            <MenuItem
              key={store.id}
              onClick={() => {
                history.push('/app/storesDetail/' + store.id)
                setStoresMenu(null)
              }}
            >
              <StoreIcon style={{ marginRight: 15, color: 'black' }} />{' '}
              {store.storeName}{' '}
              <p
                style={{
                  margin: '0',
                  paddingLeft: '.5rem',
                  color: 'red',
                }}
              >
                {store.validated ? '' : 'En proceso'}
              </p>
            </MenuItem>
          ))}
          <Button
            style={{
              display: 'flex',
              margin: '0 auto',
              background: '#333',
              color: '#fff',
              width: '100%',
              marginTop: 10,
              fontSize: '15px',
            }}
            onClick={() => {
              clickAndClose()
            }}
          >
            Agregar Comercio
          </Button>
        </Menu>
      )}
    </>
  )
}

export default Stores
