import 'react-image-lightbox/style.css'

import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

// components
import Layout from './Layout'

// pages
// import Error from "../pages/error";
// import LoadingPage from "../pages/loading";

// context


export default function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact
                    path="/"
                    component={({ location }) => (
                        <Redirect
                            to={{
                                ...location,
                                pathname: '/app/homepage',
                            }}
                        />
                    )} />
                <Route
                    exact
                    path="/app"
                    component={({ location }) => (
                        <Redirect
                            to={{
                                ...location,
                                pathname: '/app/homepage',
                            }}
                        />
                    )}
                />
                <Route path="/app"
                    render={Layout}
                />
                <Route component={({ location }) => (
                    <Redirect
                        to={{
                            ...location,
                            pathname: '/app/homepage',
                        }}
                    />
                )} />
                {/*<Route component={Error} />*/}
            </Switch>
        </BrowserRouter>
    )
}

// #######################################################################

//   function PrivateRoute({ component, LazyComponent, ...rest }) {
//
//
//     return (
//       <Route
//         {...rest}
//         render={props =>
//           (
//               component ? (React.createElement(component, props)) : (
//                   <Suspense fallback={<LoadingPage/>}>
//                   <LazyComponent {...props} />
//               </Suspense> )
//           )
//         }
//       />
//     );
//   }
// }
