import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import ReceiptIcon from '@material-ui/icons/Receipt'
import { useState } from 'react'

import { useUserData } from '../../../context/UserContext'
import { InputFacturacion } from './InputFacturacion'
import { RadioFacturacion } from './RadioFacturacion'

const useStyles = makeStyles({
  personName: {
    boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.2)',
    '&:hover': {
      boxShadow: '0 2px 20px 0 rgba(0,0,0,.1)',
    },
    paddingRight: 0,
  },
})

export const MenuFacturacion = () => {
  const { invoiceData } = useUserData()
  const [openFac, setOpenFac] = useState(false)
  const [openInput, setOpenInput] = useState(false)

  const closeInput = () => setOpenInput(false)

  const lastUsed = Object.entries(invoiceData || {}).sort(([, a], [, b]) => b.lastUsed - a.lastUsed)?.[0]?.[0]

  const classes = useStyles()

  return (
    <Box
      className={classes.personName}
      onClick={() => setOpenFac(true)}
      display={'flex'}
      alignItems="center"
      padding="1rem"
      marginBottom={1}
      borderRadius={8}
    >
      {openFac ? (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {!openInput ? (
              <>
                <RadioFacturacion
                  lastUsed={lastUsed}
                  disabled={!!openInput}
                />
                <Button
                  onClick={() => setOpenInput(true)}
                  color="secondary"
                >
                  Usar otro
                </Button>
              </>
            ) : (
              <InputFacturacion closeInput={closeInput} />
            )}
          </Box>
        </>
      ) : (
        <>
          <ReceiptIcon
            fontSize="large"
            style={{ marginRight: '17px' }}
          />
          <Typography>Datos para tu Factura</Typography>
        </>
      )}
    </Box>
  )
}
