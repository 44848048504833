import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'

import firebase from 'firebase/app'
import {useEffect} from 'react'
import {
  useCollection as firestoreUseCollection,
  // useDocumentData as firestoreUseDocumentData,
  useDocumentDataOnce as firestoreUseDocumentDataOnce,
} from 'react-firebase-hooks/firestore'


// Your web app's Firebase configuration
// eslint-disable-next-line
// const firebaseConfig = {
//     apiKey: "AIzaSyB74zAYt4YZoODmQF0rOt_ZP_9gFp9yMuc",
//     authDomain: "vivet-dev.firebaseapp.com",
//     databaseURL: "https://vivet-dev.firebaseio.com",
//     projectId: "vivet-dev",
//     storageBucket: "vivet-dev.appspot.com",
//     messagingSenderId: "894553286057",
//     appId: "1:894553286057:web:21ae06e8e9d2fdb305d0b8",
//     measurementId: "G-9TP5E45B88"
// };
// const firebaseConfigProduction = {
//     // apiKey: "AIzaSyDvzMDCkUoQG027Mq7-e1maJpCSiB-8_kw",
//     apiKey: "AIzaSyDvzMDCkUoQG027Mq7-e1maJpCSiB-8_kw",
//     authDomain: "vivet-web.firebaseapp.com",
//     databaseURL: "https://vivet-web.firebaseio.com",
//     projectId: "vivet-web",
//     storageBucket: "vivet-web.appspot.com",
//     messagingSenderId: "718089198056",
//     appId: "1:718089198056:web:39beefd5831523949aec5d"
// };
const firebaseDev = {
  apiKey: 'AIzaSyAg2Xh9LCAqhYWyCiXHP2yUaY-kZ-kieZo',
  authDomain: 'vivet-web.firebaseapp.com',
  databaseURL: 'https://vivet-web.firebaseio.com',
  projectId: 'vivet-web',
  storageBucket: 'vivet-web.appspot.com',
  messagingSenderId: '718089198056',
  appId: '1:718089198056:web:77117894152e23d39aec5d',
  measurementId: 'G-1LPHMLN63S'
}
// Initialize Firebase
firebase.initializeApp(firebaseDev)
const storage = firebase.storage()
const db = firebase.firestore()
const auth = firebase.auth()
const FieldValue = firebase.firestore.FieldValue
const Timestamp = firebase.firestore.Timestamp

var uid
auth.onAuthStateChanged(u => uid = u && u.uid)
const updateDoc = ({indexName, id, hit, delete_fields}) => {
  if (!hit) hit = {}
  if (delete_fields) for (let i in delete_fields) hit[delete_fields[i]] = firebase.firestore.FieldValue.delete()
  if (uid) hit.lastEditBy = uid
  return db.collection(indexName).doc(id).update({algoliaUpdated: false, ...hit})
}
const newDoc = ({indexName, hit}) => {
  if (!hit) hit = {}
  if (uid) hit.lastEditBy = uid
  return db.collection(indexName).add({algoliaUpdated: false, ...hit})
}

export function useCollection({collection = '', where = [], limit = 1, orderBy = []}) {

  const [snapshot, loading, error] = firestoreUseCollection(
    where.reduce((o, a) => o.where(...a), orderBy.reduce((o, a) => o.orderBy(...a), db.collection(collection))).limit(limit))
  const result = snapshot ? snapshot.docs.map((entity) => {
    return {...entity.data(), objectID: entity.id}
  }) : undefined

  if (result) {
    result.forEach(element => {
      Object.entries(element).forEach(([field, value]) => {
        if (value instanceof Timestamp) {
          element[field] = value.toDate()
        }
      })
    })
  }
  return [result, loading, error]
}

function useDocumentDataOnce(collection, docID) {
  const [entity, loading, error] = firestoreUseDocumentDataOnce(
    typeof docID === 'string' && db.collection(collection).doc(docID)
  )

  const result = entity

  if (result) {
    result.objectID = docID
    Object.entries(result).forEach(([field, value]) => {
      if (value instanceof Timestamp) {
        result[field] = value.toDate()
      }
    })
  }

  const ls = loading && JSON.parse(localStorage?.getItem(`${collection}_${docID}`) || '{}')

  useEffect(() => {
    !loading && result && localStorage?.setItem(`${collection}_${docID}`, JSON.stringify(result))
  }, [entity, loading])

  return [loading ? ls : result, loading, error]
}

const productsSearch = firebase.functions().httpsCallable('search')
export {auth, db, FieldValue, newDoc, productsSearch, storage, updateDoc, useDocumentDataOnce}
export default firebase
