import { Box, LinearProgress, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import FavoriteBorder from '@material-ui/icons/FavoriteBorder'
import { makeStyles } from '@material-ui/styles'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Populate from '../../algolia/populate'
import { useUserData } from '../../context/UserContext'
import { db, FieldValue } from '../../firebase/database'

export const ListFavorites = () => {
  const useStyles = makeStyles(() => ({
    listWrap: {
      position: 'relative',
      zIndex: 20,
      display: 'flex',
      maxWidth: 300,
      padding: 0,
      flexDirection: 'column',
    },
    favoritesTitle: {
      textAlign: 'left',
      width: '100%',
      padding: 3,
      borderBottom: '1px solid #ccc',
      fontWeight: 'bold',
    },
    listText: {
      fontWeight: 'bold',
      textOverflow: 'ellipsis',
      fontSize: '1rem',
    },
    listTextTaste: {
      fontSize: '.8rem',
      width: '50%',
    },
    listTextEliminar: {
      position: 'absolute',
      top: 58,
      left: 220,
      zIndex: 80,
      fontSize: '.8rem',
      width: '50%',
    },
    listTextNoFav: {
      textAlign: 'center',
    },
  }))

  const [productsData, setProductsData] = useState({})
  const classes = useStyles()
  const router = useHistory()
  const [skeleton, setSkeleton] = useState(true)
  const { favorites, uid } = useUserData()
  const favoritosFiltradosString = JSON.stringify(Object.keys(favorites || {}))

  useEffect(() => {
    let active = true
    const favoritosFiltrados = JSON.parse(favoritosFiltradosString)
    favoritosFiltrados &&
      Populate({ hits: [{ products: favoritosFiltrados }], props: [{ index: 'products', field: 'products' }] }).then(
        (h) => active && setProductsData(h[0].products),
      )
    return () => (active = false)
  }, [favoritosFiltradosString])

  useEffect(() => {
    setTimeout(() => {
      setSkeleton(false)
    }, 1000)
  }, [])

  const contador = _.size(favorites)

  const removeFavorite = (id) => {
    db.collection('users')
      .doc(uid)
      .update({
        ['favorites.' + id]: FieldValue.delete(),
      })
  }
  return (
    <div>
      <Typography className={classes.favoritesTitle}>Favoritos</Typography>
      {skeleton ? (
        <LinearProgress />
      ) : Object.entries(productsData).length >= 1 && contador >= 1 ? (
        Object.values(productsData)
          .filter((item) => favorites[item.objectID])
          .map((item) => (
            <div
              key={item.objectID}
              className={classes.listWrap}
              overflow="hidden"
              style={{ cursor: 'pointer' }}
            >
              <Box
                onClick={() => router.push(`/app/product/${item.objectID}`)}
                display="flex"
                alignItems="center"
                justifyContent={'space-around'}
                p={1}
                borderBottom="1px solid #ccc"
                overflow="hidden"
              >
                <span style={{ margin: '10px' }}>
                  <img src={`${item.thumbnail}`}></img>
                </span>
                <Box
                  maxWidth={150}
                  component="div"
                  my={2}
                  overflow="hidden"
                  whiteSpace={'nowrap'}
                  className={classes.listText}
                >
                  {item.name}
                  <Box>
                    <Typography className={classes.listTextTaste}>{item.taste}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                className={classes.listTextEliminar}
                onClick={() => {
                  removeFavorite(item.objectID)
                }}
              >
                <DeleteIcon />
              </Box>
            </div>
          ))
      ) : (
        <Box
          maxWidth={200}
          paddingLeft={10}
          paddingTop={2}
          display="flex"
          flexDirection={'column'}
          alignItems="center"
          justifyContent={'center'}
        >
          <Typography className={classes.listTextNoFav}>Favoritos</Typography>
          <FavoriteBorder />
        </Box>
      )}
    </div>
  )
}
