import React from 'react'

import config from '../config/config'
import { useMarketContext } from './MarketContext'
import { useSelectedStore, useUserData } from './UserContext'

const PriceListIDContext = React.createContext()
const PriceListsDataContext = React.createContext()


function PriceListProvider({ children }) {
    const user = useUserData()
    const { seller, priceList } = useMarketContext()
    const { store } = useSelectedStore()

  const priceListID = (store || user)?.priceList?.[seller] || config.defaultPriceList

  const priceLists = priceList || { [priceListID]: {} }

    return <PriceListIDContext.Provider value={priceListID}>
        <PriceListsDataContext.Provider value={priceLists}>
            {children}
        </PriceListsDataContext.Provider>
    </PriceListIDContext.Provider>
}



function usePriceListIDContext() {
    const context = React.useContext(PriceListIDContext)
    if (context === undefined) {
        throw new Error('PriceListStateContext must be used within a PriceListProvider')
    }
    return context
}

function usePriceListsDataContext() {
    const context = React.useContext(PriceListsDataContext)
    if (context === undefined) {
        throw new Error('PriceListsDataContext must be used within a PriceListProvider')
    }
    return context
}

function usePriceList() {
    const priceListID = usePriceListIDContext()
    const marketContext = useMarketContext()
    const priceLists = marketContext?.priceList || { [priceListID]: {} }
    return priceLists[priceListID]
}


export { PriceListProvider, usePriceList, usePriceListIDContext, usePriceListsDataContext }
