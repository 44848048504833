import { Button, TextField } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React, { useState } from 'react'

import { updateDoc } from '../../../firebase/database'

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
  },
  daysDiv: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginBottom: '22px',
    flexWrap: 'wrap',
  },
  daysCheckboxesFalse: {
    width: '30px',
    height: '30px',
    borderRadius: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    borderStyle: 'solid',
    cursor: 'pointer',
    opacity: '50%',
    transition: '0.35s',
  },
  daysCheckboxesTrue: {
    width: '30px',
    height: '30px',
    borderRadius: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    borderColor: '#346292',
    borderStyle: 'solid',
    cursor: 'pointer',
    transition: '0.35s',
    backgroundColor: 'rgba(52, 98, 146, 0.4)',
  },
  openOrCloseDiv: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  hoursInputs: {
    display: 'flex',
    marginLeft: '18px',
    marginTop: '25px',
    flexDirection: 'column',
    minHeight: '20vh',
    minWidth: '27vw',
  },
  container: {
    marginTop: '10px',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}))

const dayNames = {
  1: 'L',
  2: 'M',
  3: 'M',
  4: 'J',
  5: 'V',
  6: 'S',
  7: 'D',
}

export default function StoreSchedules({
  id,
  storeHours,
  storeDay,
  handleCloseDialog,
}) {
  const classes = useStyles()
  const [hours, setHours] = useState(storeHours || [])
  const [days, setDays] = useState({ [storeDay]: true })
  const hoursLength = Object?.keys(hours)?.length

  const [selectedOption, setSelectedOption] = useState('option3')

  const handleOptionChange = (option) => {
    setSelectedOption(option)
  }

  function handleCheckboxChange(value, day) {
    setDays({ ...days, [day]: value })
  }

  function handleDeleteRow(index) {
    const deleteRow = [...hours]
    deleteRow.splice(index, 1)
    setHours(deleteRow)
  }

  function handleAddHours() {
    setHours([...hours, { open: 0, close: 0 }])
  }


  function handleSave() {
    if (selectedOption === 'option1') {
      const hit = _.chain(days)
        .pickBy((d) => d)
        .reduce(
          (p, value, key) => ({
            ...p,
            [`hours.${key}`]: [{ open: 0, close: 23 }],
          }),
          {}
        )
        .value()
      updateDoc({ indexName: 'stores', hit, id })
      handleCloseDialog()

      return
    }

    if (selectedOption === 'option2') {
      const hit = _.chain(days)
        .pickBy((d) => d)
        .reduce(
          (p, value, key) => ({
            ...p,
            [`hours.${key}`]: [],
          }),
          {}
        )
        .value()
      updateDoc({ indexName: 'stores', hit, id })
      handleCloseDialog()

      return
    }
    const hit = _.chain(days)
      .pickBy((d) => d)
      .reduce(
        (p, value, key) => ({
          ...p,
          [`hours.${key}`]: hours,
        }),
        {}
      )
      .value()
    updateDoc({ indexName: 'stores', hit, id })
    handleCloseDialog()
  }

  return (
    <div style={{ maxWidth: '600px' }}>
      <h2 className={classes.title}>Selecciona los días y el horario</h2>
      <div className={classes.daysDiv}>
        {Object.entries(dayNames).map(([day, dayName], index) => (
          <span
            key={index}
            onClick={() => handleCheckboxChange(!days[day], day)}
            className={
              !days[day]
                ? classes.daysCheckboxesFalse
                : classes.daysCheckboxesTrue
            }
          >
            {dayName}
          </span>
        ))}
      </div>
      <FormControl style={{ width: '100%' }}
        component="fieldset">
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={selectedOption}
          onChange={(e) => handleOptionChange(e.target.value)}
        >
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
            }}
          >
            <FormControlLabel
              value="option1"
              control={<Radio />}
              label="Abierto las 24hs"
            />
            <FormControlLabel
              value="option2"
              control={<Radio />}
              label="Cerrado"
            />
            <FormControlLabel
              value="option3"
              control={<Radio />}
              label="Otros"
            />
          </span>
        </RadioGroup>
      </FormControl>
      <div className={classes.hoursInputs}>
        {selectedOption === 'option3' &&
          hours?.map(({ open, close }, index) => (
            <form key={index} className={classes.container}>
              <TextField
                id={`${index}`}
                label="Apertura"
                type="time"
                value={decimalToTime(open)}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300,
                }}
                onChange={(e) =>
                  setHours(
                    _.set(
                      _.clone(hours),
                      [index, 'open'],
                      timeToDecimal(e.target.value)
                    )
                  )
                }
              />
              <TextField
                id={`${index}`}
                label="Cierre"
                type="time"
                value={decimalToTime(close)}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300,
                }}
                onChange={(e) =>
                  setHours(
                    _.set(
                      _.clone(hours),
                      [index, 'close'],
                      timeToDecimal(e.target.value)
                    )
                  )
                }
              />
              <Button onClick={() => handleDeleteRow(index)}> X </Button>
            </form>
          ))}
        {hoursLength < 2 && selectedOption === 'option3' ? (
          <Button
            onClick={() => handleAddHours()}
            style={{ marginTop: '15px' }}
          >
            Agregar horario
          </Button>
        ) : (
          <></>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleSave()
          }}
          style={{ marginTop: '15px' }}
        >
          Guardar
        </Button>
      </div>
    </div>
  )
}

export function decimalToTime(decimal) {
  var hours = Math.floor(decimal)
  var minutes = Math.round((decimal - hours) * 60)
  if (minutes == 60) {
    hours++
    minutes = 0
  }
  var time =
    hours.toString().padStart(2, '0') +
    ':' +
    minutes.toString().padStart(2, '0')
  return time
}

export function timeToDecimal(time) {
  var hoursMinutes = time.split(':')
  var hours = parseInt(hoursMinutes[0], 10)
  var minutes = parseInt(hoursMinutes[1], 10)
  return hours + minutes / 60
}
