import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
//components
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import { PriceTotalPerProduct } from '../../../components/Product'
import DetailPrice from '../../../components/Product/DetailPrice'
import { GetCartTotals } from '../../../components/Product/Price/CartTotals'
import imgurWebp from '../../../components/Utils/imgurWebp'
//context
import {
    useCartDataSetContext
} from '../../../context/CartContext'
import { useMarketContext } from '../../../context/MarketContext'
import { useUserData } from '../../../context/UserContext'
import { updateDoc } from '../../../firebase'
import minusIcon from '../../../images/minus.svg'
import plusIcon from '../../../images/plus.svg'
import { formatTextToUrl } from '../../../lib/formatTextToUrl'
import { Total } from './Cart'
// styles
import useStyles from './styles'

// function addItemsToCart(items = [], cart, userUpdate) {
//     if (cart && Object.keys(cart).length && items.length && items.reduce((p, a) => p || !cart[a]?.q, false)) {
//         const hit = items.reduce((p, a) => ({ ...p, [`cart.${a}.q`]: 1 }), {})
//         userUpdate(hit, 'update')
//     }
// }

// function removeItemsFromCart(items = [], cart, userUpdate) {
//     if (items.length && items.reduce((p, a) => p || cart[a]?.q, false)) {
//         const hit = items.reduce((p, a) => ({ ...p, [`cart.${a}`]: FieldValue.delete() }), {})
//         userUpdate(hit, 'update')
//     }
// }

// const giftItems = [//'1dzdS9pkvm2IVC3O9OSc','BkeIXiKntY009vDTWvnC'
// ]

export function useCartList() {
    // const cart = useCartContext();
    // const [hits, setHits] = useState({});
    // const priceListID = usePriceListIDContext();
    // const priceList = usePriceList();
    // const userUpdate = useUserDataSet();
    // const userData = useUserData();
    // const setExtraDiscount = useExtraDiscountDataSetContext();
    // const extraDiscount = useExtraDiscountContext();

    const [hits, totals, cart] = GetCartTotals({})
    // const totalsVivet = GetCartTotals({ brand: 'VQTAn9M50USdKZB5rhJb' })[1];

    // GetPricesTotals({products:Object.keys(cart).filter(a=>{
    //         let product = hits[a];
    //             cart[a]&&cart[a].q
    //     }).map(a=>({...hits[a],q:cart[a].q}))});

    // useEffect(() => {
    //     if (cart && priceListID === 'g' && priceList?.purchasemin && totals?.totalProducts) {
    //         else if (priceList.purchasemin > totals.totalProducts) removeItemsFromCart(giftItems, cart, userUpdate);
    //     }
    //     else if (priceListID !== 'g' && totals?.totalProducts) removeItemsFromCart(giftItems, cart, userUpdate);
    // }, [cart, priceListID, totals.totalProducts, priceList, userUpdate]);

    // useEffect(() => {
    //     if (totals?.totalProducts) {
    //         if (priceListID === 'd') {
    //             if (totalsVivet.subtotal - totalsVivet.discounts >= 10000 && !extraDiscount) setExtraDiscount(0.1);
    //             else if (totalsVivet.subtotal - totalsVivet.discounts < (10000 * (1 - extraDiscount)) && extraDiscount) setExtraDiscount(0);
    //         }
    //         else if (extraDiscount) setExtraDiscount(0);
    //
    //     }
    //
    // }, [totalsVivet.subtotal, totalsVivet.discounts, totals.totalProducts, userData, extraDiscount, setExtraDiscount, priceListID]);

    // useEffect(() => {
    //     let active = true;
    //     Object.keys(cart).reduce((p, a) => p || !hits[a], false) &&
    //     Populate({hits: [{products: Object.keys(cart)}], props: [{index: 'products', field: 'products'}]})
    //         .then(h => active && setHits(h[0].products));
    //     return () => active = false;
    // }, [cart, hits]);


    return [hits, totals, cart]
}

export default function CartList({ customDiscounts } = {}) {
    let history = useHistory()
    const classes = useStyles()
    const [hits, totals, cart] = useCartList()
    const productPage = history.location.pathname === '/app/products'

    return [<div key={0} className={history.location.pathname === '/app/buy/cart' && classes.products}>
        <div className={productPage && classes.container}>
            <div className={productPage && classes.scrollable}>
                <div className={classes.content}></div>
                {cart && _.chain(cart).pickBy((v, k) => hits[k]).map(({ timestamp, q }, id) => ({
                    q,
                    t: timestamp?.seconds || timestamp || 0,
                    id
                })).values().sortBy(['t', 'id']).reverse().value().map(({ id, q = 0 }) =>
                    <ProductCard key={id}
                        customDiscounts={customDiscounts}
                        classes={classes}
                        hit={hits[id]}
                        inCart={q} />
                )}
            </div>
        </div>
    </div>,
        totals,
    <Total key={3} classes={classes}
        totals={totals} />,
        hits,
        cart
    ]
}


export function ProductCard({ hit, classes, inCart, cartForLater = false, customDiscounts }) {

    const [canAdd, setCanAdd] = useState(true)

    const cartUpdate = useCartDataSetContext()
    const market = useMarketContext()
    const userData = useUserData()
    const actualLocation = useLocation()
    const less750px = useMediaQuery('(min-width:750px)')

    const handleMoveToCart = () => {
        cartUpdate({ product: hit, q: 1 })
        updateDoc({ indexName: 'users', id: userData.uid, delete_fields: ['cartForLater.' + hit.objectID] })
    }

    const content = useMemo(() =>
        <Grid item
            xs={4}
            className={classes.cardMediaContent}>
            <Link className={classes.link}
                to={`/app/product/${formatTextToUrl(hit.name) + '-' + formatTextToUrl(hit.taste)}/${hit.objectID}`}>
                <CardMedia
                    className={classes.thumbnail}
                    image={hit.thumbnail}
                    title={hit.name}
                />
                <CardMedia
                    className={classes.media}
                    image={imgurWebp(hit.image)}
                    title={hit.name}
                />
            </Link>
            {/*{!hit.mrh && actualLocation.pathname === "/app/buy/cart" &&*/}
            {/*    <img className={classes.brand}*/}
            {/*        src={imgurWebp(market.brands?.[hit.brand]?.image || '')}*/}
            {/*        alt={market.brands?.[hit.brand]?.name || ''}*/}
            {/*    />}*/}
        </Grid>
        , [hit, classes, market.brands, actualLocation.pathname])

    const priceElement = useMemo(() =>
        <DetailPrice size={true}
            product={hit}
            customDiscounts={customDiscounts} />, [hit, customDiscounts])
    const priceTotalElement = useMemo(() =>
        <PriceTotalPerProduct hit={hit}
            q={inCart}
            customDiscounts={customDiscounts} />, [hit, inCart, customDiscounts])

    const handleAvailableStock = (qty) => {
        if (hit.available) {
            if (hit.available >= qty) {
                setCanAdd(true)
            } else {
                setCanAdd(false)
            }
        }
    }

    const actionButtons = !hit.autoItem && <>
        {
            !cartForLater ?
                !inCart
                    ? <Button onClick={() => cartUpdate({ product: hit, q: 1 })}
                        endIcon={<FontAwesomeIcon icon={faShoppingCart} />}
                        className={classes.addCart}
                        variant={'outlined'}>Agregar<span
                            className={classes.addCartOnlyBigScreen}>al</span></Button>
                    : <div className={classes.btnBuyed}>
                        <div onClick={() => {
                            cartUpdate({ product: hit, q: inCart - 1 }), handleAvailableStock(inCart - 1)
                        }}
                            className={classes.addCartButton}><img style={{ width: '30px' }}
                                src={minusIcon} /></div>
                        <div>{inCart}</div>
                        {canAdd ? <div onClick={() => {
                            cartUpdate({ product: hit, q: inCart + 1 }), handleAvailableStock(inCart + 1)
                        }}
                            className={classes.addCartButton}><img style={{ width: '30px' }}
                                src={plusIcon} /></div>
                            : <div style={{ opacity: '0.5' }}
                                className={classes.addCartButton}><img style={{ width: '30px' }}
                                    src={plusIcon} /></div>
                        }
                    </div>
                :
                hit.nostock ?
                    <Box className={classes.messageNoStock}>Producto sin stock</Box>
                    :
                    <Box className={classes.btnMoveToCart}
                        onClick={handleMoveToCart}>Mover al carrito</Box>


        }
    </>

    let history = useHistory()
    const cartListInProducts = history.location.pathname == '/app/products'

    return (
        less750px ?
            cartListInProducts ?
                // ESTA CONDICION SE CUMPLE SI SE ESTA EN LA URL /PRODUCTOS Y EXISTE EL CARRITO (1520PX aprox)
                <Card style={cartListInProducts ? { backgroundColor: '#FFFF' } : { backgroundColor: '' }}
                    className={classes.cardContent}>
                    {!canAdd && hit.available && <span
                        className={classes.productStock}>No podes agregar más de {hit.available} unidades</span>}
                    <Grid container
                        spacing={2}>
                        <Grid item
                            xs={12}
                            style={{ display: 'flex' }}>

                            {content}
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                maxWidth: 'calc(100% - 85px)'
                            }}>
                                <Typography className={classes.title}
                                    fontWeight={500}
                                    color="primary"
                                    component="p"
                                    style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                    {hit.name?.toUpperCase()}
                                </Typography>
                                <Typography variant="subtitle2"
                                    color="textSecondary"
                                    component="p">
                                    {hit.quantity ? (hit.quantity + hit.unit) + (hit.taste && ' - ') : ''}{hit.taste}
                                </Typography>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {actionButtons}
                                    <div className={classes.productTotalInProducts}>
                                        {priceTotalElement}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Card>
                : <Card style={cartListInProducts ? { backgroundColor: '#FFFF' } : { backgroundColor: '' }}
                    className={classes.cardContent}>
                    <Grid container
                        spacing={2}>
                        <Grid item
                            xs={8}
                            style={{ display: 'flex' }}>
                            <div style={{
                                display: 'flex',
                                height: '70px',
                                alignItems: 'center',
                                minWidth: '100px',
                                maxWidth: '100px'
                            }}>
                                {actionButtons}
                            </div>
                            {content}

                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Typography className={classes.title}
                                    fontWeight={500}
                                    color="primary"
                                    component="p">
                                    {hit.name?.toUpperCase()}
                                </Typography>
                                <Typography variant="subtitle2"
                                    color="textSecondary"
                                    component="p">
                                    {hit.quantity ? (hit.quantity + hit.unit) + (hit.taste && ' - ') : ''}{hit.taste}
                                </Typography>
                                {priceElement}
                            </div>

                        </Grid>
                    </Grid>
                    <div className={classes.productTotal}>
                        {priceTotalElement}
                    </div>
                    {!canAdd && hit.available && <span
                        className={classes.productStock}>No podes agregar más de {hit.available} unidades</span>}
                </Card>
            :
            <>
                <Card className={classes.cardContent}>
                    <Grid container
                        spacing={2}
                        style={{ flexWrap: 'nowrap' }}>
                        <Grid item
                            xs={10}
                            style={{ display: 'flex' }}>

                            {content}
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}>
                                <Typography className={classes.title}
                                    fontWeight={500}
                                    color="primary"
                                    component="p">
                                    {hit.name?.toUpperCase()}
                                </Typography>
                                <Typography variant="subtitle2"
                                    color="textSecondary"
                                    component="p">
                                    {hit.quantity ? (hit.quantity + hit.unit) + (hit.taste && ' - ') : ''}{hit.taste}
                                </Typography>
                                {actionButtons}
                            </div>

                        </Grid>
                    </Grid>
                    <div className={classes.productTotal}>
                        {priceTotalElement}
                    </div>
                    {!canAdd && hit.available && <span
                        className={classes.productStock}>No podes agregar más de {hit.available} unidades</span>}
                </Card>
            </>
    )
}
