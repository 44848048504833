import { Button } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'
import CreateIcon from '@material-ui/icons/Create'
import React from 'react'

import { useStores } from '../../../context/UserContext'
import StoreSchedules from './StoreSchedules'
import { decimalToTime } from './StoreSchedules'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 2,
  },
  week: {
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    width: 400,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  day: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  line: {
    display: 'flex',
  },
}))

const dayNames = {
  1: 'Lunes',
  2: 'Martes',
  3: 'Miércoles',
  4: 'Jueves',
  5: 'Viernes',
  6: 'Sábado',
  7: 'Domingo',
}

export default function SchedulesMap({ storeID }) {
  const store = useStores().find((s) => s.id === storeID)
  const classes = useStyles()
  return (
    <>
      <h2 style={{ textAlign: 'center' }}>Horarios</h2>
      <h3 style={{ textAlign: 'center' }}>{store?.storeName}</h3>
      <div className={classes.week}>
        {Object.entries(dayNames || {}).map(([day, dayName]) => (
          <DaySchedule
            key={day}
            day={day}
            dayName={dayName}
            store={store}
            classes={classes}
          />
        ))}
      </div>
    </>
  )
}

function DaySchedule({ day, dayName, store, classes }) {
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.day}>
      <p style={{ marginRight: '20px', textAlign: 'left' }}>{dayName}</p>
      <div className={classes.line}>
        <p>
          {store?.hours?.[day]?.length
            ? store?.hours?.[day].map(({ open, close }, index) => (
              <span key={index}>
                {decimalToTime(open)} - {decimalToTime(close)} <br />
              </span>
            ))
            : 'Cerrado'}
        </p>
        <Button onClick={handleOpen}>
          <CreateIcon />
        </Button>
      </div>
      <Dialog
        aria-labelledby="transition-modal-title"
        open={open}
        onClose={handleClose}
      >
        <div className={classes.paper}>
          <StoreSchedules
            handleCloseDialog={handleClose}
            id={store?.id}
            storeHours={store?.hours?.[day]}
            storeDay={day}
          />
        </div>
      </Dialog>
    </div>
  )
}
