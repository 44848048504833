import { Box, Button, FormControl, Input, InputLabel } from '@material-ui/core'
import { useEffect } from 'react'
import { useState } from 'react'

import { useGetCuitData } from '../../../components/Store/Store'
import { useUserData } from '../../../context/UserContext'
import { updateDoc } from '../../../firebase/database'

export const InputFacturacion = ({ closeInput }) => {
  const { uid, invoiceData } = useUserData()

  const [cuit, setCuit] = useState('')
  const { nombre, domicilioFiscal } = useGetCuitData(cuit)
  const [direccion, setDireccion] = useState(domicilioFiscal?.direccion ?? '')
  const [inputNombre, setInputNombre] = useState(nombre ?? '')

  const validateCuit = (e) => {
    const { value } = e.target
    setCuit(value)
  }
  const uploadCuitToUser = () => {
    const modelObject = { cuit: cuit, nombre: nombre, direccionFacturacion: domicilioFiscal?.['direccion'], lastUsed: new Date() / 1000 }
    const newInvoice = Object.keys(invoiceData || {})?.length
      ? { ...invoiceData, [Object.keys(invoiceData || {})?.length]: modelObject }
      : [modelObject]

    updateDoc({
      indexName: 'users',
      id: uid,
      hit: {
        ['invoiceData']: newInvoice,
      },
    })
    closeInput()
  }

  useEffect(() => {
    setDireccion(domicilioFiscal?.direccion)
    setInputNombre(nombre)
  }, [domicilioFiscal])

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControl sx={{ width: '100%' }}>
          <InputLabel>Cuit</InputLabel>
          <Input
            type="number"
            value={cuit}
            fullWidth
            onChange={(e) => setCuit(e.target.value)}
            onBlur={validateCuit}
          />
        </FormControl>
        <FormControl>
          <InputLabel>Direccion</InputLabel>
          <Input
            fullWidth
            value={direccion}
            onChange={(e) => setDireccion(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <InputLabel>Nombre</InputLabel>
          <Input
            fullWidth
            value={inputNombre}
            onChange={(e) => setInputNombre(e.target.value)}
          />
        </FormControl>
        <Button
          onClick={uploadCuitToUser}
          color="secondary"
          fullWidth
          disabled={(cuit?.length !== 11 && cuit?.length !== 12) || !inputNombre || !direccion}
        >
          Guardar
        </Button>
        {invoiceData?.[0] && (
          <Button
            onClick={closeInput}
            color="primary"
            fullWidth
          >
            cancelar
          </Button>
        )}
      </Box>
    </>
  )
}
