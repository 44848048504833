import { useEffect, useState } from 'react'

import Populate from '../../../algolia/populate'
import { useCartContext } from '../../../context/CartContext'
import { usePriceList, usePriceListIDContext } from '../../../context/PriceListContext'
import { GetPricesTotals } from './Price'


export function GetCartTotals({ brand = '' }) {
    const cart = useCartContext()
    const [hits, setHits] = useState({})
    const priceListID = usePriceListIDContext()
    const priceList = usePriceList()
    const cartKeys = JSON.stringify(Object.keys(cart).sort())

    const totals = GetPricesTotals({
        products: Object.keys(cart).filter(a => {
            let product = hits[a]
            return product &&
                (!brand || product.brand === brand) && (!product?.nostock &&
                    (product?.showAll || !product?.showOnly?.length || product?.showOnly.includes(priceListID) || (priceList.mother &&
                        product?.showOnly.includes(priceList.mother)))) &&
                cart[a] && cart[a].q
        }).map(a => ({ ...hits[a], q: cart[a].q }))
    })

    useEffect(() => {
        let active = true
        JSON.parse(cartKeys)//.reduce((p, a) => p || !hits[a], false)
            &&
            Populate({ hits: [{ products: JSON.parse(cartKeys) }], props: [{ index: 'products', field: 'products' }] })
                .then(h => active && setHits(h[0].products))
        return () => active = false
    }, [cartKeys])

    return [hits, totals, cart]

}
