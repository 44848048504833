import {useCartContext, useExtraDiscountContext} from '../../../context/CartContext'
import {useMarketContext} from '../../../context/MarketContext'
import {usePriceListIDContext} from '../../../context/PriceListContext'


export default function GetPrices({product, decimals, customDiscounts, customQ}) {
  const priceListID = usePriceListIDContext()
  const marketContext = useMarketContext()
  const priceLists = marketContext.priceList || {[priceListID]: {}}
  const priceList = priceLists[priceListID]
  const brand = marketContext?.brands?.[product?.brand]
  const specs = marketContext?.specs
  const cart = useCartContext()
  const q = customQ || cart?.[product.objectID]?.q || 0
  const extraDiscount = useExtraDiscountContext()

  return {
    price: getPrice({product, priceLists, priceListID, decimals, iva: priceList?.iva_included}),
    suggested: priceList?.suggested && getPrice({
      product,
      priceLists,
      priceListID: priceList?.suggested,
      decimals: 0,
      iva: true
    }),
    original: priceListID !== 'g' && getPrice({
      product, priceLists, priceListID: 'g'//priceList.mother&&priceList.motherPercent&&priceList.motherPercent<1?priceList.mother:'g'
      , decimals, iva: priceList?.iva_included
    }),
    discount: getProductDiscount({
      product, priceList,
      priceLists, priceListID, brand, specs: product.specs?.map?.(s => specs?.[s]), q, extraDiscount, customDiscounts
    }).discount
  }
}

export function GetPricesTotals({products}) {
  const priceListID = usePriceListIDContext()
  const marketContext = useMarketContext()
  const priceLists = marketContext.priceList || {[priceListID]: {}}
  const priceList = priceLists[priceListID]
  const brands = marketContext?.brands
  const specs = marketContext?.specs
  const extraDiscount = useExtraDiscountContext()

  let discounts = 0
  let subtotal = 0
  let iva = 0
  let subtotal_plus_iva = 0
  let discounts_plus_iva = 0

  for (const i in products) {
    const product = products[i]
    const price = getPrice({
      product, priceLists, priceListID,
      iva: priceList.iva_included
    }) / (priceList?.iva_included ? (1 + (product.iva ?? 0.21)) : 1)
    let discount = price * (product.q || 0) * (getProductDiscount({
      specs: product.specs?.map?.(s => specs[s]),
      product, priceList, priceLists, priceListID, brand: brands?.[product.brand], q: product.q || 0, extraDiscount
    }).discount)
    subtotal_plus_iva += (price * (product.q || 0)) * (1 + (product.iva ?? 0.21))
    discounts += discount
    discounts_plus_iva += discount * (1 + (product.iva ?? 0.21))
    subtotal += price * (product.q || 0)
    iva += (price * (product.q || 0) - discount) * (product.iva ?? 0.21)
  }

  // const subtotal = products.reduce((sum,product)=>sum+getPrice({ product,priceLists,priceListID,iva:false })*(product.q||0),0);


  return {
    subtotal: round2decimals(subtotal),
    subtotal_plus_iva: round2decimals(subtotal_plus_iva),
    discounts: round2decimals(discounts),
    discounts_plus_iva: round2decimals(discounts_plus_iva),
    iva: round2decimals(iva),
    totalProducts: round2decimals(subtotal - discounts + iva),
  }
}

export function getProductDiscount({
                                     product,
                                     priceLists,
                                     priceListID,
                                     brand,
                                     specs,
                                     q,
                                     extraDiscount = 0,
                                     customDiscounts
                                   }) {
  const priceList = priceLists?.[priceListID]
  let motherPriceListID = priceList?.mother
  // let packUnitPrice = q > 1 && q >= product?.pack && getPrice({ product,priceLists,priceListID, packUnitPrice : true});
  // let price = packUnitPrice && getPrice({ product,priceLists,priceListID });
  // const packDiscount = priceList?.packDiscount//Math.round((packUnitPrice && price > packUnitPrice ? (price-packUnitPrice)/price : 0)*100)/100;
  const discounts = {
    motherPercent: !product?.price?.[priceListID] ? 1 - (priceList?.motherPercent || 1) : 0,
    product: product?.discounts?.[priceListID] || 0,
    motherPriceListProduct: !product?.price?.[priceListID] ? product?.discounts?.[motherPriceListID] || 0 : 0,
    brand: brand?.discounts?.[priceListID] || 0,
    motherPriceListBrand: !product?.price?.[priceListID] ? brand?.discounts?.[motherPriceListID] || 0 : 0,
    ...(specs?.reduce?.((p, s) => ({...p, ['spec_' + s?.objectID]: s?.discounts?.[priceListID] || 0}), {}) || {}),
    priceList: priceList?.discount || 0,
    priceListPack: {
      discount: q > 1 && q >= product?.pack && product?.pack > 1 && (priceList?.packDiscount || 0),
      plus: priceList?.packDiscountPlus
    },
    productPack: {
      discount: q > 1 && q >= product?.pack && product?.pack > 1 &&
        (product?.packDiscount?.[priceListID] || product?.packDiscount?.[motherPriceListID] || 0),
      plus: product?.packDiscountPlus?.[priceListID] || product?.packDiscountPlus?.[motherPriceListID]
    },
    extraDiscount: extraDiscount,
    [(customDiscounts?.name || 'customDiscounts') + '_Brand']: customDiscounts?.brands?.[product?.brand] || 0,
    [(customDiscounts?.name || 'customDiscounts') + '_Product']: customDiscounts?.products?.[product?.objectID] || 0,
    ...(specs?.reduce?.((p, s) => ({
      ...p,
      [(customDiscounts?.name || 'customDiscounts_Spec') + '_' + s?.objectID]: customDiscounts?.specs?.[s?.objectID] || 0
    }), {}) || {}),
    [(customDiscounts?.name || 'customDiscounts') + '_All']: customDiscounts?.all || 0,
  }
  const d = Object.entries(discounts).filter(a => a).sort(([, a], [, b]) =>
    ((a.plusOthers === false) - (b.plusOthers === false)) ||
    (!!b?.plus - !!a?.plus) || ((a?.discount || a || 0) - (b?.discount || b || 0))).reduce((p, [type, discount]) => {
      const plusSum = discount?.plusOthers !== false ? Object.entries(p.plusDiscounts).reduce((p, [, a]) => p + a, 0) : 0
      return discount?.plus && p.plus ? {
          ...p, discount: (discount?.discount || 0)
            + plusSum, name: type, plusDiscounts: {...p.plusDiscounts, [type]: (discount?.discount || 0)}
        } :
        ((discount?.discount || discount) + plusSum) > p.discount ? {
          ...p,
          discount: (discount?.discount || discount) + plusSum,
          name: type,
          plus: discount?.plusOthers !== false,
          plusDiscounts: discount?.plusOthers !== false ? p.plusDiscounts : {},
        } : p
    }
    , {discount: 0, plusDiscounts: {}, name: '', plus: true})
  return d
}


export function getPrice({product, priceLists, priceListID, decimals, iva = false, packUnitPrice = false}) {
  let priceList = priceLists[priceListID]
  const custom_iva = product?.iva !== undefined ? (1 + product.iva) : 1.21 //aca
  let p = product['price']?.[priceListID]
  let pp = product['packUnitPrice']?.[priceListID]
  let packDiscount = (product.pack && packUnitPrice && ((pp && (1 - pp / p)) || priceList?.packDiscount)) || 0
  if (!p && priceListID !== 'g') return round2decimals(getPrice({
    product,
    priceLists,
    priceListID: priceList.mother || 'g',
    decimals,
    iva
  }) * (1 - packDiscount))
  if (!p && priceListID === 'g') return 0
  // if ( priceList.mother === priceListID && priceList.motherPercent ) p *= priceList.motherPercent;
  p *= (iva ? custom_iva : 1)
  p *= (1 - packDiscount)
  return Number(p.toFixed(decimals !== undefined ? decimals : 2))
}

function round2decimals(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100
}


//
// const packUnitPrice = ( productID , iva ) => {
//     let product = app.products[productID];
//     let pL = app.pLByProduct(productID);
//     // let p = product.price[pL];
//     let packUnitPrice = product.packUnitPrice;
//     packUnitPrice = packUnitPrice && packUnitPrice[pL] && packUnitPrice[pL] < product.price[pL] ? packUnitPrice[pL] : 0;
//     packUnitPrice = iva ? (Math.round(packUnitPrice * 1.21 * 100) / 100) : packUnitPrice;
//     return Number(packUnitPrice.toFixed(2));
// }
//
//
// const minUnitsByProduct = (productID) => {
//     let product = app.products[productID];
//     if (!product.minUnits) return 1;
//     let pL = app.user.pL[app.seller];
//     return product.minUnits[pL] || (app.pL.mother ? product.minUnits[app.pL.mother] : product.minUnits['g'] || 1);
// }
