import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { db } from '../../firebase/database'
import { OrderCard } from '../../pages/myorders/MyOrders'

const useStyles = makeStyles((theme) => ({
  storeMainBox: {
    border: '1px solid #ccc',
    borderRadius: 5,
    margin: '1rem',
  },
  headerDetail: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: '#F1F3F2',
  },
  datosEnvioWrap: {
    width: '60%',
  },
  datosEnvioInfo: {
    justifyContent: 'flex-start',
  },

  datosEnvioInfoText: {
    margin: 0,
  },
  datosEnvioInfoDateText: {
    margin: 0,
    fontWeight: 'bold',
  },
  contentDetailBox: {
    backgroundColor: '#fff',
    padding: '.5rem',
  },
  datosNombreStore: {
    margin: 0,
    fontSize: 20,
  },
  datosNombreText: {
    margin: 0,
    fontSize: 15,
  },

  footerDetail: {
    borderTop: '1px solid #ccc',
    backgroundColor: '#fff',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  skeletonBox: {
    width: 300,
    padding: 10,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  footerText: {
    margin: 0,
    padding: '.5rem',
  },
}))

export const StoreOrders = () => {
  const orders = GetOrders()
  const classes = useStyles()
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  return (
    <Box>
      {loading
        ? orders.map((order, i) => (
          <Box key={i}
            pt={0.8}
            className={classes.skeletonBox}>
            <Skeleton
              animation="wave"
              variant="rect"
              height={120}
              width="100%"
            />{' '}
            <Skeleton width="100%" />
          </Box>
        ))
        : orders.map((order) => <OrderCard order={order}
          key={order.id} />)}
    </Box>
  )
}

function GetOrders() {
  const history = useHistory()
  const locId = history.location.pathname.split('/').pop()
  const [orders, setOrders] = useState([])

  useEffect(() => {
    let active = true
    if (locId)
      db.collection('orders')
        .where('store', '==', locId)
        .orderBy('timestamp', 'desc')
        .limit(10)
        .get()
        .then((docs) => {
          let ndocs = []
          docs.forEach(function (doc) {
            let data = doc.data()
            ndocs.push({ ...data, id: doc.id })
          })
          active && setOrders(ndocs)
        })
    return () => (active = false)
  }, [locId])

  return orders
}
