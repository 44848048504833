import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  flexSidebar: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 4,
    marginTop: '20px',
    marginRight: '20px',
  },
  rootDiv: {
    width: '40vw',
    marginTop: '2vw',
  },
  rootDivPhone: {
    width: '70vw',
    marginLeft: '25vw',
    marginTop: '2vw',
  },
  rootDivPhone2: {
    marginTop: '4vw',
  },
  listNameStore: {
    fontSize: '1.5rem',
    margin: 0,
    fontWeight: 'bold',
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
  listNameValidatedMessage: {
    padding: '0rem .5rem',
    color: '#8EBC38',
    fontSize: '1rem',
    borderRadius: 4,
    fontWeight: 'bold',
    maxWidth: 400,
    margin: 0,
  },
  errorValidated: {
    maxWidth: 400,
    [theme.breakpoints.down('xs')]: {
      padding: '10px 0px',
    },
  },
  listBox: {
    padding: '.5rem .8rem',
    [theme.breakpoints.down('sm')]: {
      padding: '.5rem .8rem',
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: ' -4px 9px 5px 1px rgba(227,220,220,0.78)',
    backgroundColor: '#fff',
    borderRadius: 4,
  },
  listItems: {
    minWidth: 100,
    maxWidth: 700,
  },
  listItemsName: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    minWidth: 100,
    maxWidth: 1200,
  },
  listItemsInfo: {
    textAlign: 'right',
  },
}))
