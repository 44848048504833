import { ContactMail, ShoppingBasket, Store } from '@material-ui/icons'

export const navBarOptions = [
  {
    link: 'myorders',
    Icon: ShoppingBasket,
    label: 'Mis Compras',
  },
  {
    link: 'newStore',
    Icon: ContactMail,
    label: 'Nueva Tienda',
  },
  {
    link: 'myStores',
    Icon: Store,
    label: 'Mis Tiendas',
  },
]
