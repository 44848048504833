import { red } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    link: {
        textDecoration: 'unset',
    },
    process: {
        width: '100%',
    },

    products: {
        maxWidth: '1000px',
        margin: 'auto',
    },

    root: {
        margin: 'auto',
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },

    priceTotalRoot: {
        width: 'fit-content',
        margin: 'auto',
    },
    priceTotal: {
        display: 'flex',
        'fontWeight': '600',
        color: theme.palette.primary.main,
        'fontSize': '2.7rem',
        'fontFamily': '"Helvetica Neue Lt Std", sans-serif',
        'lineHeight': '1.235',
        'margin': 'auto'
    },
    priceTotalTitle: {
        'fontWeight': '500',
        'color': '#8a8a8a',
        'marginLeft': '20px'
    },
    priceTotalDecimals: {
        'color': '#8a8a8a',
        'fontSize': '0.6em',
    },
    priceTotalPlusIVA: {
        'fontSize': '0.5em',
        'alignSelf': 'center',
        'color': '#8a8a8a',
        'marginLeft': '0.5em'
    },
    thumbnail: {
        backgroundSize: 'contain',
        height: 0,
        paddingTop: '100%', // 16:9
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        [theme.breakpoints.down(400)]: {
            marginTop: '25px',
        }
    },
    media: {
        backgroundSize: 'contain',
        height: 0,
        paddingTop: '100%', // 16:9
        position: 'relative',
        top: 0,
        left: 0,
        width: '100%',
        [theme.breakpoints.down(400)]: {
            marginTop: '25px',
        }
        // backgroundSize: 'contain',
        // height: 0,
        // paddingTop: '100%',
        // "borderBottomWidth": "0.1px",
        // "borderBottomStyle": "solid",
        // "borderBottomColor": "darkgrey",
    },
    cardMediaContent: {
        position: 'relative',
        maxWidth: '70px',
        minWidth: '70px',
        marginRight: '15px',
        minHeight: '70px',
        [theme.breakpoints.down(750)]: {
            position: 'relative',
            maxWidth: '120px',
            minWidth: '120px',
            marginRight: '10px',
            minHeight: '120px',
        }
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    cardContent: {
        // height: 100,
        background: 'white',
        // marginBottom: 25,
        position: 'relative',
        // paddingBottom:'10px',
        paddingRight: '5px',
        // paddingTop: '12px',
        boxShadow: 'none',
        borderRadius: '0px',
        marginTop: '15px'
    },
    container: {
        position: 'relative',
        overflow: 'hidden',
        '&::after': {
            content: '\'\'',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '50px',
        },
    },
    scrollable: {
        width: '100%',
        maxHeight: 'calc(100vh - 500px)',
        overflow: 'auto',
        paddingBottom: '35px',
    },
    content: {
        height: '0px',
    },
    title: {
        'fontWeight': '600',
        'fontSize': '0.9rem',
        'letterSpacing': '-0.05rem',
    },
    titleCard: {
        // height: 85,
        overflow: 'hidden',
        width: 'calc(100% - 32px)',
    },
    cartElement: {
        margin: 'auto',
        marginBottom: '3em',
        marginTop: '1em',
        width: '80%',
        maxWidth: '300px',
    },
    addCart: {
        textTransform: 'none',
        width: 'stretch',
        height: 41,
        fontWeight: '500',
        margin: '0 10px 0 10px',
    },
    addCartOnlyBigScreen: {
        marginLeft: '4px',
        [theme.breakpoints.down(350)]: {
            display: 'none',
        }
    },
    btnBuyed: {
        'borderRadius': '10px',
        'border': 'none',
        'color': '#4A4A4A',
        'textAlign': 'center',
        'fontSize': '20px',
        'padding': '2px',
        'width': '100%',
        'transition': 'all 0.5s',
        // margin: '0 10px 10px 10px',
        'fontWeight': '500',
        'display': 'table',
        'tableLayout': 'fixed',
        '& > div': {
            'display': 'table-cell',
            'verticalAlign': 'top',
            'textAlign': 'center'
        },
        '@media (max-width:750px)': {
            'borderRadius': '10px',
            'border': 'none',
            'color': '#4A4A4A',
            'textAlign': 'center',
            'fontSize': '20px',
            'padding': '2px',
            'width': '100%',
            'transition': 'all 0.5s',
            // margin: '0 10px 10px 10px',
            'fontWeight': '500',
            'display': 'table',
            'tableLayout': 'fixed',
            'margin': '10px',
            '& > div': {
                'display': 'table-cell',
                'verticalAlign': 'top',
                'textAlign': 'center'
            },
        },
    },
    addCartButton: {
        cursor: 'pointer',
    },
    productTotal: {
        'fontWeight': '600',
        'position': 'absolute',
        'right': 0,
        'bottom': 15,
        color: theme.palette.primary.main,
        'fontSize': '1rem',
        'fontFamily': '"Helvetica Neue Lt Std", sans-serif',
        'lineHeight': '1.235',
        textAlign: 'end',
    },
    productTotalInProducts: {
        'fontWeight': '600',
        color: theme.palette.primary.main,
        'fontSize': '1rem',
        'fontFamily': '"Helvetica Neue Lt Std", sans-serif',
        'lineHeight': '1.235',
        'width': '100px'
    },
    deleteProduct: {
        position: 'absolute',
        right: '12px',
        top: '12px',
        fontSize: 'x-large',
        cursor: 'pointer',
        opacity: '0.8',
    },
    summary_sticky: {
        position: 'sticky',
        bottom: 0
    },
    brand: {

        'position': 'absolute',
        'top': '9px',
        'right': '0px',
        'height': '45px',
        'width': '45px',
        'borderCollapse': 'separate',
        'borderRadius': '50%',
        'backgroundColor': 'white',
        'boxShadow': '0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 2px 9px 1px rgba(0, 0, 0, 0.12), 0 4px 2px -2px rgba(0, 0, 0, 0.2)',
        [theme.breakpoints.down(700)]: {
            top: '10px',
            right: '-5px',
            width: '35px',
            height: '35px',
        }
    },
    purchaseMinRequired: {
        'color': '#3bbd00',
        'fontSize': '1.2em',
        'fontWeight': '450',
        'width': 'fit-content',
        'margin': 'auto'
    },
    shipping_cost: {
        'color': '#247200',
        'fontSize': '1.2em',
        'fontWeight': '450',
        // "marginBottom": '1em',
        'margin': '1em auto',
        'textAlign': 'center',
        'maxWidth': '80%',
        // "color": "#4A4A4A",
    },
    shipping_time: {
        'fontSize': '1.2em',
        'fontWeight': '450',
        'marginBottom': '1.5rem',
        'textAlign': 'center',
    },
    shippingCardContainer: {
        'display': 'flex',
        'maxWidth': '800px',
        'margin': 'auto',
        'alignItems': 'strech',
        'justifyContent': 'space-between',
        [theme.breakpoints.down(620)]: {
            'flexDirection': 'column',
        },
    },
    shippingCard: {
        'border': '3px solid #fff',
        'borderLeftColor': '#247200',
        'width': '100%',
        'margin': '1rem',
        'borderRadius': '7px',
        [theme.breakpoints.down(620)]: {
            'width': '90%',
        },
    },
    priceListNotes: {
        'color': '#721a14',
        'fontSize': '1.2em',
        'fontWeight': '450',
        'margin': '1em auto',
        'textAlign': 'right',
        'maxWidth': '80%',
    },
    btnMoveToCart: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        background: '#b6d47f',
        padding: 3,
        width: 120,
        textAlign: 'center',
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 15,
        cursor: 'pointer',
        transition: 'all ease in out 0.25s',
        '&:hover': {
            backgroundColor: '#A9C37A'
        },

    },
    messageNoStock: {
        background: '#fcf8e3',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        padding: 3,
        width: 120,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 15,
        transition: 'all ease in out 0.25s',
    },
    productStock: {
        'display': 'flex',
        'alignItems': 'center',
        'marginTop': '0',
        'marginBottom': '5px',
        // "fontWeight": "600",
        // color red warning
        'fontSize': '0.75rem',
        'color': theme.palette.error.main,
        'textAlign': 'center',
    }
}))
