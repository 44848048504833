import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { DateRange, LocalShipping } from '@material-ui/icons'
import AlternateEmailOutlinedIcon from '@material-ui/icons/AlternateEmailOutlined'
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined'
import WatchLaterOutlinedIcon from '@material-ui/icons/WatchLaterOutlined'
import { AsYouType } from 'libphonenumber-js'
import isEqual from 'lodash/isEqual'
import React, { useEffect, useReducer, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useUserData, useUserDataSet } from '../../context/UserContext'
import { db, newDoc, updateDoc } from '../../firebase'
import DirectionUser from './components/DirectionUser'
import SchedulesMap from './components/SchedulesMap'
import useStyles from './styles'

export default function Store() {
  return <></>
}

export function StoreEdit({ storeID, showTitle = true }) {
  const history = useHistory()
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [confirmButtonClicked, setConfirmButtomClicked] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const [storeData, setStoreData] = useReducer(
    (prevStoreData, o) =>
      Object.entries(o).reduce(
        (p, [k, v]) => p || !isEqual(prevStoreData[k], v),
        false
      )
        ? o.onlineData
          ? o
          : storeID
            ? (() => {
              updateDoc({ indexName: 'stores', id: storeID, hit: o })
              return { ...prevStoreData, ...o }
            })()
            : { ...prevStoreData, ...o }
        : prevStoreData,
    { razonsocial: '', storeName: '', cuit: '', address: {} }
  )

  const [address, setAddress] = useState({})
  const cuitData = useGetCuitData(storeData.cuit)
  const { uid, isAnonymous } = useUserData()

  useEffect(() => {
    isAnonymous && history.push('/')
  }, [isAnonymous])

  useEffect(() => {
    let active = true
    let snap
    storeID &&
      (snap = db
        .collection('stores')
        .doc(storeID)
        .onSnapshot((d) => {
          let data = d.data()
          active &&
            setStoreData({ ...data, onlineData: true, algoliaUpdated: true })
          active &&
            data.address &&
            setAddress((a) => (!isEqual(data.address, a) ? data.address : a))
        }))
    return () => {
      active = false
      snap && snap()
    }
  }, [storeID])

  useEffect(() => {
    cuitData.nombre && setStoreData({ razonsocial: cuitData.nombre, cuit: cuitData.id_persona })
  }, [cuitData])

  const isAutocompleteCompleted = !!storeData.address?.formatted_address

  const isDisabledConfirm =
    !storeData.storeName ||
    String(storeData.cuit)?.length !== 11 ||
    !storeData.domicilioLegal ||
    !storeData.address?.geometry ||
    !isAutocompleteCompleted ||
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(storeData.email)

  const isMobile = useMediaQuery('(max-width: 600px)')

  const userDataSet = useUserDataSet()
  async function addNewStore() {
    let newstoreid =
      storeID ||
      (await newDoc({
        indexName: 'stores',
        hit: { ...storeData, users: [uid], validated: false, seller: 'v' },
      })).id
    userDataSet({ selectedStore: newstoreid }, 'update')
    await new Promise(r => setTimeout(r, 500))
    history.push('/app/storesDetail/' + newstoreid)
  }
  return (
    <div className={classes.root}>
      {showTitle && <h3>Agregar Datos de tu Comercio</h3>}

      <form className={classes.form}>
        <Box
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            width: '100%',
            gap: 20,
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
        >
          <Box className={classes.form}>
            <DirectionUser
              storeID={storeID}
              storeData={storeData}
              updateStoreData={setStoreData}
              addressSave={storeData?.address?.formatted_address}
            />

            <div className={classes.fieldIcons}>
              <TextFieldStore
                id="storeName"
                error={confirmButtonClicked && !storeData.storeName}
                label={
                  <span style={{ alignItems: 'center', display: 'flex' }}>
                    <StorefrontOutlinedIcon
                      style={{ fontSize: '18px', margin: '5px' }}
                    />
                    Nombre de la Tienda
                  </span>
                }
                fullWidth
                variant="outlined"
                value={storeData.storeName}
                setValue={(v) => setStoreData({ storeName: v })}
              />
            </div>
            <div className={classes.fieldIcons}>
              <TextFieldStore
                id="email"
                error={
                  confirmButtonClicked &&
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                    storeData.email
                  )
                }
                label={
                  <span style={{ alignItems: 'center', display: 'flex' }}>
                    <AlternateEmailOutlinedIcon
                      style={{ fontSize: '18px', margin: '5px' }}
                    />
                    Email
                  </span>
                }
                fullWidth
                variant="outlined"
                value={storeData.email}
                setValue={(v) => setStoreData({ email: v })}
              />
            </div>
            {storeID && (
              <div className={classes.fieldIcons}>
                <Button
                  onClick={handleOpen}
                  fullWidth
                  startIcon={<WatchLaterOutlinedIcon />}
                  variant="outlined"
                  color="primary"
                >
                  Editar horarios
                </Button>

                <Dialog
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open}
                  onClose={handleClose}
                >
                  <div className={classes.paper}>
                    <SchedulesMap isEditable={true}
                      storeID={storeID} />
                  </div>
                </Dialog>
              </div>
            )}
            <div className={classes.fieldIcons}>
              <TextField
                id="cuit"
                type="numeric"
                error={
                  confirmButtonClicked &&
                  String(storeData.cuit)?.length !== 11
                }
                label={
                  <span style={{ alignItems: 'center', display: 'flex' }}>
                    <ContactMailOutlinedIcon
                      style={{ fontSize: '18px', margin: '5px' }}
                    />
                    Cuit
                  </span>
                }
                fullWidth
                variant="outlined"
                value={storeData.cuit}
                onChange={(e) =>
                  Number(e.target.value).toString() != 'NaN' &&
                  e.target.value?.length <= 11 &&
                  setStoreData({ cuit: e.target.value })
                }
              />
            </div>
            <div className={classes.fieldIcons}>
              <TextFieldStore
                id="razonsocial"
                error={confirmButtonClicked && !storeData.razonsocial}
                label={
                  <span style={{ alignItems: 'center', display: 'flex' }}>
                    <ContactMailOutlinedIcon
                      style={{ fontSize: '18px', margin: '5px' }}
                    />
                    Razon Social
                  </span>
                }
                fullWidth
                variant="outlined"
                value={storeData.razonsocial}
                setValue={(v) => setStoreData({ razonsocial: v })}
              />
            </div>
            {/* cuando este la funcion de los carries borrar */}
            {address.geometry && storeData?.shippings?.length === 0 && (
              <>
                <div>
                  <h4>En caso de estar en el interior de Argentina:</h4>
                  <p>
                    Por Favor complete los datos del Transporte/Expreso, día de
                    despacho y dirección en Buenos Aires. Muchas Gracias
                  </p>
                </div>
                <div className={classes.fieldIcons}>
                  <TextFieldStore
                    fullWidth
                    id="transporte"
                    label={
                      <span style={{ alignItems: 'center', display: 'flex' }}>
                        <LocalShipping
                          style={{ fontSize: '18px', margin: '5px' }}
                        />
                        Transporte/Expreso
                      </span>
                    }
                    variant="outlined"
                    value={storeData.transporte}
                    setValue={(v) => setStoreData({ transporte: v })}
                  />
                </div>
                <div className={classes.fieldIcons}>
                  <TextFieldStore
                    fullWidth
                    id="diadedespacho"
                    label={
                      <span style={{ alignItems: 'center', display: 'flex' }}>
                        <DateRange
                          style={{ fontSize: '18px', margin: '5px' }}
                        />
                        Día de Despacho
                      </span>
                    }
                    variant="outlined"
                    value={storeData.diadedespacho}
                    setValue={(v) => setStoreData({ diadedespacho: v })}
                  />
                </div>
                <div className={classes.fieldIcons}>
                  <TextFieldStore
                    fullWidth
                    id="addressbsas"
                    label={
                      <span style={{ alignItems: 'center', display: 'flex' }}>
                        <LocationOnOutlinedIcon
                          style={{ fontSize: '18px', margin: '5px' }}
                        />
                        Dirección en Buenos Aires
                      </span>
                    }
                    variant="outlined"
                    value={storeData.addressbsas}
                    setValue={(v) => setStoreData({ addressbsas: v })}
                  />
                </div>
              </>
            )}
            <div className={classes.fieldIcons}>
              <TextFieldStore
                fullWidth
                id="domicilioLegal"
                error={confirmButtonClicked && !storeData.domicilioLegal}
                label={
                  <span style={{ alignItems: 'center', display: 'flex' }}>
                    <LocationOnOutlinedIcon
                      style={{ fontSize: '18px', margin: '5px' }}
                    />
                    Domicilio legal
                  </span>
                }
                variant="outlined"
                value={storeData.domicilioLegal}
                setValue={(v) => setStoreData({ domicilioLegal: v })}
              />
            </div>
          </Box>
          <Box
            style={{
              flexDirection: 'column',
              display: 'flex',
              gap: 10,
              padding: '20px',
            }}
          >
            <div className={classes.purchasingResponsable}>
              <Typography style={{ fontWeight: 'bold', marginTop: 3 }}>
                Responsable de compras
              </Typography>
              <div className={classes.fieldIcons}>
                <TextFieldStore
                  fullWidth
                  id="responsableName"
                  label={
                    <span style={{ alignItems: 'center', display: 'flex' }}>
                      Nombre
                    </span>
                  }
                  variant="outlined"
                  size="small"
                  value={storeData.responsibles?.purchasing?.name}
                  setValue={(v) =>
                    setStoreData({
                      responsibles: {
                        ...storeData.responsibles,
                        purchasing: {
                          ...storeData?.responsibles?.purchasing,
                          name: v,
                        },
                      },
                    })
                  }
                />
              </div>
              <div className={classes.fieldIcons}>
                <TextField
                  fullWidth
                  id="responsablePhone"
                  placeholder="(11) 0 000-1111"
                  type="tel"
                  size="small"
                  label={
                    <span style={{ alignItems: 'center', display: 'flex' }}>
                      Telefono
                    </span>
                  }
                  variant="outlined"
                  value={storeData.responsibles?.purchasing?.phone}
                  onChange={(e) => {
                    let newValue = new AsYouType('AR').input(e.target.value)
                    setStoreData({
                      responsibles: {
                        ...storeData.responsibles,
                        purchasing: {
                          ...storeData?.responsibles?.purchasing,
                          phone: newValue,
                        },
                      },
                    })
                  }}
                />
              </div>
              <div className={classes.fieldIcons}>
                <TextFieldStore
                  fullWidth
                  id="responsableEmail"
                  size="small"
                  label={
                    <span style={{ alignItems: 'center', display: 'flex' }}>
                      Email
                    </span>
                  }
                  variant="outlined"
                  value={storeData.responsibles?.purchasing?.email}
                  setValue={(v) =>
                    setStoreData({
                      responsibles: {
                        ...storeData.responsibles,
                        purchasing: {
                          ...storeData?.responsibles?.purchasing,
                          email: v,
                        },
                      },
                    })
                  }
                />
              </div>
            </div>
            <div className={classes.purchasingResponsable}>
              <Typography style={{ fontWeight: 'bold', marginTop: 3 }}>
                Responsable de pagos
              </Typography>
              <div className={classes.fieldIcons}>
                <TextFieldStore
                  fullWidth
                  id="responsablePaymentsName"
                  label={
                    <span style={{ alignItems: 'center', display: 'flex' }}>
                      Nombre
                    </span>
                  }
                  variant="outlined"
                  size="small"
                  value={storeData.responsibles?.payments?.name}
                  setValue={(v) =>
                    setStoreData({
                      responsibles: {
                        ...storeData.responsibles,
                        payments: {
                          ...storeData?.responsibles?.payments,
                          name: v,
                        },
                      },
                    })
                  }
                />
              </div>
              <div className={classes.fieldIcons}>
                <TextField
                  fullWidth
                  id="responsablePaymentsPhone"
                  size="small"
                  placeholder="(11) 0 000-1111"
                  type="tel"
                  label={
                    <span style={{ alignItems: 'center', display: 'flex' }}>
                      Telefono
                    </span>
                  }
                  variant="outlined"
                  value={storeData.responsibles?.payments?.phone}
                  onChange={(e) => {
                    let newValue = new AsYouType('AR').input(e.target.value)

                    setStoreData({
                      responsibles: {
                        ...storeData.responsibles,
                        payments: {
                          ...storeData?.responsibles?.payments,
                          phone: newValue,
                        },
                      },
                    })
                  }}
                />
              </div>
              <div className={classes.fieldIcons}>
                <TextFieldStore
                  fullWidth
                  id="responsablePaymentsEmail"
                  size="small"
                  label={
                    <span style={{ alignItems: 'center', display: 'flex' }}>
                      Email
                    </span>
                  }
                  variant="outlined"
                  value={storeData.responsibles?.payments?.email}
                  setValue={(v) =>
                    setStoreData({
                      responsibles: {
                        ...storeData.responsibles,
                        payments: {
                          ...storeData?.responsibles?.payments,
                          email: v,
                        },
                      },
                    })
                  }
                />
              </div>
            </div>
          </Box>
        </Box>
        {!storeID && (
          <div>
            <Button
              disabled={confirmButtonClicked && isDisabledConfirm}
              fullWidth
              variant="contained"
              onClick={() => {
                setConfirmButtomClicked(true)
                !isDisabledConfirm && addNewStore()
              }}
              color={'secondary'}
              style={{ color: 'black' }}
            >
              Confirmar
            </Button>
          </div>
        )}
      </form>
    </div>
  )
}

function TextFieldStore({ value: V, setValue: S, ...props }) {
  const [value, setValue] = useState(V || '')

  useEffect(() => {
    setValue(V || '')
  }, [V])

  return (
    <TextField
      {...props}
      value={value}
      onChange={(e) =>
        value !== e.target.value &&
        setValue(e.target.value) &&
        value !== e.target.value &&
        setValue(e.target.value)
      }
      onBlur={(e) => S && S(e.target.value)}
    />
  )
}

export function useGetCuitData(cuit) {
  const [cuitData, setCuitData] = useState({})
  useEffect(() => {
    var active = true
    if (cuit && cuit.length > 7) {
      (async () => {
        await new Promise(r => setTimeout(r, cuit.length > 10 ? 0 : 800))
        let data = active && await (await fetch(
          'https://api.vivetmarket.com/v1/plant/cuit/' +
          cuit,
          {
            method: 'GET',
            redirect: 'follow',
          }
        )).json()
        active && data.nombre && setCuitData(data)
      })()
    }
    return () => (active = false)
  }, [cuit])

  return cuitData
}
