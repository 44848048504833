import { Box } from '@material-ui/core'
import { useEffect } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'

import { useChangeTitle } from '../../components/Utils/useChangeTitle'
import config from '../../config/config'
import { ProcessProvider, useProcessState } from '../../context/ProcessContext'
import { useStores, useUserData } from '../../context/UserContext'
import { updateDoc } from '../../firebase/database'
//components
import AddressSelect from './address/Address'
import Takeaway from './address/Takeaway'
import CarrierSelect from './carrierSelect/carrierSelect'
import ConfirmOrder from './confirm'
import PaymentOptionSelect from './payment'
import { PreferrenceTime } from './preferrenceTime/preferrenceTime'
import Shipoptionselect from './shipoptionselect'
// styles
import useStyles from './styles'
import SuccessPage from './success/Success'

export default function Process() {
  useChangeTitle(`Carrito - ${config.title || ''}`)


  return (
    <ProcessProvider>
      <ProcessHelper />
    </ProcessProvider>
  )
}

function ProcessHelper() {
  const classes = useStyles()
  const ProcessState = useProcessState()
  const location = useLocation()
  const history = useHistory()
  const stores = useStores()
  const isStoreValidated = stores?.some(a => a.validated)
  const { isAnonymous, uid, isOnlineData } = useUserData()

  useEffect(() => {
    uid && isAnonymous === false && isOnlineData && updateDoc({ indexName: 'users', id: uid, hit: { algoliaUpdated: false } })
  }, [uid, isAnonymous, isOnlineData])

  // const { uid } = useUserData();
  // const [hits, , cart] = useCartList();

  useEffect(() => {
    config.stores && !isStoreValidated && history.push('/')
  }, [isStoreValidated])

  // function setSteps(){
  // const pi = location?.pathname?.replace('/app/buy/', '');
  //          if(pi === 'cart') return ['Carrito', 'Medio de envio', 'Metodo de pago', 'Confirmar']
  //              if(pi === 'addressselect') return handleNext
  //                if(pi === 'shipoptionselect') return 'Metodo de pago'
  //                  if(pi === 'payselect') return 'Metodo de pago'
  //                    if(pi === 'preferrenceTime') return 'Horario de preferencia'
  //                      if(pi === 'confirmorder') return 'Confirmar'
  // }

  //processInstance
  // const pi = location?.pathname?.replace('/app/buy/', '')
  const boxConditional = location.pathname === '/app/buy/cart'

  return (
    <Box className={boxConditional ? classes.cartBox : classes.boxCart}>
      {/* {pi !== 'success' && <StepperProcess />} */}
      {!ProcessState.address && (
        <>
          <Switch>
            <Route path="/app/buy/cart"
              component={ConfirmOrder} />
            <Route path="/app/buy/addressselect"
              component={AddressSelect} />
            <Route path="/app/buy/carrierselect"
              component={CarrierSelect} />
            <Route path="/app/buy/takeaway"
              component={Takeaway} />
            <Route path="/app/buy/payselect"
              component={PaymentOptionSelect} />
            <Route path="/app/buy/success"
              component={SuccessPage} />
            <Route path="/app/buy/success_business"
              component={SuccessPage} />
          </Switch>
        </>
      )}
      {ProcessState.address && (
        <>
          <Switch>
            <Route path="/app/buy/takeaway"
              component={Takeaway} />
            <Route path="/app/buy/carrierselect"
              component={CarrierSelect} />
            <Route path="/app/buy/addressselect"
              component={AddressSelect} />
            <Route
              path="/app/buy/shipoptionselect"
              component={Shipoptionselect}
            />
            <Route path="/app/buy/payselect"
              component={PaymentOptionSelect} />
            <Route
              path="/app/buy/preferrenceTime"
              component={PreferrenceTime}
            />
            <Route path="/app/buy/cart"
              component={ConfirmOrder} />
            <Route path="/app/buy/success"
              component={SuccessPage} />
            <Route path="/app/buy/success_business"
              component={SuccessPage} />
          </Switch>
        </>
      )}
    </Box>
  )
}
