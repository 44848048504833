import Avatar from '@material-ui/core/Avatar'
import TextField from '@material-ui/core/TextField'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, { useEffect, useState } from 'react'

import { useChangeTitle } from '../../components/Utils/useChangeTitle'
import config from '../../config/config'
import { useUserData } from '../../context/UserContext'
import { db, storage, updateDoc } from '../../firebase'
import ProfileNav from './ProfileNav'
import useStyles from './styles'

export default function Profile() {
  const classes = useStyles()
  const less950px = useMediaQuery('(min-width:950px)')
  const less1024px = useMediaQuery('(min-width:1024px)')
  const [profileData, setProfileData] = useState({
    name: '',
    email: '',
    birthday: '',
    image: '',
  })
  const { uid } = useUserData()
  const setValue = (o) =>
    Object.entries(o).reduce((p, [k, v]) => p || profileData[k] !== v, false) &&
    Object.entries(o).reduce((p, [, v]) => p && v, true) &&
    (uid
      ? db.collection('users').doc(uid).update(o)
      : setProfileData((s) => ({ ...s, ...o })))

  useEffect(() => {
    let active = true
    let snap
    uid &&
      (snap = db
        .collection('users')
        .doc(uid)
        .onSnapshot((d) => active && setProfileData(d.data())))
    return () => {
      active = false
      snap && snap()
    }
  }, [uid])

  config?.plant_web_view === true
    ? useChangeTitle('Mi Cuenta - Plant')
    : useChangeTitle('Mi Cuenta - Plant business')
  const less850px = useMediaQuery('(min-width:850px)')


  const [images] = useState([])
  const [, setImageURLs] = useState([])

  useEffect(() => {
    if (images.length < 1) return
    const newImageURLs = []
    images.forEach((image) => newImageURLs.push(URL.createObjectURL(image)))
    setImageURLs(newImageURLs)
  }, [images])


  const onFileChange = async (e) => {
    e.preventDefault()
    // console.log(e)
    const file = e.target.files[0]
    const storageRef = storage.ref()
    const fileRef = storageRef.child(`users/${uid}`)
    await fileRef.put(file)
    let fileToUpdate = await fileRef.getDownloadURL()
    fileToUpdate &&
      updateDoc({
        indexName: 'users',
        id: uid,
        hit: {
          ['image']: fileToUpdate,
        },
      })
    file &&
      setProfileData({ ...profileData, image: await fileRef.getDownloadURL() })
  }

  return (
    <div
      style={{
        marginTop: less950px ? '20px' : '2px',
        marginRight: less1024px ? '12px' : '2px',
      }}
      className={classes.root}
    >
      {less850px ? <ProfileNav /> : <></>}
      <div
        style={{
          backgroundColor: 'white',
          display: 'flex',
          background: '#F4F4F4',
          width: less950px ? '90%' : '98%',
          maxHeight: '90vh',
          marginLeft: less1024px ? 40 : 5,
          borderRadius: '32px',
          justifyContent: 'center',
          overflowY: 'auto',
        }}
      >
        <form
          className={classes.form}
          style={{ width: !less950px ? '90%' : '50%' }}
        >
          <div style={{ maxWidth: 500, margin: 'auto' }}>
            <h2>Mi cuenta</h2>
            <h4 className={classes.subtitle}>Nombre de usuario</h4>
            <TextFieldProfile
              className={classes.field}
              id="name"
              variant="standard"
              value={profileData.name}
              setValue={(v) => setValue({ name: v })}
            />
            <h4 className={classes.subtitle}>Email</h4>
            <TextFieldProfile
              className={classes.field}
              id="email"
              variant="standard"
              value={profileData.email}
              setValue={(v) => setValue({ email: v })}
            />
            <div style={{ display: 'flex', marginTop: '10px' }}>
              <h4 className={classes.subtitle}>Foto de perfil</h4>
              <Avatar
                style={{ marginLeft: '5px' }}
                alt={profileData.name}
                src={profileData.image}
              />
              <input
                style={{
                  cursor: 'pointer',
                  opacity: 0,
                  position: 'relative',
                  width: '100px',
                  height: '50px',
                  right: '50px',
                }}
                type="file"
                accept="image/*"
                onChange={onFileChange}
              />
            </div>
            <h4 className={classes.subtitle}>
              Fecha de nacimiento {'(Día/Mes/Año)'}
            </h4>
            <TextFieldProfile
              type="date"
              className={classes.field}
              id="birthday"
              variant="standard"
              value={profileData.birthday}
              setValue={(v) => setValue({ birthday: v })}
            />
            <>
              <h4 className={classes.subtitle}>Cuit</h4>
              <TextFieldProfile
                className={classes.field}
                disabled
                id="roles"
                variant="standard"
                value={profileData?.cuit}
              />
            </>
            <h4 className={classes.subtitle}>Numero de telefono</h4>
            <TextFieldProfile
              className={classes.field}
              disabled
              id="phoneNumber"
              variant="standard"
              value={profileData.phoneNumber}
            />
            {/* {profileData.priceList?<><h4 className={classes.subtitle}>Lista de precios</h4>
                        <TextFieldProfile
                            className={classes.field}
                            disabled
                            id="listaDePrecios"
                            variant="standard"
                            value={profileData.priceList.v}
                        /></>:<></>} */}
            {profileData.roles ? (
              <>
                <h4 className={classes.subtitle}>Tus roles</h4>
                <TextFieldProfile
                  className={classes.field}
                  disabled
                  id="roles"
                  variant="standard"
                  value={profileData.roles}
                />
              </>
            ) : (
              <></>
            )}
            {profileData.currentRole ? (
              <>
                <h4 className={classes.subtitle}>Rol actual</h4>
                <TextFieldProfile
                  className={classes.field}
                  disabled
                  id="currentRole"
                  variant="standard"
                  value={profileData.currentRole}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}

function TextFieldProfile({ value: V, setValue: S, ...props }) {
  const [value, setValue] = useState(V)

  useEffect(() => {
    setValue(V)
  }, [V])

  return (
    <TextField
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => S && S(e.target.value)}
    />
  )
}
