import React, { useState } from 'react'

var ProcessStateContext = React.createContext()
var ProcessDispatchContext = React.createContext()
var CommentContext = React.createContext()


function processReducer(state, action) {
  if (action.reset) return {}
  if (action.field) return { ...state, [action.field]: action.value }
  return removeEmpty({ ...state, ...action })
}

const removeEmpty = (obj) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key])
    else if (obj[key] === undefined) delete obj[key]
  })
  return obj
}

function ProcessProvider({ children }) {
  const [state, dispatch] = React.useReducer(processReducer, {
    address: false,
  })
  const [comment, setComment] = useState('')
  return (
    <ProcessStateContext.Provider value={state}>
      <ProcessDispatchContext.Provider value={dispatch}>
        <CommentContext.Provider value={{ comment, setComment }}>
          {children}
        </CommentContext.Provider>
      </ProcessDispatchContext.Provider>
    </ProcessStateContext.Provider>
  )
}

function useProcessState() {
  var context = React.useContext(ProcessStateContext)
  if (context === undefined) {
    throw new Error('useProcessState must be used within a ProcessProvider')
  }
  return context
}
function useCreateComment() {
  var context = React.useContext(CommentContext)
  if (context === undefined) {
    throw new Error('useProcessState must be used within a ProcessProvider')
  }
  return context
}

function useProcessDispatch() {
  var context = React.useContext(ProcessDispatchContext)
  if (context === undefined) {
    throw new Error('useProcessDispatch must be used within a ProcessProvider')
  }
  return context
}

export { ProcessProvider, useCreateComment, useProcessDispatch, useProcessState }

