import { Avatar, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'

import { numberWithCommas } from '../../components/Utils/NumberWithCommas'
import { formatTextToUrl } from '../../lib/formatTextToUrl'

export const ListProductsDetail = ({ product, data }) => {
  const useStyles = makeStyles((theme) => ({
    link: {
      color: 'black',
      textDecoration: 'none',
    },
    image_detail_order: {
      minWidth: '50px',
    },
    listItemTextWrap: {
      whiteSpace: 'nowrap',
      maxWidth: 600,
    },
    listItemText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    listItemWrap: {
      padding: '.5rem',
      [theme.breakpoints.down('sm')]: {
        overflow: 'scroll',
        '&::-webkit-scrollbar': {
          '-webkit-appearance': 'none',
        },
      },
    },
  }))
  const classes = useStyles()
  const valorIva = data.price * data.iva
  const precioConIva = data.price + valorIva
  const precioTotalUnidad = numberWithCommas(precioConIva * (1 - data.discount), 0)

  const quantityEl = !!product.quantity && <>( {product.quantity + (product.unit || '')} )</>

  return (
    // <Box className={classes.listItemWrap} >
    <Link
      to={`/app/product/${formatTextToUrl(product?.name) + '-' + formatTextToUrl(product?.taste)}/${product.objectID}`}
      className={classes.link}
    >
      <ListItem
        dense
        className={classes.listItemTextWrap}
      >
        <ListItemIcon>
          <Avatar src={product.thumbnail} />
        </ListItemIcon>
        <ListItemText className={classes.listItemText}>
          <Typography className={classes.listItemText}>
            {[product.brandName, product.name, product.taste].filter((a) => a).join(' - ')} {quantityEl}
            <br />${precioTotalUnidad} | {data.q} {data.q > 1 ? 'unidades' : 'unidad'}
          </Typography>
        </ListItemText>
      </ListItem>
    </Link>
    // </Box>
  )
}
