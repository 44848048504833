import { faBox, faBoxOpen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//info pack
import { Tooltip } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'

import { useCartContext, useCartDataSetContext } from '../../context/CartContext'
import { useMarketContext } from '../../context/MarketContext'
import { usePriceList } from '../../context/PriceListContext'
import { getProductDiscount } from './Price'

const useStyles = makeStyles((theme) => ({
    addCartBox: {
        textTransform: 'none',
        width: '40px',
        marginLeft: '10px',
        marginRight: 0,
        margin: '4px',
        height: 41,
        whiteSpace: 'nowrap',
        fontWeight: '800',
        backgroundColor: 'black',
        border: 'none',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#3d3d3d'
        },
        [theme.breakpoints.down(480)]: {
            fontSize: '0.65rem',
            minWidth: '48px',
        }
    },
    qBoxes: {
        marginRight: 5
    }
}))

export default function ProductAggregationPack({ product }) {

    const classes = useStyles()
    const cart = useCartContext()
    const cartUpdate = useCartDataSetContext()
    const qInCart = cart[product.objectID]?.q
    const qBoxes = parseInt((qInCart || 0) / product.pack)
    const priceList = usePriceList()
    const marketContext = useMarketContext()
    const priceLists = marketContext.priceList || {}
    const brand = marketContext?.brands?.[product?.brand]
    const specs = marketContext?.specs

    const { discount } = getProductDiscount({
        product, priceLists,
        priceListID: priceList.objectID, brand, specs: product.specs?.map?.(s => specs?.[s]), q: 1
    })
    const { discount: discountPlus } = getProductDiscount({
        product,
        priceLists, priceListID: priceList.objectID, brand, specs: product.specs?.map?.(s => specs?.[s]), q: product.pack
    })

    //Info Pack

    const handleTooltipOpen = () => {

        cartUpdate({ product: product, q: qBoxes ? 0 : product.pack })
    }
    // const estimatedDiscount = product?.packDiscount?.[priceList?.objectID]

    const hoverMessage = qInCart >= product.pack
        ? <>Estas comprando por bulto cerrado<br />Toca para sacar el producto del carrito</>
        : discount !== discountPlus ? 'Comprar por bulto cerrado y obtené un ' + _.round((discountPlus - discount) * 100, 0) + '% de descuento adicional'
            : 'Compra por bulto cerrado'

    return (<>
        {!product.autoItem && product.objectID && !product.nostock &&
            // ((qInCart || 0) < product.pack) &&
            <CardActions style={{ padding: '0' }}
                disableSpacing
                onClick={e => e.preventDefault()}>
                <Tooltip
                    title={hoverMessage}
                    placement="bottom-start">
                    <Button onClick={handleTooltipOpen}
                        className={classes.addCartBox}
                        variant={'outlined'}>

                        {qBoxes ?
                            <><span className={classes.qBoxes}>{qBoxes}</span> <FontAwesomeIcon icon={faBox}
                                size={'lg'} /></>
                            :
                            <FontAwesomeIcon icon={faBoxOpen}
                                size={'lg'} />

                        }

                    </Button>
                </Tooltip>
            </CardActions>



        }
    </>
    )

}
