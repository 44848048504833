
import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
    'footer': {
        'width': '100%',
        position: 'absolute',
        background: '#fff',
        paddingTop: '10px',
        left: 0,
        bottom: 0,
    },
    'footerInCart': {
        'width': '100%',
        'position': 'absolute',
        '@media (max-height: 1500px) and (min-width: 750px)': {
            'bottom': '0px',
        },
        '@media (max-height: 950px) and (min-width: 750px)': {
            'bottom': '-120px',
        },
        '@media (max-height: 900px) and (min-width: 750px)': {
            'bottom': '-140px',
        },
        '@media (max-height: 850px) and (min-width: 750px)': {
            'bottom': '-160px',
        },
        '@media (max-height: 800px) and (min-width: 750px)': {
            'bottom': '-200px',
        },
        '@media (max-height: 750px) and (min-width: 750px)': {
            'bottom': '-230px',
        },
        '@media (max-height: 700px) and (min-width: 750px)': {
            'bottom': '-280px',
        },
        '@media (max-height: 650px) and (min-width: 750px)': {
            'bottom': '-320px',
        },
        '@media (max-height: 600px) and (min-width: 750px)': {
            'bottom': '-350px',
        },
        '@media (max-height: 550px) and (min-width: 750px)': {
            'bottom': '-400px',
        },
        '@media (max-height: 500px) and (min-width: 750px)': {
            'bottom': '-430px',
        },
        '@media (max-height: 450px) and (min-width: 750px)': {
            'bottom': '-480px',
        },
        '@media (max-width: 750px)': {
            'bottom': '0',
        },
    },
    'contenedor_footer': {
        'borderBottom': '1px solid #d6d6d6',
        maxWidth: 1400,
        margin: 'auto',
        width: '100%'
    },
    'logo_footer': {
        'margin': 'auto',
        'alignItems': 'center',
        '& img': {
            'transition': '0.5s all',
            '&:hover': {
                'transform': 'scale(1.060)'
            }
        }
    },
    'descripcion_footer': {
        'textAlign': 'center',
        'alignItems': 'center',
        'justifyContent': 'center',
        'margin': 'auto',
        'lineHeight': '20px',
        'cursor': 'default',
        '& h3': {
            'textTransform': 'uppercase',
            'fontWeight': '300',
            'color': '#666'
        },
        '& h2': {
            'color': '#555'

        }
    },
    'social': {
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'center',
        'margin': 'auto',
        '& a': {
            'textAlign': 'center',
            'margin': 'auto 0px',
            '&:hover': {
                'boxShadow': '6px 10px 10px #c9c9c9'

            }
        },
        '& svg': {
            'background': '#fff',
            'color': '#808080',
            'fontSize': '16px',
            // "padding": "17px 20px",
            'borderRadius': '50%',
            'boxShadow': '4px 5px 5px #c9c9c9',
            'marginRight': '6px'
        }
    },
    'text_social': {
        'textAlign': 'center',
        'marginLeft': '10px',
        'cursor': 'default',
        '& p': {
            'textAlign': 'left',
            'color': '#424242',
        }
    },
    'copy': {
        'textAlign': 'center',
        'marginTop': '5px',
        'bottom': '0px',
        '& a': {
            'color': '#049104',
            'fontSize': '14px'
        }
    },
    'copy_text': {
        'color': '#8b8b8b',
        'fontSize': '14px',
        'bottom': '0px'
    }
}))
