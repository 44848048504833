import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { StoreEdit } from '../../components/Store'

export default function Stores() {
  return (
    <Switch>
      <Route path={'/app/stores/:storeID'}
        render={StoreEditPage} />
    </Switch>
  )
}

function StoreEditPage({ match }) {
  const storeID = match?.params?.storeID

  return <StoreEdit storeID={storeID}
    showTitle={false} />
}
