import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
    'paymentOption_list': {
        // "height": "260px",
        // "overflowY": "scroll"
    },
    'paymentOption_list__paymentOption__selected': {
        'border': '2px solid #ffc244'
    },
    'paymentOption_list__paymentOption': {
        // "height": "80px",
        // "width": "70%",
        // "maxWidth": "360px",
        'border': '0px',
        'borderRadius': '10px',
        'margin': '0 auto 10px',
        'transition': '.3s',
        'cursor': 'pointer',
        'display': 'flex',
        'flexWrap': 'wrap',
        'boxShadow': '1px 1px 1px 1px rgba(0,0,0,0.2)',
        '&:hover': {
            'boxShadow': '0 2px 20px 0 rgba(0,0,0,.1)',
        }
    },
    'paymentOption_list__paymentOption__flag': {
        'display': 'flex',
        'width': '70px',
        'lineHeight': '80px',
        'height': '80px',
        'color': '#2abb9b',
        'fontSize': '24px',
        'justifyContent': 'start',
        'boxSizing': 'border-box',
        '&> img': {
            'marginTop': '13px',
            'marginLeft': '10px',
            'height': '50px',
            // "opacity": "0.6"
            maxWidth: '-webkit-stretch',
        }
    },
    'paymentOption_list__paymentOption__checkbox': {
        'display': 'flex',
        'width': '70px',
        'lineHeight': '80px',
        'height': '80px',
        'color': '#2abb9b',
        'fontSize': '24px'
    },
    'paymentOption_list__paymentOption__option': {
        'width': 'calc(100% - 70px)',
        'display': 'flex',
        'alignItems': 'center',
        'padding': '8px 8px 8px 0',
        'textAlign': 'left'
    },
    'paymentOption_list__paymentOption__option__details': {
        'color': '#9b9b9b',
        'margin': '5px 0'
    },
    'paymentOption_list__paymentOption__option__price': {
        'color': '#FF5014',
        'fontWeight': '600',
        'margin': '5px 0'
    },
    'paymentOption_list__paymentOption__option__title': {
        'fontWeight': '600',
        'fontSize': '1rem',
    },
    'paymentOption_list__paymentOption__option__text': {
        'overflow': 'hidden',
        'marginLeft': 8,

        // "textOverflow": "ellipsis",
        // "whiteSpace": "nowrap",
        'fontSize': '1rem'
    },
    'paymentOption_list__paymentOption__alert': {
        'display': 'block',
        'color': 'red',
        'fontSize': '1rem'
    },
    'paymentOption_list__add': {
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'center',
        'fontSize': '16px',
        'lineHeight': '1.33',
        'margin': '20px auto 20px',
        'cursor': 'pointer',
        'color': '#2abb9b',
        'fontWeight': '500',
        // "maxWidth": "80%",
        // "&:hover":{
        //     "opacity": "0.8"
        // }
    },
}))
