import React from 'react'

// styles
// import LoadingImage from '../../images/business.png'
import config from '../../config'
//components

export default function LoadingPage() {
  return (
    <div
      style={{
        width: '15rem',
        margin: 'auto',
        height: '15rem',
        top: 'calc(40% - 10rem)',
        left: 'calc(50% - 8rem)',
        position: 'absolute',
        background: 'url(' + config.logo + ') no-repeat center center',
        backgroundSize: 'contain',
        padding: '18rem 0 0 9rem',
      }}
    ></div>
  )
}
