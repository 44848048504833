import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({

    'root': {
        'width': '75%',
        'maxWidth': '390px',
        'margin': 'auto',
        // "WebkitBoxShadow": "0 11px 18px -4px #777",
        // "MozBoxShadow": "0 11px 18px -4px #777",
        // "boxShadow": "0 11px 18px -4px #777",
        'padding': '1rem',
        [theme.breakpoints.down('sm')]: {
            'width': '95%',
        }
    },
    'succesImageComponent': {
        'marginTop': '0.5rem',
        'width': '6rem',
        'margin': 'auto',
    },
    'orderConfirmedContainer': {
        'marginTop': '3rem',
        'textAlign': 'center',
    },
    'text': {
        'fontWeight': 'lighter',
        'color': '#8a8a8a',
        'font-weight': 'bold',
    }
}))
