import { Box, makeStyles } from '@material-ui/core'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'

import GetPrices from '../../components/Product/Price/Price'
import { useCartContext } from '../../context/CartContext'
import { usePriceList } from '../../context/PriceListContext'

const useStyles = makeStyles((theme) => ({
  packMessage: {
    position: 'relative',
    top: '5px',
    display: 'flex',
    alignItems: 'center',
    maxWidth: 300,
    margin: '0 auto',
    width: '100%',
    textAlign: 'center',
    boxShadow: '0 8px 16px 0 rgb(0 0 0 / 10%), 0 0 6px 0 rgb(0 0 0 / 10%)',
    fontSize: '.8rem',
    borderRadius: 5,
    padding: 8,
    zIndex: 50,
  },
  chevronMessage: {
    position: 'absolute',
    zIndex: 8,
    top: -8,
    backgroundColor: '#fff',
    left: 140,
    width: 15,
    borderBottom: '1px solid rgba(0,0,0,0.2)',
    borderRight: '1px solid rgba(0,0,0,0.2)',
    height: 15,
    transform: 'rotate(225deg)',
    [theme.breakpoints.down(601)]: {
      display: 'none',
    },
  },
}))

export const BuyPackMessage = ({ product }) => {
  const priceList = usePriceList()
  const classes = useStyles()
  const cart = useCartContext()
  const price = GetPrices({ product: product })
  return (
    <Box className={classes.packMessage}>
      <Box className={classes.chevronMessage}></Box>
      <ShoppingCartOutlinedIcon color="black" />
      {price.discount * 100 < priceList.packDiscount * 100 && priceList.packDiscount * 100 > 4 ? (
        (cart[product.objectID]?.q || 0) >= product.pack ? (
          <p>
            Estas comprando por pack de :<p style={{ display: 'inline', padding: '.2rem' }}>{product.pack}</p> unidades y obteniendo un descuento del
            : <p style={{ color: 'green', display: 'inline', padding: '.2rem', fontSize: '1rem' }}>{priceList.packDiscount * 100}%</p>
          </p>
        ) : (
          <p>
            Comprá por pack de :<p style={{ display: 'inline', padding: '.2rem' }}>{product.pack}</p> unidades y obtené un descuento del :{' '}
            <p style={{ color: 'green', display: 'inline', padding: '.2rem', fontSize: '1rem' }}>{priceList.packDiscount * 100}%</p>
          </p>
        )
      ) : (cart[product.objectID]?.q || 0) >= product.pack ? (
        priceList.packDiscount * 100 < 4 ? (
          <p>
            Estas comprando por pack de : <p style={{ display: 'inline', padding: '.2rem' }}>{product.pack}</p> unidades
          </p>
        ) : (
          <p>
            Estas comprando por pack de : <p style={{ display: 'inline', padding: '.2rem' }}>{product.pack}</p> unidades y obteniendo un descuento
          </p>
        )
      ) : (
        <p>
          Podes Comprar por pack de : <p style={{ display: 'inline', padding: '.2rem' }}>{product.pack}</p> unidades
        </p>
      )}
    </Box>
  )
}
