/* eslint-disable react/no-unescaped-entities */
import { faMobile, faSms } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { AsYouType } from 'libphonenumber-js'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { useMarketContext } from '../../context/MarketContext'
// context
import {
  loginUser,
  useUserData,
  useUserDataSet,
} from '../../context/UserContext'
import whatsappLoading from '../../images/whatsapp-loading.gif'
import whatsappLogo from '../../images/whatsapp-logo.svg'
import { countries } from './countryJson'
import { validatePhoneNumber } from './phoneNumber'
// styles
import useStyles from './styles'

export default function Login(props) {
  const classes = useStyles()

  // global
  const userDataSet = useUserDataSet()

  const { wcodeSent, phoneNumber } = useUserData()

  const { whatsappLogin } = useMarketContext()

  // local
  var [isLoading, setIsLoading] = useState(false)
  var [SMSCodeSet, setSMSCodeSet] = useState(false)
  var [error, setError] = useState(false)
  // var [errorSms, setErrorSms] = useState(false);
  // const [flag, setFlag] = useState(false)
  const [loginValue, setLoginValue] = useState(
    String(phoneNumber?.replace('+', '').replace('549', '') || '')
  )
  const [SMSCode, setSMSCode] = useState('')
  const [changeAreaCode, setChangeAreaCode] = useState(false)
  const [areaCode, setAreaCode] = useState('AR')
  const [areaPhone, setAreaPhone] = useState('+54')
  const [loginType, setLoginType] = useState('')

  const handleLoginValue = (e) => {
    let newValue = new AsYouType(areaCode.length ? areaCode : 'AR').input(
      e.target.value
    )
    setLoginValue(newValue)
  }

  const handleLogin = (type) => {
    loginUser({
      areaPhone: areaPhone,
      phoneNumber: loginValue,
      history: props.history,
      setIsLoading,
      setError,
      userDataSet,
      type,
      setSMSCodeSet,
    })
    setLoginType(type)
  }

  const handleValidateSms = () => {
    SMSCodeSet.confirm(SMSCode)
    // setFlag(true)
    // setErrorSms(false)
  }

  const modifyAreaCode = (areaPhone, areaCode) => {
    setAreaCode(areaCode)
    setAreaPhone('+' + areaPhone)
  }

  return (
    <div className={classes.formContainer}>
      <div className={classes.form}>
        <React.Fragment>
          {!error ? (
            <Typography
              variant="h3"
              color={'primary'}
              className={classes.greeting}
            >
              Bienvenido a Plant
            </Typography>
          ) : (
            <Typography
              variant="h3"
              color={'primary'}
              className={classes.greeting}
            >
              Sucedió un error, intentá otra vez
            </Typography>
          )}
          <InputLabel htmlFor="formatted-text-mask-input">
            <>
              Celu <FontAwesomeIcon icon={faMobile} />
            </>
          </InputLabel>
          <OutlinedInput
            id="phoneNumber"
            variant="outlined"
            classes={{
              // underline: classes.textFieldUnderline,
              input: classes.textField,
              notchedOutline: classes.notchedOutline,
              // adornedStart : classes.adornedStart,
            }}
            value={loginValue}
            onChange={handleLoginValue}
            // margin="normal"
            placeholder="(11) 0 000-1111"
            type="tel"
            label={
              <>
                Celu <FontAwesomeIcon icon={faMobile} />
              </>
            }
            notched={true}
            disabled={isLoading || !!SMSCodeSet}
            // disableUnderline
            color={'primary'}
            onFocus={() => setChangeAreaCode(false)}
            startAdornment={
              changeAreaCode ? (
                <span className={classes.adornedStartSelect}>
                  <Box style={{ display: 'flex', padding: 0 }}>
                    <Autocomplete
                      options={countries}
                      style={{ border: 'none', width: '170px' }}
                      clearOnBlur={true}
                      autoHighlight
                      closeIcon={false}
                      defaultValue={countries.find(
                        (option) => '+' + option.phone === areaPhone
                      )}
                      getOptionLabel={(option) =>
                        option.label + ' ' + option.phone
                      }
                      renderOption={(option) => (
                        <Box style={{ padding: 0, width: '100%' }}>
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt=""
                          />
                          {option.label} +{option.phone}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          style={{ border: 'none' }}
                          {...params}
                          label="Pais"
                          variant="standard"
                        />
                      )}
                      onChange={(e, v) =>
                        Object.entries(v || {}).length
                          ? modifyAreaCode(v?.phone, v.code)
                          : null
                      }
                    />
                  </Box>
                </span>
              ) : (
                <span
                  className={classes.adornedStart}
                  onClick={() => setChangeAreaCode(true)}
                >
                  <Typography variant="h6"
                    color="secondary">
                    {areaPhone}{' '}
                  </Typography>
                  <ArrowDropDownIcon />
                </span>
              )
            }
            fullWidth
            autoFocus
          />
          {SMSCodeSet && (
            <div className={classes.smsCodeContent}>
              <InputLabel htmlFor="formatted-text-mask-input">
                <>
                  Codigo SMS <FontAwesomeIcon icon={faSms} />
                </>
              </InputLabel>
              <OutlinedInput
                onChange={(e) => setSMSCode(e.target.value)}
                // margin="normal"
                placeholder="000 000"
                value={SMSCode}
                type="number"
                label={
                  <>
                    Codigo SMS <FontAwesomeIcon icon={faSms} />
                  </>
                }
                notched={true}
                disabled={isLoading}
                // disableUnderline
                color={'primary'}
                fullWidth
                autoFocus
              // inputComponent={TextMaskCustomCode}
              />
            </div>
          )}
          <div className={classes.formButtons}>
            {isLoading ? (
              wcodeSent && loginType === 'whatsapp' ? (
                <div>
                  <div>
                    Te enviamos un mensaje, por favor respondé "Si" para
                    ingresar
                  </div>
                  <div style={{ marginLeft: 'auto', width: 'fit-content' }}>
                    Esperando respuesta{' '}
                    <img
                      alt={'whatsappLogo'}
                      src={whatsappLoading}
                      style={{ height: '25px', width: '25px' }}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <CircularProgress size={26}
                    className={classes.loginLoader} />
                  <span>
                    {loginType === 'sms' ? (
                      <>
                        Estamos enviando un sms a tu{' '}
                        <FontAwesomeIcon icon={faMobile} />
                      </>
                    ) : (
                      <>
                        Estamos enviando un mensaje a tu{' '}
                        <img
                          alt={'whatsappLogo'}
                          src={whatsappLogo}
                          style={{ height: '25px', width: '25px' }}
                        />
                      </>
                    )}
                  </span>
                </>
              )
            ) : (
              !SMSCodeSet && (
                <>
                  {!!whatsappLogin && (
                    <Button
                      style={{ margin: 'auto' }}
                      disabled={!validatePhoneNumber(areaPhone, loginValue)}
                      onClick={() => handleLogin('whatsapp')}
                      variant="contained"
                      color="primary"
                      size="medium"
                    >
                      Enviar Whatsapp
                    </Button>
                  )}
                  <Button
                    style={{ margin: 'auto' }}
                    disabled={!validatePhoneNumber(areaPhone, loginValue)}
                    onClick={() => handleLogin('sms')}
                    variant="contained"
                    color="primary"
                    size="medium"
                  >
                    Enviar SMS
                  </Button>
                </>
              )
            )}
            {SMSCodeSet && (
              <Button
                style={{ margin: 'auto' }}
                disabled={String(SMSCode).length !== 6}
                onClick={handleValidateSms}
                variant="contained"
                color="secondary"
                size="large"
              >
                Enviar Codigo
              </Button>
            )}
          </div>
        </React.Fragment>
      </div>
    </div>
  )
}

export const InputLogin = ({
  loginValue,
  setLoginValue,
  isLoading,
  areaPhone,
  modifyAreaCode,
  areaCode,
  SMSCodeSet,
  setChangeAreaCode,
  changeAreaCode,
}) => {
  const classes = useStyles()

  const handleLoginValue = (e) => {
    let newValue = new AsYouType(areaCode.length ? areaCode : 'AR').input(
      e.target.value
    )
    setLoginValue(newValue)
  }
  return (
    <OutlinedInput
      id="phoneNumber"
      variant="outlined"
      classes={{
        // underline: classes.textFieldUnderline,
        input: classes.textField,
        notchedOutline: classes.notchedOutline,
        // adornedStart : classes.adornedStart,
      }}
      value={loginValue}
      onChange={handleLoginValue}
      // margin="normal"
      placeholder="(11) 0 000-1111"
      type="tel"
      label={
        <>
          Celu <FontAwesomeIcon icon={faMobile} />
        </>
      }
      notched={true}
      disabled={isLoading || !!SMSCodeSet}
      // disableUnderline
      color={'secondary'}
      onFocus={() => setChangeAreaCode(false)}
      startAdornment={
        changeAreaCode ? (
          <span className={classes.adornedStartSelect}>
            <Box style={{ display: 'flex', padding: 0 }}>
              <Autocomplete
                options={countries}
                style={{ border: 'none', width: '170px' }}
                clearOnBlur={true}
                autoHighlight
                closeIcon={false}
                defaultValue={countries.find(
                  (option) => '+' + option.phone === areaPhone
                )}
                getOptionLabel={(option) => option.label + ' ' + option.phone}
                renderOption={(option) => (
                  <Box style={{ padding: 0, width: '100%' }}>
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.label} +{option.phone}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    style={{ border: 'none' }}
                    {...params}
                    label="Pais"
                    variant="standard"
                  />
                )}
                onChange={(e, v) =>
                  Object.entries(v || {}).length
                    ? modifyAreaCode(v?.phone, v.code)
                    : null
                }
              />
            </Box>
          </span>
        ) : (
          <span
            className={classes.adornedStart}
            onClick={() => setChangeAreaCode(true)}
          >
            <Typography variant="h6"
              color="secondary">
              {areaPhone}{' '}
            </Typography>
            <ArrowDropDownIcon />
          </span>
        )
      }
      fullWidth
      autoFocus
    />
  )
}

function TextMaskCustom() {
  return (
    // <MaskedInput
    //     {...other}
    //     ref={(ref) => {
    //         inputRef(ref ? ref.inputElement : null);
    //         console.log(ref);
    //     }}
    //     mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    //     placeholderChar={'\u2000'}
    //     guide={false}
    //     showMask
    // />
    <></>
  )
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

// export default withRouter(Login);
