import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({

    'root': {
        'width': '75%',
        'maxWidth': '390px',
        'margin': 'auto',
        'marginBottom': '70px',
        'WebkitBoxShadow': '0 11px 18px -4px #777',
        'MozBoxShadow': '0 11px 18px -4px #777',
        'boxShadow': '0 11px 18px -4px #777',
        'padding': '1rem',
        [theme.breakpoints.down('sm')]: {
            'width': '95%',
        }
    },
    'title': {
        'margin': '1rem 0',
    }
}))
