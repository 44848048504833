import useMediaQuery from '@material-ui/core/useMediaQuery'
import React from 'react'

import { StoreEdit } from '../../components/Store'
import { useChangeTitle } from '../../components/Utils/useChangeTitle'
import config from '../../config/config'
import ProfileNav from '../profile/ProfileNav'
import useStyles from './styles'

export default function NewStore() {
  const classes = useStyles()
  const less900px = useMediaQuery('(min-width:900px)')
  const less950px = useMediaQuery('(min-width:950px)')
  const less1024px = useMediaQuery('(min-width:1024px)')
  config?.plant_web_view === true
    ? useChangeTitle('Nueva Tienda - Plant')
    : useChangeTitle('Nueva Tienda - Plant business')

  return (
    <div
      style={{
        marginRight: less1024px ? '12px' : '2px',
      }}
      className={classes.root}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 4,
          width: '100%',
          marginRight: less1024px ? '12px' : '2px',
        }}
      >
        {less900px ? <ProfileNav /> : <></>}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            background: '#F4F4F4',
            width: less950px ? '90%' : '98%',
            marginLeft: less1024px ? 40 : 5,
            borderRadius: '32px',
          }}
        >
          <StoreEdit />
        </div>
      </div>
    </div>
  )
}
