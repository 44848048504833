import { Avatar, Box, Button, Divider, FormControl, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, TextField } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import { makeStyles } from '@material-ui/styles'
import { pickBy } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import Populate from '../../algolia/populate'
import { DriveFileDownloadButton } from '../../components/Drive/Modal'
import { numberWithCommas } from '../../components/Utils/NumberWithCommas'
import { useChangeTitle } from '../../components/Utils/useChangeTitle'
import config from '../../config/config'
import { useUserDataSet } from '../../context/UserContext'
import { db } from '../../firebase'
import { ListProductsDetail } from './ListProductsDetail'

const useStyles = makeStyles((theme) => ({
  titleDetailWrap: {
    backgroundColor: '#fff',
  },
  listBox: {
    padding: '1rem 2rem',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: '.5rem .8rem',
    },
    maxWidth: 700,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: ' -4px 9px 5px 1px rgba(227,220,220,0.78)',
    backgroundColor: '#fff',
    borderRadius: 4,
  },

  listAddress: {
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: ' -4px 9px 5px 1px rgba(227,220,220,0.78)',
    backgroundColor: '#fff',
    borderRadius: 4,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  listItemsInfoShipping: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  listItems: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 100,
    maxWidth: 700,
  },
  listItemsInfo: {
    textAlign: 'right',
  },
  titleDetail: {
    padding: '.5rem 1rem',
  },
  listItemButtonInfo: {
    border: '1px solid #ccc',
    marginLeft: '1rem',
    padding: '.2rem .5rem',
  },
  listItemButtonLink: {
    textDecoration: 'none',
  },
  buttonRepetirPedido: {
    margin: '.5rem',
  },
  commentsBox: {
    border: '1px solid #ccc',
    maxWidth: 500,
    padding: 5,
    margin: 10,
    borderRadius: 4,
  },
  listBoxComment: {
    padding: '1rem 2rem',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: '.5rem .8rem',
    },
    maxWidth: 700,
  },
  customerCommentTitle: {
    fontSize: '1.2rem',
    margin: 0,
    padding: 10,
    fontWeight: 'bold',
  },
  customerCommentsInput: {
    maxWidth: 400,
    padding: 10,
    margin: 20,
  },
  customerCommentsValue: {
    fontSize: '1.2rem',
    padding: 30,
  },
  customerCommentValueBox: {
    border: '1px solid #ccc',
  },
  formControllerComment: {
    padding: 20,
    width: '50%',
  },
  addNewComment: {
    margin: '0 auto 1rem auto',
  },
}))

export default function Order({ orderId }) {
  const classes = useStyles()
  const userUpdate = useUserDataSet()
  const [productsData, setProductsData] = useState({})
  const [order, setOrder] = useState({})

  const repetirPedido = () => {
    userUpdate(
      Object.entries(order.items || {})
        .filter(([, { q }]) => q)
        .reduce(
          (p, [id, data]) => ({
            ...p,
            ['cart.' + id]: { q: data.q, timestamp: new Date() },
          }),
          {},
        ),
      'update',
    )
  }

  useEffect(() => {
    let active = true
    let snap
    if (orderId)
      snap = db
        .collection('orders')
        .doc(orderId)
        .onSnapshot((doc) => {
          let data = doc.data()
          active && setOrder(data)
        })
    return () => {
      active = false
      snap && snap()
    }
  }, [orderId])
  useEffect(() => {
    let active = true
    if (order.items) {
      Object.keys(order.items) &&
        Populate({
          hits: [{ products: Object.keys(order.items) }],
          props: [{ index: 'products', field: 'products' }],
        }).then((h) => active && setProductsData(h[0].products))
    }
    return () => (active = false)
  }, [order])
  const descuento = order.discount || 0
  config?.plant_web_view === true ? useChangeTitle('Órden de Compra - Plant') : useChangeTitle('Órden de Compra - Plant business')

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-start"
        className={classes.titleDetailWrap}
      >
        <p className={classes.titleDetail}>Detalle de la compra</p>
      </Box>
      <Box className={classes.listBox}>
        <List className={classes.list}>
          <ListItem className={classes.listItems}>
            {!order.canceled ? (
              <ListItemText primary={new Date(order?.timestamp?.seconds * 1000).toLocaleString()} />
            ) : (
              <p style={{ color: 'red' }}>Orden cancelada</p>
            )}
            {order.invoiceDriveId && (
              <p>
                Descargar Factura <DriveFileDownloadButton driveFileId={order.invoiceDriveId} />
              </p>
            )}
            {order.paymentMethod === 'mp' && !order.canceled && (
              <>
                <ListItemText
                  className={classes.listItemsInfo}
                  style={{
                    color: order.status === 'payed' ? '#4caf50' : '#d32f2f',
                  }}
                >
                  {order.status === 'payed' ? 'pagado' : 'Pago pendiente'}
                </ListItemText>
                {order.status !== 'payed' && (
                  <a
                    className={classes.listItemButtonLink}
                    href={`https://www.mercadopago.com/mla/checkout/start?pref_id=${order.mercadoPagoId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button className={classes.listItemButtonInfo}>Pagar</Button>
                  </a>
                )}
              </>
            )}
          </ListItem>
          <Divider />
          <ListItem className={classes.listItems}>
            <ListItemText primary="Producto" />
            <ListItemText
              className={classes.listItemsInfo}
              primary={`$${numberWithCommas(order.subTotal, 0)}`}
            />
          </ListItem>
          <ListItem className={classes.listItems}>
            <ListItemText primary="Iva" />
            <ListItemText
              className={classes.listItemsInfo}
              primary={`$${numberWithCommas(order.iva, 0)}`}
            />
          </ListItem>
          <ListItem className={classes.listItems}>
            <ListItemText primary="Descuento" />
            <ListItemText
              className={classes.listItemsInfo}
              primary={`$${numberWithCommas(descuento, 0)}`}
            />
          </ListItem>
          <ListItem className={classes.listItems}>
            <ListItemText primary="Envio" />
            <ListItemText
              className={classes.listItemsInfo}
              primary={order.shipping_cost === 0 ? 'Gratis' : numberWithCommas(order.shipping_cost, 0)}
            />
          </ListItem>
          <Divider />
          <ListItem className={classes.listItems}>
            <ListItemText primary="Total" />
            <ListItemText
              className={classes.listItemsInfo}
              primary={`$${numberWithCommas(order.total, 0)}`}
            />
          </ListItem>
        </List>
      </Box>
      <Box className={classes.listBox}>
        <List className={classes.listAddress}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <LocalShippingIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={order?.data?.address?.formatted_address} />
          </ListItem>
          <ListItem style={{ gap: '50px' }}>
            {!order.canceled && (
              <ListItemText
                className={classes.listItemsInfoShipping}
                style={{
                  color: order.delivered
                    ? '#4caf50'
                    : order.shipmentState === 'pending'
                      ? '#d32f2f'
                      : order.shipmentState === 'assigned' && '#F59530',
                }}
                primary={
                  order.delivered
                    ? `Entregado : ${moment(order.delivered_timestamp * 1000).format('DD/MM/yyyy HH:mm')}`
                    : order.shipmentState === 'pending'
                      ? 'Envio pendiente'
                      : order.shipmentState === 'assigned' && order.shippingDate
                        ? `Envio confirmado: ${moment(order.shippingDate).format('DD/MM/yyyy')}`
                        : null
                }
              />
            )}
          </ListItem>
        </List>
      </Box>
      <CommentsBox
        orderID={orderId}
        classes={classes}
        order={order}
      />
      <Box className={classes.listBox}>
        <Box className={classes.list}>
          {Object.entries(pickBy(order.items, (v) => v.price !== 0) || {})
            .filter(([, data]) => data.q)
            .sort(
              ([idA], [idB]) =>
                (productsData[idA]?.brandRanking - productsData[idB]?.brandRanking) * 10000 +
                (productsData[idA]?.ranking - productsData[idB]?.ranking) * 100 +
                productsData[idA]?.name.localeCompare(productsData[idB]?.name),
            )
            .map(([id, data]) => (
              <ListProductsDetail
                product={productsData[id] || {}}
                data={data}
                key={id}
              />
            ))}
          <Box
            sx={{ marginY: 2 }}
            display="flex"
            justifyContent="center"
          >
            <Button
              variant="outlined"
              onClick={() => repetirPedido()}
            >
              Agregar de nuevo al carrito
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}
function CommentsBox({ classes, order, orderID }) {
  const [open, setOpen] = useState(false)

  const orderDoc = db.collection('orders').doc(orderID)

  const commentUpdate = (comment) => comment.length < 200 && orderDoc.update({ customerComments: comment })

  return (
    <Box className={classes.listBoxComment}>
      <Box className={classes.list}>
        <p className={classes.customerCommentTitle}>Comentarios</p>
        <Box
          display={'flex'}
          alignItems="center"
        >
          {open && (
            <>
              <FormControl className={classes.formControllerComment}>
                <CommentsField
                  value={order?.customerComments}
                  setValue={commentUpdate}
                  multiline
                  autoFocus
                />
              </FormControl>

              <Button
                color="secondary"
                onClick={() => setOpen(!open)}
              >
                Guardar Cambios
              </Button>
            </>
          )}
          {!open &&
            (order?.customerComments ? (
              <>
                <p className={classes.customerCommentsValue}>{order?.customerComments}</p>
                <ListItemIcon>
                  <Avatar>
                    <EditIcon onClick={() => setOpen(!open)} />
                  </Avatar>
                </ListItemIcon>
              </>
            ) : (
              <Button
                color="primary"
                variant="contained"
                className={classes.addNewComment}
                onClick={() => setOpen(!open)}
              >
                Agregar Comentario
              </Button>
            ))}
        </Box>
      </Box>
    </Box>
  )
}

function CommentsField({ value: V, setValue: S, ...props }) {
  const [value, setValue] = useState(V || '')

  useEffect(() => {
    setValue(V || '')
  }, [V])

  return (
    <TextField
      {...props}
      value={value}
      onChange={(e) => value !== e.target.value && setValue(e.target.value)}
      onBlur={(e) => S && S(e.target.value)}
    />
  )
}
