import { Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'
import CheckIcon from '@material-ui/icons/Check'
import LoyaltyIcon from '@material-ui/icons/Loyalty'
import React, { useState } from 'react'

import { numberWithCommas } from '../../../components/Utils/NumberWithCommas'
import { useCreateComment, useProcessDispatch } from '../../../context/ProcessContext'
import { useUserData } from '../../../context/UserContext'
import { db } from '../../../firebase'
import useStyles from './styles'

export const DiscountCodeButton = ({ order }) => {
    const [addCode, setAddCode] = useState(false)

    return <Box style={{ margin: '.2rem', justifyContent: 'center' }}
        display='flex'>
        {addCode ? <DiscountCodeBox order={order} /> : <Button
            variant={'contained'}
            style={{
                'background': '#ffc80a',
                'color': '#4A4A4A',
            }}
            onClick={() => setAddCode(true)}>¿Tienes un código promocional?</Button>}

    </Box>
}
export const CommentBox = () => {
    const { comment, setComment } = useCreateComment()
    const classes = useStyles()

    return (
        <Box className={classes.commentForm}>
            <Box style={{
                width: '70px',
                height: '80px',
                display: 'flex',
                fontSize: '24px',
                boxSizing: 'border-box',
                alignItems: 'center',
                lineHeight: '80px',
                justifyContent: 'center'
            }}>
                <ChatBubbleOutlineIcon fontSize="large" />
            </Box>
            <FormControl className={classes.formComment}>
                <InputLabel htmlFor="comments">Comentarios de la compra</InputLabel>
                <Input
                    id='comments'
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                />
            </FormControl>
        </Box>
    )
}

export const DiscountCodeBox = ({ order }) => {
    const [code, setCode] = useState('')
    const [result, setResult] = useState({})
    const processDispatch = useProcessDispatch()
    const { uid } = useUserData()
    const classes = useStyles()

    return <div className={classes.root}>
        <Box display={'flex'}
            alignItems='center'>
            <LoyaltyIcon fontSize="large"
                style={{ marginLeft: '12px', marginRight: '15px', marginTop: '10px' }} />
            <FormControl error={result.error}
                className={classes.form}>
                <InputLabel htmlFor="component-discountcode">Código de Descuento</InputLabel>
                <Input
                    id="component-discountcode"
                    value={code}
                    onChange={e => setCode(e.target.value)}
                    aria-describedby="component-discountcode-result-text"
                />

                {(result.name || result.response) && <FormHelperText
                    id="component-discountcode-result-text">{result.response || 'Código Válido'}</FormHelperText>}
            </FormControl>
            <Button type="submit"
                className={classes.iconButton}
                aria-label="checkDiscountCode"
                onClick={e => {
                    e.preventDefault()
                    checkDiscountCode(code, order, uid).then(r => {
                        // eslint-disable-next-line no-console
                        console.log(r)
                        setResult(r)
                        !r.error && processDispatch({ discountCode: r })
                    })
                }}>
                <CheckIcon />
            </Button>
        </Box>
        <p>*Los descuentos no son acumulables</p>
    </div>

}

const checkDiscountCode = async (code, order, uid) => {

    code = code.toUpperCase()

    const getDate = (date) => {
        let seconds = date && (date._seconds || date.seconds || date)
        return new Date(seconds * 1000)
    }

    const dc = await db.collection('discountCodes').where('codes', 'array-contains', code).limit(1).get().then(docs => {
        if (docs.size === 0) return false
        let dc = docs.docs[0].data()
        dc.id = docs.docs[0].id
        if (new Date() < getDate(dc.end_date) && new Date() > getDate(dc.start_date)) return dc
        else return false
    })
    if (dc)
        if (!dc.usedCodes || !dc.usedCodes.includes(code)) {
            if (dc.orderMin && dc.orderMin > order.subtotal) return {
                error: true,
                response: 'El código requiere un mínimo de $ ' + numberWithCommas(dc.orderMin, 0)
            }
            if (!dc.firstOrderOnly || await db.collection('orders').where('user', '==', uid).where('status', '==', 'payed').limit(1).get().then(docs => {
                return docs.size === 0
            })) {
                return {
                    name: dc.name,
                    discount: dc.discount,
                    code,
                    one_use: !!dc.one_use,
                    id: dc.id,
                    type: dc.discount_type || 'percentage',
                    response: 'Código Válido',
                    plus: !!dc.plus
                }
            } else return {
                error: true,
                response: 'Código solo válido para la primera compra'
            }
            // }
        } else {
            return {
                error: true,
                response: 'Código ya utilizado'
            }
        }

    else {
        return {
            error: true,
            response: 'Código Inválido'
        }
    }

}
