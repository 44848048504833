import Grid from '@material-ui/core/Grid'
import React, { useEffect, useState } from 'react'

import { useMarketContext } from '../../../context/MarketContext'
import { useProcessState } from '../../../context/ProcessContext'
import { db } from '../../../firebase'
import cart_check_success from '../../../images/cart_check_success.svg'
import mercadoPagoLogo from '../../../images/mercadopago-logo.png'
import useStyles from './styles'


export default function SuccessPage() {
    const classes = useStyles()
    const time = useCountDown(10)
    const { orderID, newOrder } = useProcessState()
    const [mercadoPagoId, setMercadoPagoId] = useState('')
    const marketContext = useMarketContext()

    const paymentMethodData = marketContext?.paymentOptions?.[newOrder?.paymentMethod]

    useEffect(() => {
        let active = true
        let orderSnap = () => ''
        if (orderID && paymentMethodData?.mercadopago) orderSnap = db.collection('orders').doc(orderID).onSnapshot(order => order.data().mercadoPagoId && active && setMercadoPagoId(order.data().mercadoPagoId))
        return () => {
            active = false
            orderSnap()
        }
    }
        , [orderID, paymentMethodData])

    useEffect(() => {
        time === 0 && mercadoPagoId &&
            (window.location.href = 'https://www.mercadopago.com/mla/checkout/start?pref_id=' + mercadoPagoId)
    }, [time, mercadoPagoId])

    return <div className={classes.root}>
        <Grid container
            justify={'center'}>
            <Grid item
                xs={12}>
                <div className={classes.orderConfirmedContainer}>
                    <span className={classes.text}>Gracias Por Tu compra</span>
                    <div className={classes.succesImageComponent}>
                        <img className={classes.succesImage}
                            src={cart_check_success}
                            alt={'Gracias por tu compra'} />
                    </div>
                    <h1>Pedido Confirmado</h1>
                    {paymentMethodData?.mercadopago && <span className={classes.text}>Se redirigirá a <img alt={'MercadoPago'}
                        src={mercadoPagoLogo}
                        height={'22px'} /> en {time}</span>}
                </div>
            </Grid>
        </Grid>
    </div>
}


function useCountDown(seconds) {
    const [time, setTime] = useState(seconds + 1)
    useEffect(() => {
        let active = true
        const reduceOne = () => setTime(t => {
            t > 1 && setTimeout(() => active && reduceOne(), 1000)
            return t - 1
        })
        reduceOne()
        return () => active = false
    }, [])

    return time
}
