import { Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { usePriceList } from '../../context/PriceListContext'

export const MainNotification = ({ headerMessage }) => {
  const priceList = usePriceList()
  const history = useHistory()
  const locOrders = history.location.pathname.split('/').pop()

  return (
    <>
      { (
        (headerMessage || priceList?.headerMessage) &&
        locOrders === 'homepage' &&
        (
          <Box
            style={{
              background: 'radial-gradient(circle, rgba(195,230,230,1) 50%, rgba(195,230,230,1) 96% )',
            }}
            padding={1}
            width="100%"
            position="relative"
            top="0"
            display={'flex'}
            alignItems="center"
            justifyContent={'center'}
          >
            <p
              style={{
                margin: 0,
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {headerMessage || priceList?.headerMessage}
            </p>
          </Box>
        )
      )}
    </>
  )
}
