import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, { useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { Link, useHistory } from 'react-router-dom'

import RelatedProducts from '../../../components/Product/RelatedProducts'
import imgurWebp from '../../../components/Utils/imgurWebp'
import { usePriceList } from '../../../context/PriceListContext'
import { useUserData } from '../../../context/UserContext'

export default function HomepageElement({ el, classes }) {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                WebkitJustifyContent: 'center',
                overflow: 'hidden',
                alignItems: 'center',
            }}
        >
            {el.type === 'img' && <ImageElement el={el}
                classes={classes} />}
            {el.type === 'carousel' && <CarouselEl el={el}
                classes={classes} />}
            {el.type === 'productsList' && <RelatedProducts {...el} />}
        </div>
    )
}

function ImageElement({ el, classes }) {
    const ImgEl = (
        <img
            src={imgurWebp(el.src, 2000)}
            alt={el.alt || 'img'}
            className={classes.media}
        />
    )
    if (el.link) return <Link to={el.link}>{ImgEl}</Link>
    return ImgEl
}

function CarouselEl({ el, classes }) {
    const history = useHistory()
    const min600 = useMediaQuery('(min-width:600px)')
    const { isAnonymous } = useUserData()
    const [ordersSize] = useState({ size: 0 })

    const priceList = usePriceList()

    const elementsFiltered = el.elements?.filter(
        (el) =>
            (!el.startTimeStamp || el.startTimeStamp < ((Date.now() / 1000) | 0)) &&
            (!el.endTimeStamp || el.endTimeStamp > ((Date.now() / 1000) | 0)) &&
            (!el.onlyNewUser || (ordersSize === 0 && !isAnonymous))
    )

    return (
        <Carousel
            renderArrowPrev={(onClickHandler, hasPrev) =>
                hasPrev && (
                    <button
                        type="button"
                        onClick={onClickHandler}
                        className={classes.arrowStyles}
                        style={{ left: 0 }}
                    >
                        {'<'}
                    </button>
                )
            }
            renderArrowNext={(onClickHandler, hasNext) =>
                hasNext && (
                    <button
                        type="button"
                        onClick={onClickHandler}
                        className={classes.arrowStyles}
                        style={{ right: 0 }}
                    >
                        {'>'}
                    </button>
                )
            }
            className={
                priceList?.headerMessage
                    ? classes.carousel
                    : classes.carouselWithoutMessage
            }
            interval={5000}
            showStatus={false}
            showThumbs={false}
            onClickItem={(item) =>
                elementsFiltered?.[item]?.link &&
                history.push(elementsFiltered?.[item].link)
            }
            emulateTouch={true}
            useKeyboardArrows={true}
            infiniteLoop={el.autoPlay ?? true}
            autoPlay={el.autoPlay ?? true}
        >
            {elementsFiltered?.map(
                (el, i) =>
                    min600 ? (
                        <div key={i}>
                            <img
                                alt={i}
                                src={imgurWebp(el.src, 2000)}
                                className={classes.carouselItemImage}
                            />
                        </div>
                    ) : (
                        <img alt={i}
                            key={i}
                            src={el.src} />
                    )
                // <HomepageElement key={i} {...{el,classes}}/>
            )}
        </Carousel>
    )
}
