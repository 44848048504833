import 'react-image-lightbox/style.css' // This only needs to be imported once in your app

import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox'

export default class Light extends Component {
  constructor(props) {
    super(props)

    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }

  render() {
    const { photoIndex, isOpen } = this.state

    return (
      <div style={{ zIndex: 100 }}>
        <div style={{ width: '100%' }}
          type="button"
          onClick={() => this.setState({ isOpen: true })}>
          <img style={{ width: '75vw' }}
            src={this.props.images} />
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={this.props.images}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + this.props.images.length - 1) % this.props.images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % this.props.images.length,
              })
            }
          />
        )}
      </div>
    )
  }
}