
var data = {}

async function waitFor(someFunction) {
    return new Promise(r => {
        let counter = 0

        function w() {
            if (counter++ > 10) r()
            else if (someFunction()) r()
            else setTimeout(w, 250)
        }

        w()
    })
}

let running = false

const getObjects = async (index, o) => {
    await new Promise((r) => {
        const check = () => (!running ? r() : setTimeout(check, 30))
        setTimeout(check, Math.floor(Math.random() * 50))
    })
    if (['priceList', 'brands', 'specs', 'shippings'].includes(index))
        // return marketConfigPromise.then(() => data[index])
        running = true
    if (!data[index]) data[index] = {}
    o = [...new Set(o)]
    let op = []
    let keys = Object.keys(data[index])
    await waitFor(() => Object.entries(data[index]).filter(([, d]) => d?.updating).length === 0)

    for (let i in o) {
        if (!keys.includes(o[i])) {
            // data[index][o[i]] = {updating: true};
            op.push(o[i])
        }
    }

    if (!op.length) {
        running = false
        return JSON.parse(JSON.stringify(data[index]))
    }
    return stockFetch({ indexName: index, ids: op }).then((a) => {
        data[index] = { ...data[index], ...a }
        running = false
        return JSON.parse(JSON.stringify(data[index]))
    })
}

function stockFetch({ indexName, ids }) {
    const myHeaders = new Headers()
    const BASE_URL = 'https://us-central1-vivet-web.cloudfunctions.net'

    myHeaders.append('Content-Type', 'application/json')
    return fetch(BASE_URL + '/getObjects', {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({ indexName, ids }),
        redirect: 'follow',
    }).then((response) => response.json())
}

export var gdata = data
export default getObjects
