import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  filterImage: {
    height: '30px',
    width: '30px',
    marginRight: '5px',
    'borderCollapse': 'separate',
    'borderRadius': '50%',
    'backgroundColor': 'white',
    'boxShadow': '0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 2px 9px 1px rgba(0, 0, 0, 0.12), 0 4px 2px -2px rgba(0, 0, 0, 0.2)'
  },
  filtersBar: {
    'width': '100vw',
    'zIndex': '98',
    'transition': 'margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    'top': '112px',
    'backgroundColor': 'white',
    'left': 'auto',
    'right': '0',
    'position': 'sticky',
    'height': '45px',
    'boxShadow': '0px 2px 4px -1px rgba(0,0,0,0.1), 0px 4px 5px 0px rgba(0,0,0,0.1), 0px 1px 10px 0px rgba(0,0,0,0.1)',
    'lineHeight': '40px',
    'textAlign': 'center',
    'paddingRight': '1rem',
    '@media (min-width:599px)': {
      'top': '0',
    }
  },

  filtersBarHide: {
    'display': 'none',
  },
  filterBarFloatRight: {
    'display': 'flex',
    'marginLeft': '10px',
    'justifyContent': 'flex-end'

  },
  priceListName: {
    'float': 'left',
    'marginLeft': '10px',
    'marginTop': '10px',
    'textAlign': 'left',
    '& p': {
      'whiteSpace': 'nowrap',
      'overflow': 'hidden',
      'textOverflow': 'ellipsis',
    }
  },
  storeNameContainer: {
    'float': 'left',
    'cursor': 'pointer',
    'marginLeft': '10px',
    'marginTop': '10px',
    'textAlign': 'left',
    '& p': {
      'whiteSpace': 'nowrap',
      'overflow': 'hidden',
      'textOverflow': 'ellipsis',
    }
  },
  storeNameTextBefore: {
    'fontSize': '0.5em',
    'whiteSpace': 'nowrap',
    'overflow': 'hidden',
    'textOverflow': 'ellipsis',
  },
  storeName: {
    'marginTop': '-4px',
    'whiteSpace': 'nowrap',
    'overflow': 'hidden',
    'textOverflow': 'ellipsis',
  },
  filters: {
    paddingBottom: '200px',
    'width': '80vw',
    'maxWidth': '40rem',
    'zIndex': '1',
    'transition': 'margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    'top': '0',
    'backgroundColor': 'white',
    'left': 'auto',
    'right': '0',
    'position': 'absolute',
    'boxShadow': '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    'height': '100vh',
    'overflow': 'scroll',
    '@media (min-width:600px)': {
      'top': '64px',
    },
    '@media (min-width:1024px)': {
      'maxWidth': '300px',
      'position': 'fixed',
      'zIndex': '20',
      'left': '0',
      'height': '100vh',
      'top': '0',
      'backgroundColor': '#fff',
      'boxShadow': 'none',
      'padding': '4rem 2rem',
      'overflow': 'scroll',
      '&::-webkit-scrollbar': {
        '-webkit-appearance': 'none',
      },
      '&::-webkit-scrollbar-thumb ': {
        'backgroundColor': '#686868',
        'borderRadius': '5px'
      },
      '&::-webkit-scrollbar:vertical ': {
        'width': '10px',
      }

    },

  },
  filtersBox: {
    position: 'sticky',
    top: 10,
    background: '#fff'
  },
  filtersBack: {
    'content': '\'\'',
    'position': 'absolute',
    'zIndex': '1',
    'height': '100vh',
    'width': '100vw',
    'top': '0',
    'left': '0',
    'cursor': 'pointer',
    'background': 'rgba(0, 0, 0, 0.5)',
    'WebkitTransition': 'opacity .4s, visibility .4s',
    'transition': 'opacity .4s, visibility .4s'
  },
  sliderTitle: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    padding: '2rem'
  },
  barTitle: {
    fontSize: '1.1rem',
    fontWeight: '500',
    'color': 'gray',
    'verticalAlign': 'middle',
    marginLeft: '0.5rem',
    display: 'unset',
  },
  filterOption: {
    cursor: 'pointer',
    padding: '0.2rem',
    userSelect: 'none',
    width: 'max-content',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  filterDiscount: {
    'color': '#fff',
    'padding': '6px',
    'fontWeight': '500',
    'borderRadius': '10px',
    'backgroundColor': '#4A4A4A',
    'marginLeft': '10px'
  },
  button: {
    margin: theme.spacing(1),
  },
  searcherBrands: {
    borderColor: 'red',
    padding: 0,
    borderRadius: 8
  },
  verMas: {
    cursor: 'pointer',
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
  mainAccordion: {
    position: 'sticky',
    top: 0,
    background: '#fff'
  }

}))
