import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({

    'title': {
        'margin': '1rem 0',
    },

    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    map: {
        width: '100%',
        height: 'auto',
    },
    googleMap: {
        width: '100%',
        paddingBottom: '100%',
    },
    inpMap: {
        width: '100%',
        marginBottom: '10px',
    },
    detailsField: {
        marginTop: '1rem',
    },
    confirmButton: {
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'center',
        'fontSize': '16px',
        'lineHeight': '1.33',
        'margin': '20px auto 20px',
        'cursor': 'pointer',
        'color': '#2abb9b',
        'fontWeight': '500',
        '& button': {
            margin: '0 1rem'
        }
        // "maxWidth": "80%",
        // "&:hover":{
        //     "opacity": "0.8"
        // }
    },

}))
