import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    root: {
        marginTop: 20,
        [theme.breakpoints.down('md')]: {
            // margin: '1rem',
        },
    },
    media: {
        top: 0,
        left: 0,
        width: '100%',
        backgroundSize: 'contain',
        height: 0,
        paddingTop: '100%',
    },

}))
