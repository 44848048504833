import './index.css'

import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import React from 'react'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'

import App from './components/App'
import { CartProvider } from './context/CartContext'
import { LayoutProvider } from './context/LayoutContext'
import { MarketProvider } from './context/MarketContext'
import { PriceListProvider } from './context/PriceListContext'
import { ProductsProvider } from './context/ProductsContext'
import { UserProvider } from './context/UserContext'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import Themes from './themes'

ReactDOM.render(
    <LayoutProvider>
        <HelmetProvider>
            <div id="recaptcha-container" />
            <ThemeProvider theme={Themes.default}>
                <CssBaseline />
                <UserProvider>
                    <MarketProvider>
                        <PriceListProvider>
                            <CartProvider>
                                <ProductsProvider>
                                    <App />
                                </ProductsProvider>
                            </CartProvider>
                        </PriceListProvider>
                    </MarketProvider>
                </UserProvider>
            </ThemeProvider>
        </HelmetProvider>
    </LayoutProvider>,
    document.getElementById('root'),
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({ onUpdate: () => window.location.reload() })

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
