import { Avatar, Box, Button } from '@material-ui/core'
import FolderIcon from '@material-ui/icons/Folder'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'

export const MessageFeedbackCart = ({ cartForLater }) => {
  const history = useHistory()
  const classes = useStyles()

  return (
    <Box className={classes.mainBox}>
      {!cartForLater ? (
        <>
          <Avatar className={classes.avatar}>
            <ShoppingCartIcon style={{ fontSize: 40 }} />
          </Avatar>
          <p>El carrito esta vacío</p>
          <Button
            style={{ background: 'black', color: '#fff' }}
            onClick={() => history.push('/app/products')}
          >
            Ver Productos
          </Button>
        </>
      ) : (
        <>
          <Avatar className={classes.avatar}>
            <FolderIcon style={{ fontSize: 40 }} />
          </Avatar>
          <p>Aquí puedes guardar tus productos para comprar más tarde</p>
        </>
      )}
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  mainBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    margin: '0 auto',
  },
  avatar: {
    height: 90,
    width: 90,
  },
}))
