import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  root: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  flexSidebar: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 4,
    marginTop: '20px',
    marginRight: '20px',
  },
  form: {
    borderRadius: '32px',

    backgroundColor: 'white',
    padding: 15,
    '& > *': {
      margin: theme.spacing(1),
      marginTop: '-5px',
    },
  },
  field: {
    width: '90%',
  },
  subtitle: {
    marginTop: '10px',
  },
  profileNav: {
    backgroundColor: 'white',
    height: '90vh',
    borderRadius: '0 39px 39px 0',
    padding: '8px 20px',
    transition: 'all ease-in-out 0.25s',
  },
  mainLinks: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 55,
    marginTop: 80,
  },
  link: {
    padding: '4px 6px',
    borderRadius: '10px',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: '#3d3d3d',
      color: '#fff',
    },
  },
  buttons: {
    width: '100%',
    backgroundColor: '#292c2b',
    color: 'white',
    height: '50px',
    cursor: 'pointer',
    borderColor: '#292c2b',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: '8px',
  },
}))
