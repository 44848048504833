import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Grow from '@material-ui/core/Grow'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'

const useStyles = makeStyles(() => ({
  scrollToTop: {
    fontSize: '1.7rem',
    position: 'fixed',
    bottom: 16,
    right: 16,
    zIndex: 1065,
    backgroundColor: '#fff',
    borderRadius: '50%',
    width: 50,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    cursor: 'pointer',
  },
}))

const ScrollToTop = () => {
  const classes = useStyles()
  const [showTopBtn, setShowTopBtn] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      window.scrollY > 400 ? setShowTopBtn(true) : setShowTopBtn(false)
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      transformOrigin: '0 0 0',
    })
  }

  return (
    <>
      {showTopBtn && (
        <div
          className={classes.scrollToTop}
          onClick={scrollToTop}
        >
          <Grow
            in={showTopBtn}
            style={{ transformOrigin: '0 0 0' }}
          >
            <FontAwesomeIcon icon={faAngleUp} />
          </Grow>
        </div>
      )}
    </>
  )
}

export default ScrollToTop
