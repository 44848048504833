import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import React, { createElement } from 'react'
import { useHistory } from 'react-router-dom'

import { HideSearchBar } from '../../../context/LayoutContext'
import { useMarketContext } from '../../../context/MarketContext'
import { useProcessDispatch } from '../../../context/ProcessContext'
import { useSelectedStore, useUserDataSet } from '../../../context/UserContext'
import { usePriceList } from '../shipoptionselect'
import PaymentOptionTemplate from './PaymentOptionTemplate'
import useStyles from './styles'

export default function PaymentOptionSelect() {

    const classes = useStyles()

    const processDispatch = useProcessDispatch()
    const priceList = usePriceList()
    const { store } = useSelectedStore()

    HideSearchBar()

    const history = useHistory()
    const marketContext = useMarketContext()

    const paymentOptions = store?.agreedPaymentMethod?.length ? store.agreedPaymentMethod : priceList.paymentOptions
    const PaymentOptions = paymentOptions?.map(p => marketContext?.paymentOptions?.[p] && ({ ...marketContext?.paymentOptions?.[p], objectID: p }))
        .filter(p => p)

    const paymentDispatch = (paymentMethod) => {
        processDispatch({ paymentMethod })
        history.push('/app/buy/cart')
    }
    const userDataSet = useUserDataSet()
    return <div className={classes.root}>
        <Button onClick={() => history.goBack()}><ArrowBackIcon /> Volver</Button>
        <div className={classes.title}>
            <Typography variant={'h2'}
                align={'center'}>Seleccionar método de Pago</Typography>
        </div>
        {PaymentOptions?.map(p => createElement(PaymentOptionTemplate, { key: p.objectID, paymentMethod: p, onClick: () => { paymentDispatch(p.objectID); userDataSet({ lastPaymentMethod: p.objectID }, 'update') } }))}
    </div>
}


