import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
//plantilla
// import p from './template';
import { useLocation, useParams } from 'react-router-dom'

import RelatedProducts from '../../components/Product/RelatedProducts'
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop'
import { useChangeTitle } from '../../components/Utils/useChangeTitle'
import config from '../../config/config'
import { HideHeader } from '../../context/LayoutContext'
import { useMarketContext } from '../../context/MarketContext'
import { db } from '../../firebase'
//components
import ProductDescription from './ProductDescription'
// styles
import useStyles from './styles'

// const specs = {
//     '065rjghpoIv8ZlCr0oKO':'frozen',
//     '46b6JA5y1h3MSD3e5mPc':'cold',
//     'bLIqQtROjcLH1IYlwoSe':'glutenFree',
//     '68XPjgNviKzRsHnqO8CU':'bestSeller',
//     'ptWZbdx4HozjBWMAa4Qt':'new'
// }

export default function Product() {
  const classes = useStyles()
  const [{ data, pdata }, setData] = useState({})
  const location = useLocation()
  const param = useParams()
  const market = useMarketContext()
  const objectID = param?.productId || ''

  HideHeader(false)

  config?.plant_web_view === true
    ? useChangeTitle(pdata?.name + ' - Plant')
    : useChangeTitle(pdata?.name + ' - Plant business')


  useEffect(() => {
    let active = true

    market.brands &&
      db
        .collection('products')
        .doc(objectID)
        .get()
        .then((d) => {
          const data = { ...d.data(), objectID: d.id }
          let pdata = Object.assign({}, data)
          pdata.brand = {
            ...(market.brands[data.brand] || {}),
            objectID: data.brand,
          }
          pdata.specs = data.specs?.map((s) => market.specs?.[s] || s) || []
          active && setData({ data, pdata })
        })
    return () => (active = false)
  }, [location, market])

  return (
    <>
      <Helmet>
        <meta property="og:title" content={pdata?.name} />
        <meta property="og:description" content={pdata?.description?.slice(0, 150)} />
        <meta property="og:image" content={pdata?.images?.[0] || pdata?.image || ''} />
        <link rel="canonical" href={`https://business.plant.com.ar/app/product/${objectID}`} />
      </Helmet>
      {!!data && (
        <div className={classes.root}>
          <ScrollToTop />
          <ProductDescription product={data}
            product_populate={pdata} />
          {pdata &&
            pdata.specs.map((s) => (
              <RelatedProducts
                key={s.objectID}
                facetFilters={[`specs:${s.objectID}`]}
                title={s.name}
                showMoreLink={`/app/products?specs=${s.objectID}`}
              />
            ))}
        </div>
      )}
    </>
  )
}
