import { Box, Button, Checkbox, FormControl, TextField } from '@material-ui/core'
import { useState } from 'react'

import { db } from '../../../firebase/database'

export const SelectNewCarrier = ({ updateStore, conservations, carriers }) => {
  const [addOther, setAddOther] = useState(false)
  let newCarrier = {}
  const carrierRef = db.collection('carriers').doc()
  let carriersToOrder = {}
  const [newCarrierName, setNewCarrierName] = useState('')
  const [newCarrierCategory, setNewCarrierCategory] = useState({ frio: false, seco: false, congelado: false })

  const addCategoriesToNewCarrier = (c, e) => {
    setNewCarrierCategory((newCarrierCategory) => ({ ...newCarrierCategory, [c]: e.target.checked }))
  }

  const addNewCarrier = () => {
    newCarrier = {
      name: newCarrierName,
      conservations: Object.entries(newCarrierCategory || {})
        .filter(([, bool]) => bool)
        .map(([c]) => c),
      algoliaUpdated: false,
      details: '',
      active: true,
    }

    let carriersName = Object.entries(carriers || {}).map(([, c]) => c.name)

    if (carriersName.every((x) => x.toLowerCase() !== newCarrier['name'].toLowerCase())) {
      Object.entries(newCarrierCategory || {})
        .filter(([, bool]) => bool)
        .forEach(([c]) => {
          carriersToOrder = { ...carriersToOrder, [c]: { carrierID: carrierRef.id, preferenceDay: '' } }
        })
      carrierRef.set(newCarrier)
    }
    updateStore(carriersToOrder)
    setAddOther(false)
  }

  return (
    <>
      {!addOther ? (
        <Button
          onClick={() => setAddOther(true)}
          color="primary"
          variant="outlined"
        >
          Agregar otro
        </Button>
      ) : (
        <Box
          display={'flex'}
          flexDirection="column"
        >
          <FormControl value={newCarrierName}>
            <TextField
              label="transportista"
              onChange={(e) => {
                setNewCarrierName(e.target.value)
              }}
            />
          </FormControl>
          {conservations
            .sort((a, b) => a.localeCompare(b))
            .map((c) => (
              <span key={c}>
                <Checkbox
                  onChange={(e) => {
                    addCategoriesToNewCarrier(c, e)
                  }}
                  color="primary"
                />
                <span>{c}</span>
              </span>
            ))}
          <Box style={{ margin: '0 auto' }}>
            <Button
              size="small"
              disabled={
                newCarrierName?.length < 3 ||
                !Object.entries(newCarrierCategory || {})
                  .filter(([, bool]) => bool)
                  .map(([c]) => c).length
              }
              variant="outlined"
              onClick={(e) => {
                e.preventDefault
                addNewCarrier()
              }}
              color="secondary"
            >
              Guardar
            </Button>
            <Button
              style={{ marginLeft: 3 }}
              size="small"
              variant="contained"
              onClick={(e) => {
                e.preventDefault
                setAddOther(false)
                setNewCarrierName('')
              }}
              color="primary"
            >
              Cancelar
            </Button>
          </Box>
        </Box>
      )}
    </>
  )
}
