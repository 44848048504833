import { Grid, makeStyles } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import { FixedSizeList as List } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'

import { algoliaIndex } from '../../algolia'
import config from '../../config'
import { useStores, useUserData } from '../../context/UserContext'

let dataHits = {}


const useStyles = makeStyles((theme) => ({
    listWrap: {
        display: 'flex',
        overflow: 'hidden',
        maxWidth: 900,
        margin: 'auto',
        position: 'relative',
        left: '150px',
        [theme.breakpoints.down('md')]: {
            maxWidth: 700,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 350
        },
        // responsive para que quede centrado la lista de productos.
        '@media (min-width:1520px)': {
            marginLeft: '22vw'
        },
        '@media (max-width:1520px)': {
            marginLeft: '30vw'
        },
        '@media (max-width:1400px)': {
            marginLeft: '28vw'
        },
        '@media (max-width:1280px)': {
            marginLeft: '27vw'
        },
        '@media (max-width:1100px)': {
            marginLeft: '32vw'
        },
        '@media (max-width:1023px)': {
            marginLeft: '15vw'
        },
        '@media (max-width:950px)': {
            marginLeft: '12vw'
        },
        '@media (max-width:900px)': {
            marginLeft: '10vw'
        },
        '@media (max-width:850px)': {
            marginLeft: '8vw'
        },
        '@media (max-width:800px)': {
            marginLeft: '6vw'
        },
        '@media (max-width:750px)': {
            marginLeft: '4vw'
        },
        '@media (max-width:700px)': {
            marginLeft: '0vw'
        },
        '@media (max-width:599px)': {
        },
        //Surface duo width 540
        '@media (max-width:600px)': {
            marginTop: '158px',
        },
    },
}))

export default function InfiniteProducts({ infinityRef, index, Item, facets, setFilters, LoadingItem, ...props }) {
    const [data, setData] = useState(props)
    const [itemCount, setItemCount] = useState(1000)
    const size = useWindowSize()
    const classes = useStyles()
    const gridSize = data.gridSize || 1
    const isItemLoaded = index => !!dataHits[index * gridSize]

    useEffect(() => () => {
        dataHits = {}
    }, [])

    useEffect(() => {
        dataHits = {}
        setItemCount(1000)
        setData(d => ({ ...d, q: props.q, facetFilters: props.facetFilters, filters: props.filters }))
    }, [props.q, props.facetFilters, props.filters])

    useEffect(() => {
        setData(props)
    }, [props.gridSize])

    const loadMoreItems = (startIndex, stopIndex) => {
        startIndex *= gridSize
        stopIndex *= gridSize
        stopIndex += (gridSize - 1)

        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async resolve => {

            const algoliaFetch = async () => algoliaIndex(index).search(data.q, {
                maxValuesPerFacet: 200,
                facetFilters: data.facetFilters,
                filters: data.filters,
                offset: startIndex,
                facets: facets,
                length: ((stopIndex + 1) - startIndex)
            })

            const mongoFetch = async () => fetch('https://us-central1-vivet-web.cloudfunctions.net/search',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        query: data.q,
                        facets: facets,
                        filters: data.filters,
                        facetFilters: data.facetFilters,
                        offset: startIndex,
                        length: ((stopIndex + 1) - startIndex),
                    })
                }).then(r => r.ok && r.json())

            const response = data.q ? await algoliaFetch() : await mongoFetch()

            setFilters && setFilters(response.facets)
            itemCount !== response.nbHits && (setItemCount(response.nbHits))
            for (let i = startIndex; i <= stopIndex; i++) {
                dataHits[i] = response.hits[i - startIndex]
            }
            resolve()
        }
        )
    }

    function Row({ index, style }) {
        let gridMap = []
        for (let i = 0; i < gridSize; i++) gridMap[i] = i
        let hits = []
        for (let i = 0; i < gridSize; i++) hits.push(dataHits[index * gridSize + i])

        return (
            <Grid style={style}
                className={classes.listWrap}>
                {/*{el}*/}
                <Grid container >
                    {(hits || Array(gridSize)).map((p, i) =>
                        <Grid xs={6}
                            sm={4}
                            md={4}
                            lg={4}
                            key={(p && p.objectID) || i}
                            style={{ width: `${100 / gridSize}%` }}>
                            {p ? <Item hit={p} /> : LoadingItem && !dataHits[index * gridSize] && <LoadingItem />}
                        </Grid>)}
                </Grid>
            </Grid>
        )
    }

    return (<>
        {
            Object.keys(props).reduce((a, v) => a && props[v] === data[v], true) &&
            <ListComponent gridSize={gridSize}
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
                Row={Row}
                // width={Math.min(size.width > 600 ? size.width - 48 : size.width, maxWidth || 2000)}
                width={window.innerWidth}
                height={size.height}
                infinityRef={infinityRef}
            />
        }</>)
}

const ListComponent = ({ infinityRef, isItemLoaded, loadMoreItems, itemCount, gridSize, Row, width, height }) => {
    const { isAnonymous } = useUserData()
    const stores = useStores()
    itemCount = ((itemCount / gridSize | 0) - (itemCount / gridSize)) ? (itemCount / gridSize | 0) + 1 : (itemCount / gridSize | 0)
    const showPrices = (!config.show_prices_only_validated_store || stores?.some?.(s => s.validated))

    return (<div ref={infinityRef}>{
        <Fragment>
            <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
                minimumBatchSize={10}
                threshold={14}
            >
                {({ onItemsRendered, ref }) => (
                    <List
                        className="List"
                        height={height}
                        itemCount={itemCount}
                        itemSize={Math.min(width >= 1280 ?
                            900 :
                            width < 600 ?
                                width +
                                (config.show_prices_only_validated_store && isAnonymous ? 130 : 330) : 700) / gridSize +
                            (width < 600 ? 15 : showPrices ? 190 : 80)}
                        onItemsRendered={onItemsRendered}
                        ref={ref}
                        width={width}
                        style={{ margin: 'auto' }}
                    >
                        {Row}
                    </List>

                )
                }
            </InfiniteLoader>
        </Fragment>}
    </div>
    )
}




// Hook
function useWindowSize() {
    const isClient = typeof window === 'object'

    function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            // height: isClient ? window.innerHeight - (window.innerWidth < 600 ? 112 + 65 : 64 + 45) : undefined
            height: isClient ? window.innerHeight - (window.innerWidth < 600 ? 0 //112 + 65
                : 64// + 45
            ) : undefined
        }
    }

    const [windowSize, setWindowSize] = useState(getSize)

    useEffect(() => {
        if (!isClient) {
            return false
        }

        function handleResize() {
            setWindowSize(getSize())
        }

        window.addEventListener('resize', handleResize)
        window.addEventListener('keyboardWillShow', handleResize)
        window.addEventListener('keyboardWillHide', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
            window.removeEventListener('keyboardWillShow', handleResize)
            window.removeEventListener('keyboardWillHide', handleResize)
        }
    }, []) // Empty array ensures that effect is only run on mount and unmount

    return windowSize
}
