import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    maxWidth: '100vw',
    height: 'stretch',
    backgroundColor: '#ebebeb',
  },
  content: {
    position: 'relative',
    flexGrow: 1,
    // paddingLeft: theme.spacing(3),
    // paddingRight: theme.spacing(3),
    // width: `calc(100vw - 240px)`,
    height: 'fit-content',
    // marginBottom: theme.spacing(3),
    minHeight: '100vh',
    '@media (max-width:600px)': {
      paddingLeft: 0,
      paddingRight: 0,
    }
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    // maxWidth: `1200px`,
    // margin: 'auto',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  fakeSearchBar: {
    '@media (max-width:600px)': {
      minHeight: 56,
    }
  },
  fakeFooter: {
    minHeight: '175px',
    [theme.breakpoints.down('xs')]: {
      minHeight: '400px',
    }
  },
}))
