import { Box } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import queryString from 'query-string'
import React, { useMemo, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import config from '../../config'
import { useMarketContext } from '../../context/MarketContext'
import { usePriceListIDContext } from '../../context/PriceListContext'
import { useStores } from '../../context/UserContext'
import { formatTextToUrl } from '../../lib/formatTextToUrl'
import imgurWebp from '../Utils/imgurWebp'
import { AddFavorite } from './AddFavorite'
import DetailPrice from './DetailPrice'
import ProductAggregation from './ProductAggregation'
import ProductAggregationPackInList from './ProductAggregationPackInList'

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 280,
        margin: '1em',
        marginTop: 15,
        zIndex: 50,
        position: 'relative',
        borderRadius: '16px',
        padding: '0',
        [theme.breakpoints.down('md')]: {
            margin: '0.3rem',
        },

    },
    cardMediaWrap: {
        position: 'relative',
    },

    link: {
        textDecoration: 'unset',
    },
    thumbnail: {
        backgroundSize: 'contain',
        height: 0,
        paddingTop: '100%',
    },
    media: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        backgroundSize: 'contain',
        height: 0,
        paddingTop: '100%',
    },
    mediaSlide: {
        backgroundSize: 'contain',
        paddingTop: '100%',
    },
    carousel: {
        marginTop: '-100%'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    cardContent: {
        height: 76,
        position: 'relative',
        padding: '5px',
    },
    title: {
        'fontWeight': '600',
        'fontSize': '0.9rem',
        'letterSpacing': '-0.05rem',
    },
    titleCard: {
        height: 85,
        overflow: 'hidden',
        '& p': {
            'whiteSpace': 'nowrap',
            'textOverflow': 'ellipsis',
            'overflow': 'hidden'
        }
    },
    brand: {
        'position': 'absolute',
        'top': '20px',
        'right': '20px',
        'height': '45px',
        'width': '45px',
        'borderCollapse': 'separate',
        'borderRadius': '50%',
        'backgroundColor': 'white',
        'boxShadow': '0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 2px 9px 1px rgba(0, 0, 0, 0.12), 0 4px 2px -2px rgba(0, 0, 0, 0.2)'
    },
    specs: {
        'position': 'absolute',
        'top': '-30px',
        'left': '20px',
    },
    specImg: {
        'height': '20px',
        'width': '20px',
        'borderCollapse': 'separate',
        'borderRadius': '50%',
        'backgroundColor': 'white',
        'boxShadow': '0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 2px 9px 1px rgba(0, 0, 0, 0.12), 0 4px 2px -2px rgba(0, 0, 0, 0.2)',
        'position': 'relative',
        'top': '56px',
        'left': '20px',
        'marginRight': '2px',
    },
    nostockNotification: {
        'display': 'block',
        'backgroundColor': '#fcf8e3',
        'color': '#8a6d3b',
        'borderColor': '#faebcc',
        'padding': '8px',
        'border': '1px solid transparent',
        'borderRadius': '15px',
        'textAlign': 'center',
    },
    productButtons: {
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'center',
    },
    loadingPrice: {
        color: theme.palette.primary.main,
        'fontWeight': '600',
        'fontSize': '1.3rem',
        'fontFamily': '"Helvetica Neue Lt Std", sans-serif',
        'lineHeight': '1.235',
        'display': 'flex',
        'width': 'fit-content',
        'margin': 'auto',
    },
    productStock: {
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
        'marginTop': '0',
        'fontSize': '0.75rem',
        'color': theme.palette.error.main,
        'textAlign': 'center',
    },
    arrowStyles: {
        position: 'absolute',
        zIndex: 2,
        top: 0,
        width: 25,
        height: '100%',
        cursor: 'pointer',
        borderStyle: 'none',
        backgroundColor: 'transparent',
        color: 'floralwhite',
        textShadow: '3px 0px black',
        fontSize: '20px',
        transition: '0.3s',
        '&:hover': {
            transition: '0.5s',
            backgroundColor: '#00000040',
        },
    }
}))

const loadingText = '▒▒▒▒▒▒▒▒▒▒'
const loadingImage = 'https://firebasestorage.googleapis.com/v0/b/vivet-web.appspot.com/o/loading.gif?alt=media&token=22571851-e643-4ece-89ac-b1a540fadaf8'

export default function ProductCard({ product, showAdd = true }) {

    const priceListID = usePriceListIDContext()
    const classes = useStyles()
    const market = useMarketContext()
    const [, setDisplay] = useState(0)
    const [showAlertMaxStock, setShowAlertMaxStock] = useState(false)
    const locationQuery = useLocation()
    const query = queryString.parse(locationQuery.search)?.q
    const stores = useStores()
    const showPrices = (!config.show_prices_only_validated_store || stores?.some?.(s => s.validated))

    const productContent = useMemo(() =>
        <div>
            {(product.thumbnail || !product.image) &&
                <div className={classes.cardMediaWrap}>
                    <CardMedia
                        className={classes.thumbnail}
                        image={product.image ? product.thumbnail : loadingImage}
                        title={product.name}
                    />
                </div>
            }

            {product.image && product.images && product.images.length > 1 ? <Carousel
                className={classes.carousel}
                emulateTouch={true}
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
                renderArrowPrev={(onClickHandler, hasPrev) =>
                    hasPrev && (
                        <button type="button"
                            onClick={e => {
                                e.stopPropagation()
                                e.preventDefault()
                                onClickHandler()
                            }}
                            className={classes.arrowStyles}
                            style={{ left: 0 }}>
                            {'<'}
                        </button>
                    )}
                renderArrowNext={(onClickHandler, hasNext) =>
                    hasNext && (
                        <button type="button"
                            onClick={e => {
                                e.stopPropagation()
                                e.preventDefault()
                                onClickHandler()
                            }}
                            className={classes.arrowStyles}
                            style={{ right: 0 }}>
                            {'>'}
                        </button>
                    )}
            >{product?.images?.map(slide =>
                <CardMedia
                    key={slide}
                    className={classes.mediaSlide}
                    image={imgurWebp(slide)}
                    title={product.name}
                />
            )}</Carousel>
                :
                <CardMedia
                    className={classes.media}
                    image={imgurWebp(product.image)}
                    title={product.name}
                />
            }
            {// mrh = mobile ready hero
                !product.mrh && market.brands?.[product.brand]?.image &&
                <img className={classes.brand}
                    src={imgurWebp(market.brands?.[product.brand]?.image || '')}
                    alt={market.brands?.[product.brand]?.name || ''}
                    title={market.brands?.[product.brand]?.name || ''} />}

            <CardContent className={classes.cardContent}>
                {product.specs && priceListID !== 'g' && <div className={classes.specs}>
                    {[...new Set(product.specs)].filter(spec => market.specs?.[spec]?.image && market.specs?.[spec]?.showOnProduct).map(spec =>
                        <img
                            key={spec}
                            className={classes.specImg}
                            src={imgurWebp(market.specs?.[spec]?.image)}
                            alt={market.specs?.[spec]?.name || ''}
                            title={market.specs?.[spec]?.name || ''}
                        />)}
                </div>}
                <div className={classes.titleCard}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography align='center'
                            className={classes.title}
                            fontWeight={500}
                            color="primary"
                            component="p">
                            <HighlightedText text={product.name?.toUpperCase() || loadingText}
                                highlight={query} />
                        </Typography>
                    </div>

                    <Typography align='center'
                        variant="subtitle2"
                        color="textSecondary"
                        component="p">
                        {product.quantity ? (product.quantity + product.unit) : ''}
                    </Typography>

                    <Typography align='center'
                        variant="subtitle2"
                        color="textSecondary"
                        component="p">
                        <HighlightedText text={product ? product.taste : loadingText}
                            highlight={query} />

                    </Typography>
                </div>
            </CardContent>

            {product.objectID && showPrices
                ? <DetailPrice size={true}
                    product={product} />
                : null

            }

        </div>
        , [product, classes, market])


    const handleCanAddToCart = (value) => {
        value ? setShowAlertMaxStock(false) : setShowAlertMaxStock(true)
    }

    return (
        <>
            {
                <Card className={classes.root}
                    onMouseEnter={() => setDisplay(1)}
                    onMouseLeave={() => setDisplay(0)}>
                    <AddFavorite product={product}
                        pointer='auto' />
                    {product.objectID ? <Link className={classes.link}
                        to={`/app/product/${formatTextToUrl(product.name) + '-' + formatTextToUrl(product.taste)}/${product.objectID}`}>
                        {productContent}
                    </Link> :
                        productContent
                    }
                    <Box style={{ position: 'relative' }}>
                        {showAlertMaxStock && <span
                            className={classes.productStock}>Sólo hay {product.available} u disponible{product.available > 1 && 's'}</span>}
                    </Box>
                    {showAdd && showPrices &&
                        <div className={product.pack > 1 ? classes.productButtons : ''}>
                            {product.pack > 1 && (!product.available || product.available >= product.pack) &&
                                <ProductAggregationPackInList product={product} />}
                            <ProductAggregation product={product}
                                canAddToCart={handleCanAddToCart} />
                        </div>
                    }

                </Card>
            }

        </>
    )
}


function HighlightedText({ text = '', highlight = '' }) {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
    return <span> {parts.map((part, i) =>
        <span key={i}
            style={part?.toLowerCase() === highlight?.toLowerCase() ? {
                fontWeight: 'bold',
                background: 'rgb(105, 240, 174, 40%)',
            } : {}}>
            {part}
        </span>)
    } </span>
}
