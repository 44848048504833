import classNames from 'classnames'
import React from 'react'

import payments from '../../../../images/payments.svg'
import useStyles from './styles'

export default function PaymentOptionTemplate({ paymentMethod: { commission = 0, image = '', name = '', description = '' } = {}, icon, title, details, onClick, alertMessage }) {

    const classes = useStyles()


    return <div onClick={onClick}
        className={classNames([classes.paymentOption_list__paymentOption,
        classes.paymentOption_list__paymentOption__selected])}>{/* paymentOption_list__paymentOption__selected">*/}
        <div className={classes.paymentOption_list__paymentOption__flag}>
            <img src={icon || image || payments}
                alt="Payment Method"
            />
        </div>
        <div className={classes.paymentOption_list__paymentOption__option}>
            <div className={classes.paymentOption_list__paymentOption__option__text}>
                {title || name || 'Agregar metodo de pago'}
            </div>
            <div className={classes.paymentOption_list__paymentOption__option__text}>
                {description}
                <span className={classes.paymentOption_list__paymentOption__alert}>
                    {alertMessage}
                </span>
            </div>
            <div className={classes.paymentOption_list__paymentOption__option__price}>
                {commission ? 'Comisión: ' + (commission * 100).toFixed(0) + '%' : ''}
            </div>
            <div className={classes.paymentOption_list__paymentOption__option__details}>
                {details}
            </div>
        </div>
    </div>
}
