import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import DomainIcon from '@material-ui/icons/Domain'
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined'
import TwoWheelerIcon from '@material-ui/icons/TwoWheeler'
import classNames from 'classnames'
import moment from 'moment'
import React, { createElement, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { numberWithCommas } from '../../../components/Utils/NumberWithCommas'
import { HideSearchBar } from '../../../context/LayoutContext'
import { useMarketContext } from '../../../context/MarketContext'
import { usePriceListIDContext } from '../../../context/PriceListContext'
import { useProcessDispatch, useProcessState } from '../../../context/ProcessContext'
import { checkContainsLocation } from '../address'
import GetTotals from '../cart/getTotals'
import useStyles from './styles'

const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

export function usePriceList() {
    const priceListID = usePriceListIDContext()
    const priceLists = useMarketContext().priceList || { [priceListID]: {} }
    return priceLists[priceListID]
}

export default function Shipoptionselect() {
    const classes = useStyles()
    const { address, storeID, store } = useProcessState()
    const [{ shippings, loading }, setShippings] = useState({ shippings: [], loading: true })
    const processDispatch = useProcessDispatch()
    const priceList = usePriceList()
    HideSearchBar()
    const {
        discounts, subtotal, iva
    } = GetTotals()
    const history = useHistory()
    const marketContext = useMarketContext()
    const paymentOptions = store?.agreedPaymentMethod?.length ? store.agreedPaymentMethod : priceList.paymentOptions

    !address && history.push('/app/buy/cart')

    const shippingDispatch = useCallback(
        (shipping, shipment, replace) => {
            if (paymentOptions.length > 1) {
                processDispatch({ shippingID: shipping?.objectID, shipmentID: shipment?.id })
                history[replace ? 'replace' : 'push']('/app/buy/payselect')
            } else {
                const paymentMethod = paymentOptions?.[0] || ''
                processDispatch({ shippingID: shipping?.objectID, shipmentID: shipment?.id, paymentMethod })
                history[replace ? 'replace' : 'push']('/app/buy/payselect')
            }
        },
        [history, paymentOptions, processDispatch],
    )


    useEffect(() => {
        let google = window.google
        if (google) {
            let point = new google.maps.LatLng(address?.geometry?.location?.lat, address?.geometry?.location?.lng)
            let shippings = checkContainsLocation(point, marketContext.shippings)
            if (shippings.length > 0) setShippings({ shippings, loading: false })
            else if (storeID) shippingDispatch(null, null, true)//history.push('/app/buy/confirmorder');
            else setShippings({ shippings: [], loading: false })
        }
    }, [address, marketContext.shippings, history, storeID, shippingDispatch])

    const available_shippings = shippings?.filter(s => s.active && s.next_shipments?.filter(ns => ns.deadline > new Date().getTime())?.length)

    useEffect(() => {
        available_shippings?.length === 1 && shippingDispatch(available_shippings[0],
            available_shippings[0].next_shipments?.reduce((a, c) => a && a.deadline > new Date().getTime() && a.deadline < c.deadline ? a : c), true)
    },
        [available_shippings, shippingDispatch])

    return <div className={classes.root}>
        <Button onClick={() => history.goBack()}><ArrowBackIcon /> Volver</Button>
        <div className={classes.title}><Typography variant={'h2'}
            align={'center'}>Seleccionar método de
            envío</Typography></div>
        {available_shippings?.map((s) => {
            let next_shipment = s.next_shipments?.reduce((a, c) => a && a.deadline > new Date().getTime() && a.deadline < c.deadline ? a : c)
            return ShipmentBox({
                shipping: s,
                classes,
                total: subtotal - discounts + (priceList.iva_included ? iva : 0),
                priceList,
                next_shipment,
                shippingDispatch
            })
        })
        }
        {!loading && !available_shippings?.length && 'No llegamos a tu zona :('}

    </div>
}

export function ShipmentBox({ shipping, classes, total, priceList, next_shipment, shippingDispatch }) {
    return createElement(ShippingTemplate, {
        key: shipping?.objectID,
        classes,
        icon: shipping?.icon,
        price: shippingPrice(shipping, total, priceList),
        purchase_min4free: purchase_min4free(shipping, priceList),
        alertMessage: !!shipping?.only_dry && 'Solo Productos Secos',
        title: next_shipment && (priceList.objectID === 'g' && next_shipment.date !== dateFormat(addDays(new Date(), 1)) ? 'Llega en menos de 48 hs hábiles' : (next_shipment.date === dateFormat(new Date()) ? 'Hoy, ' : next_shipment.date === dateFormat(addDays(new Date(), 1)) ? 'Mañana, ' : '') + days[moment(next_shipment.date).day()] + ' ' + moment(next_shipment.date).date() + ' de ' + months[moment(next_shipment.date).month()]),
        subtitle: '',//shipping?.title,
        details: '',//shipping?.details,
        iva_included: priceList.iva_included,
        onClick: () => shippingDispatch?.(shipping, next_shipment)
    })
}

export function shippingPrice(shipping, total, priceList) {
    return shipping?.free === 'always' ? 0 : shipping?.free === 'price_list_min' || !shipping ? total < (priceList.purchasemin) ? priceList.shipping_cost : 0 : shipping?.free === 'custom' && shipping?.min_free ? total < (shipping?.min_free /* * (priceList.iva_included?1.21:1) */) ? shipping?.shipping_cost : 0 : 0
}

export function purchase_min4free(shipping, priceList) {
    return Math.round( /*( priceList?.iva_included?1.21:1 ) * */(shipping?.free === 'price_list_min' || !shipping ? priceList.purchasemin : shipping?.free === 'custom' && shipping?.min_free ? shipping?.min_free : 0))
}

function pad(num, size) {
    var s = num + ''
    while (s.length < size) s = '0' + s
    return s
}

function dateFormat(date) {
    return '' + date.getFullYear() + '-' + pad(date.getMonth() + 1, 2) + '-' + pad(date.getDate(), 2)
}

function addDays(date, days) {
    var result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
}

// function getNextShippingDay(shipping) {
//     const date = new Date();
//     const hours = date.getHours();
//     let day = date.getDay() + (shipping.hoursBefore ? Math.floor((shipping.hoursBefore+hours)/24) :0);
//     day>6&&(day-=7);
//     const calcDay = (d)=>d<day?calcDay(Number(d)+7):d;
//     let nd = calcDay(shipping.day[0]);
//     if(shipping.day) for (let d of shipping.day) {nd=Math.min(nd,calcDay(d))}
//     // console.log( (new Date().getDay() - (nd)) )
//     var shipingDate = new Date();
//     shipingDate.setDate(shipingDate.getDate() + nd - shipingDate.getDay()+1);
//     // const sd = "" + shipingDate.getFullYear() + '-' + pad(shipingDate.getMonth()+1,2) + '-' + pad(shipingDate.getDate(),2)
//     return shipingDate;
//     // return nd;
// }


function ShippingTemplate({ classes, icon, title, subtitle, details, price, onClick, alertMessage, purchase_min4free, iva_included }) {

    return <div onClick={onClick}
        className={classNames([classes.shipping_list__shipping, classes.shipping_list__shipping__selected])}>
        <div className={classes.shipping_list__shipping__flag}>
            {icon === 'mororcycle' ? <TwoWheelerIcon fontSize="large" /> : icon === 'industry' ? <DomainIcon fontSize="large" /> : <LocalShippingOutlinedIcon fontSize="large" />}
        </div>
        <div className={classes.shipping_list__shipping__address}>
            <div className={classes.shipping_list__shipping__address__title}>
                {title}
            </div>
            <div className={classes.shipping_list__shipping__address__text}>
                {subtitle}
                <span className={classes.shipping_list__shipping__alert}>
                    {alertMessage}
                </span>
            </div>
            <div className={classes.shipping_list__shipping__address__price}>
                {price ? '$ ' + numberWithCommas(price, 0) : 'Gratis'} {!!price && !!purchase_min4free && '(Comprando $ ' + numberWithCommas(purchase_min4free, 0) + (!iva_included ? ' +IVA' : '') + ' obtenes envío Gratis)'}
            </div>
            <div className={classes.shipping_list__shipping__address__details}>
                {details}
            </div>
        </div>
    </div>
}
