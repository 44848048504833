import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import RoomIcon from '@material-ui/icons/Room'
import React, { memo, useState } from 'react'

import { useSelectedAddress, useUserData } from '../../context/UserContext'
import ModalUbicacion from './AddressSelectModal'


const SendTo = memo(function SendTo() {

    const { address: selectedAddress } = useSelectedAddress()
    const { ipData } = useUserData()
    const [openModal, setOpenModal] = useState(false)

    return <>{openModal && <ModalUbicacion handleClose={() => setOpenModal(false)} />}
        <Grid
            container
            direction="row"
            // justifyContent="center"
            onClick={() => setOpenModal(true)}
            alignItems="flex-end"
            noWrap
            style={{ width: 'auto', margin: 15, cursor: 'pointer', flexWrap: 'nowrap', maxWidth: '100%', overflow: 'hidden' }}
        >

            <Grid item>
                <RoomIcon />
            </Grid>
            <Grid
                container
                item
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
            >
                <Grid item
                    style={{ color: 'rgb(204, 204, 204)' }}>

                    <Typography noWrap
                        variant="body2">
                        Enviar a
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography noWrap>
                        {selectedAddress?.address_components?.find?.(({ types }) => types.includes?.('locality'))?.short_name || <>{ipData?.city} ({ipData?.postal})</>}
                    </Typography>
                </Grid>
            </Grid>
        </Grid></>
})

export default SendTo
