import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  root: {
    margin: 'auto',
    paddingTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    gap: 4,
  },
  form: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  field: {
    width: '-webkit-stretch',
  },
}))
