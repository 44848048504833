import { Typography } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import React from 'react'
import { Link } from 'react-router-dom'

import { titleCase } from '../../components/Categories/Categories'
import { useChangeTitle } from '../../components/Utils/useChangeTitle'
import config from '../../config/config'
import { useMarketContext } from '../../context/MarketContext'

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },

  center: {
    backgroundColor: theme.palette.background.paper,
    margin: 'auto',
    padding: 5,
    marginTop: 5,
    width: '100%',
    maxWidth: 300,
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
    },
  },

  icon: {
    width: 15,
    marginLeft: 'auto',
  },
}))

export default function Categories() {
  const classes = useStyles()
  const { specs } = useMarketContext()

  const alphabeticalOrder = Object.entries(specs || {})
    .filter(([, a]) => a.name)
    .sort((c, d) => c[1]?.name?.localeCompare(d?.[1].name))

  config?.plant_web_view === true
    ? useChangeTitle('Categorías - Plant')
    : useChangeTitle('Categorías - Plant business')
  return (
    <>
      <h2
        style={{
          fontSize: '21px',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '25px',
        }}
      >
        CATEGORÍAS
      </h2>
      <List className={classes.root}>
        {alphabeticalOrder.map(([id, x]) => (
          <ListItem
            key={id}
            className={classes.center}
            component={Link}
            to={`/app/products?specs=${id}`}
          >
            <Typography> {titleCase(x.name)} </Typography>
            <ArrowForwardIosIcon className={classes.icon} />
          </ListItem>
        ))}
      </List>
    </>
  )
}
