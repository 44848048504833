//Material
import { Grid } from '@material-ui/core'
import { Button } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import LinearProgress from '@material-ui/core/LinearProgress'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import config from '../../config/config'
//Context
import { useMarketContext } from '../../context/MarketContext'
import { useStores, useUserData } from '../../context/UserContext'
import ticketLogo from '../../images/carritoResumen.png'
import { useCartList } from '../../pages/orderProcess/cart/CartList'
import CartList from '../../pages/orderProcess/cart/CartList'
//Styles
import useStyles from '../../pages/orderProcess/confirm/styles'
//Components & utyls
import { purchase_min4free, shippingPrice } from '../../pages/orderProcess/shipoptionselect/Shipoptionselect'
import { usePriceList } from '../../pages/orderProcess/shipoptionselect/Shipoptionselect'
import { numberWithCommas } from '../Utils/NumberWithCommas'

export default function CartSummary() {
    const [shippingId] = useState(undefined)
    const classes = useStyles()
    const less1530px = useMediaQuery('(min-width:1520px)')

    const priceList = usePriceList()
    const [, cartTotals, cart] = useCartList()
    const marketContext = useMarketContext()
    const descuentosCarrito = cartTotals.discounts && priceList.iva_included ? cartTotals.discounts_plus_iva : cartTotals.discounts
    const totalPerDiscount = ((descuentosCarrito / cartTotals.totalProducts * 100).toFixed())
    const shipping = shippingId && marketContext.shippings?.[shippingId]
    const shipping_price = priceList &&
        cartTotals.subtotal &&
        shippingPrice(shipping, cartTotals.subtotal - cartTotals.discounts + (priceList.iva_included ? cartTotals.iva : 0), priceList)
    const purchase_min4free_value = priceList && purchase_min4free(shipping, priceList)
    const [CartListEl] = CartList({})
    const { isAnonymous, onlyDry } = useUserData()
    const stores = useStores()
    const isStoreValidated = stores?.some(a => a.validated)


    let history = useHistory()

    const RowItem = ({ row }) => <>
        {row[2] &&
            <Grid item
                xs={12}>
                <Divider className={classes.dividerCart} />
            </Grid>}
        <div style={{ display: 'flex' }}>
            <Grid item
                xs={7}>
                <div className={classes.leftGridItem}>{row[0]}</div>
            </Grid>
            <Grid item
                xs={5}>
                <div className={classes.rightGridItem}>{row[1]}</div>
            </Grid>
        </div>
    </>

    const newProgress = (cartTotals.subtotal_plus_iva - cartTotals.discounts_plus_iva) * 100 / purchase_min4free_value
    return (less1530px && (isStoreValidated || !config.stores) ?
        <Grid style={{ top: config.show_prices_only_validated_store && isAnonymous ? 75 : 118 }}
            className={classes.cart}>
            <Grid style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}
                className={classes.greyBackground} >
                <Grid style={{ display: 'flex', justifyContent: 'space-between' }}
                    item >
                    <h3 className={classes.title}>Resumen</h3>
                    <img style={{ width: '60px', height: '60px' }}
                        src={ticketLogo}></img>
                </Grid>
                {CartListEl}
                {[['Productos (' + Object.keys(cart).reduce((c, id) => c + cart[id].q, 0) + ')', '$ ' + numberWithCommas((priceList.iva_included ? cartTotals.subtotal_plus_iva : cartTotals.subtotal), 0)],
                [!totalPerDiscount < 3 && 'Descuento ', cartTotals.discounts && '-$ ' + numberWithCommas(priceList.iva_included ? cartTotals.discounts_plus_iva : cartTotals.discounts, 0)],
                ['Subtotal', (!priceList.iva_included || shipping_price) && <b>{'$ ' + numberWithCommas(cartTotals.subtotal - cartTotals.discounts + (!priceList.iva_included ? 0 : cartTotals.iva), 0)}</b>, true],
                ['IVA', !priceList.iva_included && '$ ' + numberWithCommas(cartTotals.iva)],
                [<> Envío {!!shipping_price && <span style={{ fontSize: 10 }}><br />
                    <Box sx={{ width: '100%' }}>(Comprando $ {numberWithCommas(purchase_min4free_value, 0)}{!priceList.iva_included && ' +IVA'} o más obtenes envío Gratis)<LinearProgress variant="determinate"
                        value={newProgress} /></Box></span>}</>, (onlyDry ? shipping_price ? 'Mercado pago' : 'Gratis' : shipping_price ? '$ ' + numberWithCommas(shipping_price, 0) : 'Gratis')],
                [newProgress !== 0 ? <Button variant={'contained'}
                    className={`${classes.payButton} btn-confirm`}
                    onClick={() => history.push('/app/buy/cart')}>Pagás  {'$' + numberWithCommas(cartTotals.totalProducts + shipping_price, 0)}</Button> :
                    <div className={classes.cartWithoutProducts}>¡No lo pienses más, agrega tus productos al carrito ahora!</div>, true],
                ].filter(r => r[1]).map((row, i) => <RowItem key={i}
                    row={row} />)}
            </Grid>
        </Grid> : <></>)
}
