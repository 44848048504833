import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'

import { CardMedia } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React from 'react'
import InnerImageZoom from 'react-inner-image-zoom'
import { Carousel } from 'react-responsive-carousel'

import imgurWebp from '../../components/Utils/imgurWebp'
import Light from './Light'
import ZoomLightMultiple from './zoomLightMultiple'

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        margin: 0,
        maxWidth: 600
        // marginLeft: "5rem",
        // marginTop: "",
    },
    imgSmall: {
        border: '1px solid #ddd',
        padding: '5px',
        width: '100px',
        height: '100px'
    },
    img: {
        maxHeight: '20rem',
        minHeight: '23rem',
        [theme.breakpoints.down('sm')]: {
            minHeight: '100vw'
        },
        backgroundSize: 'contain'
    },
    imgThumb: {
        maxHeight: '20rem',
        maxWidth: '100%',
        minHeight: '6rem',
        backgroundSize: 'contain'
    },
    statusFormat: {
        fontSize: '0.6rem',
        backgroundColor: '#f5f5f5',
        color: '#000',
        padding: '0.3rem',
        textShadow: '0 0 black',
        borderRadius: '0.5rem',
        marginTop: '0.5rem',
        [theme.breakpoints.up(600)]: {
            display: 'none'
        }
    },
}))

export default function ProductThumbnails({ product }) {
    const classes = useStyles()
    const media950 = useMediaQuery('(min-width:950px)')
    const mobile = useMediaQuery('(max-width:600px)')
    const props = {
        width: 400,
        hideHint: true,
        height: 420,
        src: `${imgurWebp(product.image)}`,
        zoomScale: 1.5,
        zoomSrc: `${imgurWebp(product.image)}`,
        imgAttributes: {
            alt: product?.name || '' + product?.taste || ''
        }
    }
    const loadingImage = 'https://firebasestorage.googleapis.com/v0/b/vivet-web.appspot.com/o/loading.gif?alt=media&token=22571851-e643-4ece-89ac-b1a540fadaf8'

    return (
        <div className={classes.root}>
            {product.images && product.images.length > 1 ?
                <Carousel
                    renderThumbs={() => (product.images || product.image).map((value, index) =>
                        <CardMedia
                            className={classes.imgThumb}
                            image={value ? value : loadingImage}
                            key={index}
                            title={product.name} />
                    )}
                    statusFormatter={(...a) => <span className={classes.statusFormat}>{a[0]} de {a[1]}</span>}
                    showThumbs={!mobile}
                    dynamicHeight={true}
                    showArrows={false}
                >
                    {(Object.entries(product.images)).map((_, index) =>
                        <ZoomLightMultiple key={index} images={product.images}
                            index={index} />
                    )}
                </Carousel>
                :
                product.image && (media950 ?
                    <InnerImageZoom {...props} />
                    : <Light images={imgurWebp(product.image)} />)
            }
        </div>
    )
}


