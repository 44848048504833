import { Box, Button, Typography } from '@material-ui/core'

import { useSelectedStore } from '../../../context/UserContext'
import { updateDoc } from '../../../firebase/database'

export const DaySelect = ({ active, conservation, carrierID, userWillChange, setUserWillChange }) => {
  const DAYS = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes']
  const { store } = useSelectedStore()

  const storeID = store.id
  const preferenceDay = store?.carriers?.[conservation]?.preferenceDay

  let conditionToShow = (active && !preferenceDay?.length) || userWillChange

  const handleAddDay = (day) => {
    updateDoc({
      indexName: 'stores',
      id: storeID,
      hit: {
        [`carriers.${conservation}`]: { preferenceDay: day, carrierID },
      },
    })
    setUserWillChange({})
  }

  return (
    <>
      {conditionToShow && (
        <Box style={{ marginTop: 30 }}>
          <Typography style={{ fontSize: '.8rem', textAlign: 'center' }}>Seleccioná el día de preferencia</Typography>
          <Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {DAYS.map((day) => (
              <Button
                key={day}
                size="small"
                style={{ margin: '.2rem .2rem' }}
                color={preferenceDay === day ? 'secondary' : 'primary'}
                variant="contained"
                onClick={() => handleAddDay(day)}
              >
                {day}
              </Button>
            ))}
          </Box>
        </Box>
      )}
    </>
  )
}
