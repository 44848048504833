import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import { makeStyles } from '@material-ui/styles'

import config from '../../config/config'
import { useStores, useUserData, useUserDataSet } from '../../context/UserContext'
import { FieldValue } from '../../firebase/database'

const useStyles = makeStyles((theme) => ({
  favorite: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    padding: '.2rem',
    maxHeight: 32,
    top: '20px',
    [theme.breakpoints.down(920)]: {
      top: '2px',
      marginLeft: '.7rem',
      marginRight: '.5rem',
    },
  },
}))

export const AddFavoriteDetail = ({ product, pointer }) => {
  const stores = useStores()

  const { isAnonymous, favorites } = useUserData()

  const showFavorites = !isAnonymous && (!config.show_prices_only_validated_store || stores?.some?.((s) => s.validated))

  const userDataSet = useUserDataSet()

  const isFavorite = !!favorites?.[product.objectID]
  const favoriteHandler = (productID) => {
    !isAnonymous && userDataSet({ ['favorites.' + productID]: isFavorite ? FieldValue.delete() : (new Date() / 1000) | 0 }, 'update')
  }
  const classes = useStyles()
  return (
    <>
      {showFavorites &&
        (isFavorite ? (
          <div
            className={classes.favorite}
            style={{ cursor: `${pointer}` }}
          >
            <FavoriteIcon
              style={{ color: '#FF5014' }}
              onClick={() => favoriteHandler(product.objectID)}
            />
          </div>
        ) : (
          <div
            className={classes.favorite}
            style={{ cursor: `${pointer}` }}
            onClick={() => favoriteHandler(product.objectID)}
          >
            <FavoriteBorderIcon style={{ color: '#FF5014' }} />
          </div>
        ))}
    </>
  )
}
