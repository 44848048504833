import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({

  root: {
    maxWidth: '500px',
    margin: 'auto',
    width: 'fit-content',
  },
}))
