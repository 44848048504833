import { createTheme } from '@material-ui/core'

import defaultTheme from './default'

// import 'typeface-montserrat';
// import 'typeface-poppins';


const overrides = {
  typography: {
    fontFamily: '"Helvetica Neue Lt Std", sans-serif',

    h1: {
      fontSize: '3rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.64rem',
      fontWeight: '600',
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: '500',
    },
    h5: {
      fontSize: '1.285rem',
    },
    h6: {
      fontSize: '1.142rem',
    },
  },
}

const defaultThemeWOverrrides = {
  default: createTheme({ ...defaultTheme, ...overrides }),
}

export default defaultThemeWOverrrides
