/* eslint-disable no-undef */
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import StoreMallDirectoryOutlinedIcon from '@material-ui/icons/StoreMallDirectoryOutlined'
import classNames from 'classnames'
import React, { createElement, useEffect, useState } from 'react'
import { useGoogleMapsApi } from 'react-hook-google-maps'
import {
    Route,
    Switch,
    useHistory, useLocation
} from 'react-router-dom'

import { HideSearchBar } from '../../../context/LayoutContext'
import { useProcessDispatch, useProcessState } from '../../../context/ProcessContext'
import { useUserData, useUserDataSet } from '../../../context/UserContext'
import { db } from '../../../firebase'
import { usePriceList } from '../shipoptionselect'
import NewAddress from './NewAddress'
import ConfirmNewAddress from './NewAddress/ConfirmNewAddress'
import useStyles from './styles'

export default function SelectAddress() {

    const classes = useStyles()
    const history = useHistory()
    let location = useLocation()
    const processDispatch = useProcessDispatch()
    const ProcessState = useProcessState()
    const priceList = usePriceList()
    const userDataSet = useUserDataSet()
    const [newAddressToConfirm, setNewAddressToConfirm] = useState()
    useMaps()

    const addressDispatch = async (address) => {
        address?.id && userDataSet({ selectedAddress: address?.id }, 'update')
        history.push('/app/buy/cart')
    }

    const storeDispatch = (store) => {
        if (store.address) {
            userDataSet({ selectedAddress: null, selectedStore: store.id }, 'update')
            history.push('/app/buy/cart')
        }
        else history.push('/app/stores/' + store.id)
    }

    ProcessState.newAddress && location.pathname === '/app/buy/addressselect' && (setTimeout(() => processDispatch({ newAddress: undefined, address: undefined, shippings: undefined }), 300))

    HideSearchBar()
    return (
        <>
            <div className={classes.root}>
                <Button onClick={() => history.goBack()}><ArrowBackIcon /> Volver</Button>
                <div className={classes.title}><Typography variant={'h2'}
                    align={'center'}>{ProcessState.newAddress ? 'Confirmar Dirección' : !priceList?.storeOnly ? 'Seleccionar una dirección de envío' : 'Seleccionar un Comercio'}</Typography></div>
                <Switch>
                    <Route path="/app/buy/addressselect/newaddress" >
                        {newAddressToConfirm ? createElement(ConfirmNewAddress, { addressDispatch, address: newAddressToConfirm }) :
                            <NewAddress setNewAddressValue={setNewAddressToConfirm}
                                Template={(props) => AddressesTemplate({ classes, ...props })} />}
                    </Route>
                    <Route exact
                        path="/app/buy/addressselect">
                        <div className={classes.location_list__add}>

                            {!priceList?.storeOnly ? <Button startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                                onClick={() => history.push('/app/buy/addressselect/newaddress')}
                                variant="contained"
                                color="primary">Introducir una nueva
                                dirección de envío.</Button>
                                :
                                <Button startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                                    onClick={() => history.push('/app/newStore')}
                                    variant="contained"
                                    color="secondary">Agregar nuevo Comercio.
                                </Button>
                            }
                        </div>
                    </Route>
                </Switch>

                {!ProcessState.newAddress &&
                    <div className={classes.location_list}>
                        <Stores Template={(props) => AddressesTemplate({ classes, onClick: () => { storeDispatch(props.hit) }, ...props })} />
                        {!priceList?.storeOnly && <Addresses
                            Template={(props) => AddressesTemplate({ classes, onClick: () => addressDispatch(props.hit), ...props })} />}
                    </div>}
                {/*<Process step={'shipping'}/>*/}

            </div>
        </>
    )
}

export function AddressesTemplate({ classes, collection, title, address, details, onClick }) {
    return <div onClick={onClick}
        className={classNames([classes.location_list__location, classes.location_list__location__selected])}>
        <div className={classes.location_list__location__flag}>
            {collection === 'addresses' ? <LocationOnIcon fontSize="large" /> : <StoreMallDirectoryOutlinedIcon fontSize="large" />}
        </div>
        <div className={classes.location_list__location__address}>
            <div className={classes.location_list__location__address__title}>
                {title}
            </div>
            <div className={classes.location_list__location__address__text}>
                {address || <span style={{ color: 'red' }}>Tocar para agregar dirección</span>}
            </div>
            <div className={classes.location_list__location__address__details}>
                {details}
            </div>
        </div>
    </div>
}


export function Addresses({ Template }) {
    const user = useUserData()
    const [addresses, setAddresses] = useState([])

    useEffect(() => {
        let active = true
        if (user.uid) db.collection('addresses').where('users', 'array-contains', user.uid).orderBy('last_used', 'desc').limit(5).get().then((addresses) => {
            let naddresses = []
            addresses.forEach(function (doc) {
                let address = doc.data()
                naddresses.push({ ...address, address: address ? (address.formatted_address) : '', title: '', id: doc.id, details: address.details })
            })
            active && setAddresses(naddresses)
        })
        return () => active = false
    }, [user.uid])

    return <>{addresses.map((a) => <Template key={a.id}
        collection={'addresses'}
        hit={a}
        title={a.title}
        address={a.address}
        details={a.details}
        id={a.id} />)}</>
}

function Stores({ Template }) {
    const user = useUserData()
    const [stores, setStores] = useState([])

    useEffect(() => {
        let active = true
        const storesSnapshot = !user.uid ? () => '' : db.collection('stores').where('users', 'array-contains', user.uid).where('validated', '==', true).limit(20).onSnapshot((stores) => {
            let nstores = []
            stores.forEach(function (doc) {
                let store = doc.data()
                !store.permanently_closed && nstores.push({ ...store, formatted_address: store.address?.formatted_address || '', title: store.storeName || '', id: doc.id, details: store.address?.details })
            })
            active && setStores(nstores)
        })
        return () => {
            active = false
            storesSnapshot()
        }
    }, [user.uid])

    return <>{stores.map((a) => <Template key={a.id}
        collection={'stores'}
        hit={a}
        title={a.title}
        address={a.formatted_address}
        details={a.details}
        id={a.id} />)}</>
}

function useMaps() {
    const processDispatch = useProcessDispatch()
    const google = useGoogleMapsApi(
        process.env.REACT_APP_MAPS_API + '&libraries=places,geometry,drawings'
    )
    useEffect(() => {
        google && processDispatch({ field: 'mapsLoaded', value: true })
    }, [google, processDispatch])
    // window.initAutocomplete = () => {
    //     processDispatch({field:'mapsLoaded',value:true});
    // };
    return google
}

export function checkContainsLocation(point, shippings) {
    const google = window.google
    //clean map
    let matchShippings = []
    for (let i in shippings) {
        let ship = shippings[i]
        if (ship.coordinates) {
            //  console.log(ship);

            const polygo = JSON.parse(ship.coordinates)[0].map(cord => ({ lat: cord[1], lng: cord[0] }))
            const bermudaTriangle = new google.maps.Polygon({ paths: polygo })
            const contains = google && google.maps.geometry.poly.containsLocation(point, bermudaTriangle)
            contains && matchShippings.push(ship)
            //  map.data.add({geometry: new google.maps.Data.Polygon([polygo])})
        }
    }
    return matchShippings
}
