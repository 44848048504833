import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import classNames from 'classnames'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { numberWithCommas } from '../Utils/NumberWithCommas'
import GetPrices from './Price/Price'

const useStyles = makeStyles((theme) => ({
    price: {
        'fontWeight': '600',
        color: theme.palette.primary.main,
        'fontSize': '2rem',
        'fontFamily': '"Helvetica Neue Lt Std", sans-serif',
        'lineHeight': '1.235',
        display: 'flex',
        'width': 'fit-content',
        'margin': 'auto',
        'whiteSpace': 'nowrap'
    },
    priceInProducts: {
        'fontWeight': '600',
        color: theme.palette.primary.main,
        'fontSize': '2rem',
        'fontFamily': '"Helvetica Neue Lt Std", sans-serif',
        'lineHeight': '1.235',
        display: 'flex',
        'marginLeft': '18px',
        'marginTop': '10px',
        'whiteSpace': 'nowrap'
    },
    price_flat_card: {
        paddingBottom: '60px',
        'fontWeight': '600',
        color: theme.palette.primary.main,
        'fontSize': '1.5rem',
        'fontFamily': '"Helvetica Neue Lt Std", sans-serif',
        'lineHeight': '1.235',
        display: 'flex',
        'width': 'fit-content',
        opacity: 0.8,
        'whiteSpace': 'nowrap'

    },
    priceStrikethrough: {
        'textDecoration': 'line-through',
        'marginLeft': '5px',
        'fontSize': '0.6em',
        'fontWeight': 'lighter',
        'color': '#8a8a8a',
        'lineHeight': '1.235',
        'whiteSpace': 'nowrap'
    },
    priceDiscount: {
        'marginLeft': '5px',
        'fontSize': '0.6em',
        'fontWeight': 'lighter',
        'color': '#8a8a8a',
        'lineHeight': '1.235',
    },
    priceStrikethroughDiscount: {
        'textDecoration': 'line-through',
        'whiteSpace': 'nowrap'
    },
    percentOFF: {
        'color': '#FF5014',
        'font-weight': 'bold',
    },
    priceSuggested: {
        'marginLeft': '5px',
        'fontSize': '0.6em',
        'fontWeight': 'lighter',
        'color': '#8a8a8a',
        'lineHeight': '0.9em',
        'textAlign': 'left',
        '& > span': {
            'fontSize': '0.6em',
            'lineHeight': '0.9em',
            'whiteSpace': 'nowrap',
        }
    },
}))

export default function DetailPrice({ size, product, flat_card = false, customDiscounts, ...props }) {
    const classes = useStyles()
    const prices = GetPrices({ product: product, customDiscounts })
    const loc = useLocation()
    let products = loc.pathname.includes('products') //si se encuentra en la seccion donde estan las tarjetas de todos los productos
    const min600 = useMediaQuery('(min-width:600px)')

    return <div {...props}
        style={{ fontSize: size && '1.3rem', position: !products && 'relative' }}
        className={min600 ? classes['price' + (flat_card ? '_flat_card' : '')] : classes['priceInProducts' + (flat_card ? '_flat_card' : '')]}>
        <span className={classNames('MuiTypography-colorPrimary')}>
            $ {numberWithCommas(prices.price * (1 - prices.discount), 0)}
        </span>
        {!!prices.original && !prices.suggested && !prices.discount &&
            <span className={classes.priceStrikethrough}>
                $ {numberWithCommas(prices.original, 0)}
            </span>}
        {!!prices.discount &&
            <span className={classes.priceDiscount}>
                <span className={classes.priceStrikethroughDiscount}>
                    $ {numberWithCommas(prices.price, 0)}
                </span>
                <br />
                {!(prices.discount * 100 < 4.9) && <span className={classes.percentOFF}>
                    {numberWithCommas(prices.discount * 100, 0)}% OFF
                </span>}
            </span>}
        {!!prices.suggested && !prices.discount && prices.suggested > (prices.price * (1 - prices.discount)) &&
            <span className={classes.priceSuggested}>
                <span>Sugerido al </span><br /><span>Público: </span>
                $ {numberWithCommas(prices.suggested, 0)}
            </span>}
    </div>
}
