import React, { useEffect } from 'react'

var LayoutStateContext = React.createContext()
var LayoutDispatchContext = React.createContext()

function layoutReducer(state, action) {
  switch (action.type) {
    case 'TOGGLE_SIDEBAR':
      return { ...state, isSidebarOpened: !state.isSidebarOpened }
    case 'SHOW_HEADER':
      return { ...state, showHeader: true }
    case 'HIDE_HEADER':
      return { ...state, showHeader: false }
    case 'SHOW_SearchBar':
      return { ...state, showSearchBar: true }
    case 'HIDE_SearchBar':
      return { ...state, showSearchBar: false }
    case 'SHOW_Footer':
      return { ...state, showFooter: true }
    case 'HIDE_Footer':
      return { ...state, showFooter: false }
    case 'SHOW_ButtonShowProducts':
      return { ...state, buttonShowProducts: true }
    case 'HIDE_ButtonShowProducts':
      return { ...state, buttonShowProducts: false }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function LayoutProvider({ children }) {
  var [state, dispatch] = React.useReducer(layoutReducer, {
    isSidebarOpened: true,
    showHeader: true,
    showFooter: true,
    showSearchBar: true,
    buttonShowProducts: true,
  })
  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>
        {children}
      </LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  )
}

function useLayoutState() {
  var context = React.useContext(LayoutStateContext)
  if (context === undefined) {
    throw new Error('useLayoutState must be used within a LayoutProvider')
  }
  return context
}

function useLayoutDispatch() {
  var context = React.useContext(LayoutDispatchContext)
  if (context === undefined) {
    throw new Error('useLayoutDispatch must be used within a LayoutProvider')
  }
  return context
}

export { HideButtonShowProducts, HideFooter, HideHeader, HideSearchBar, LayoutProvider, showHeader, toggleSidebar, useLayoutDispatch, useLayoutState }

// ###########################################################
function toggleSidebar(dispatch) {
  dispatch({
    type: 'TOGGLE_SIDEBAR',
  })
}

function showHeader(dispatch, show) {
  dispatch({
    type: (show ? 'SHOW' : 'HIDE') + '_HEADER',
  })
}


function HideHeader(state = true) {
  const layoutDispatch = useLayoutDispatch()

  useEffect(() => {
    showHeader(layoutDispatch, !state)
    return () => showHeader(layoutDispatch, true)
  }, [layoutDispatch, state])
}

function showSearchBar(dispatch, show) {
  dispatch({
    type: (show ? 'SHOW' : 'HIDE') + '_SearchBar',
  })
}


function HideSearchBar(state = true) {
  const layoutDispatch = useLayoutDispatch()

  useEffect(() => {
    showSearchBar(layoutDispatch, !state)
    return () => showSearchBar(layoutDispatch, true)
  }, [layoutDispatch, state])
}

function showFooter(dispatch, show) {
  dispatch({
    type: (show ? 'SHOW' : 'HIDE') + '_Footer',
  })
}


function HideFooter(state = true) {
  const layoutDispatch = useLayoutDispatch()

  useEffect(() => {
    showFooter(layoutDispatch, !state)
    return () => showFooter(layoutDispatch, true)
  }, [layoutDispatch, state])
}

function showButtonShowProducts(dispatch, show) {
  dispatch({
    type: (show ? 'SHOW' : 'HIDE') + '_ButtonShowProducts',
  })
}


function HideButtonShowProducts(state = true) {
  const layoutDispatch = useLayoutDispatch()

  useEffect(() => {
    showButtonShowProducts(layoutDispatch, !state)
    return () => showButtonShowProducts(layoutDispatch, true)
  }, [layoutDispatch, state])
}
