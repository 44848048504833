import DateFnsUtils from '@date-io/date-fns'
import { Box, Button, Tooltip } from '@material-ui/core'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/styles'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useUserData, useUserDataSet } from '../../../context/UserContext'
import { db } from '../../../firebase/database'

const useStyles = makeStyles(() => ({
  mainContainer: {
    maxWidth: 600,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
  },
  boxHorario: {
    textTransform: 'none',
    height: 41,
    marginTop: 5,
    marginBottom: 20,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '500',
    margin: '0 auto',
    backgroundColor: '#ccc',
    border: 'none',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#8b8b8b',
    },
  },
  boxContinuar: {
    textTransform: 'none',
    height: 41,
    marginTop: 5,
    marginBottom: 20,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '500',
    margin: '0 auto',
    backgroundColor: '#85b83f',
    border: 'none',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#628520',
    },
  },
  customWidth: {
    maxWidth: '150px',
  },
}))

export const PreferrenceTime = () => {
  const [selectedDate, setSelectedDate] = useState(new Date())
  const { uid, preferredTime } = useUserData()
  const [openClock, setOpenClock] = useState(false)
  const classes = useStyles()
  const userDataSet = useUserDataSet()
  const history = useHistory()

  const timeHandler = (time) => {
    db.collection('users')
      .doc(uid)
      .get()
      .then(() => {
        try {
          userDataSet({ ['preferredTime']: time }, 'update')
        } catch {
          userDataSet({ ['preferredTime']: time }, 'update')
        }
      })
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
    timeHandler(date.toLocaleTimeString())
    setOpenClock(!openClock)
  }
  const handleContinuar = () => {
    history.push('/app/buy/confirmorder')
  }

  return (
    <Box className={classes.mainContainer}>
      <div style={{ fontSize: '5rem' }}>
        <AccessTimeIcon fontSize={'inherit'} />
      </div>
      {!preferredTime && !openClock ? (
        <Tooltip
          title="puedes elegir un horario de preferencia para tus pedidos"
          classes={{ tooltip: classes.customWidth }}
        >
          <Button
            className={classes.boxHorario}
            onClick={() => setOpenClock(!openClock)}
          >
            Agregar horario
          </Button>
        </Tooltip>
      ) : (
        <>
          {openClock && (
            <Box
              display={'flex'}
              flexDirection="column"
              margin="0 auto"
              maxWidth={400}
              p={5}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label="Horario de preferencia"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
              </MuiPickersUtilsProvider>
              <Button onClick={() => setOpenClock(!openClock)}>Cancelar</Button>
            </Box>
          )}
          {!openClock && (
            <Box
              display={'flex'}
              justifyContent="center"
              width={'100%'}
              maxWidth={400}
            >
              <Button
                className={classes.boxHorario}
                onClick={() => setOpenClock(!openClock)}
              >
                Modificar horario
              </Button>
              <Button
                className={classes.boxContinuar}
                onClick={() => handleContinuar()}
              >
                Continuar
              </Button>
            </Box>
          )}
          {preferredTime && (
            <Box
              maxWidth={400}
              display="flex"
              justifyContent={'center'}
              margin="0 auto"
              fontSize="1.2rem"
              p={2}
              boxShadow="0px 1px 1px rgba(0,0,0,0.4)"
              borderTop="1px solid rgba(0,0,0,0.1)"
            >
              Tu horario de preferencia actual:{' '}
              <Box
                display={'flex'}
                alignItems="center"
              >
                <p style={{ margin: 1, marginRight: 5, fontSize: '1.5rem', fontWeight: 'bold', textAlign: 'center' }}> {preferredTime}</p>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}
