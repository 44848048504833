import { makeStyles } from '@material-ui/styles'

// const drawerWidth = 240;

export default makeStyles((theme) => ({
  root: {
    margin: 'auto',
    marginTop: '1rem',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    bottom: '5px',
    background: 'white',
    padding: 20,
    borderRadius: 5,
    gap: 10,
    justifyContent: 'flex-start',
  },

  fieldIcons: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 300,
    justifyContent: 'center',
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    margin: 'auto',
    display: 'grid',
  },

  containerAutocomplete: {
    display: 'flex',
    borderRadius: '10px',
    alignItems: 'center',
  },
  buttonAutocomplete: {
    fontSize: 10,
    height: '30px',
    borderRadius: '5px',
    background: 'black',
    color: 'white',
    border: '1px solid black',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    fontWeight: 600,
    cursor: 'pointer',
    transition: 'all ease-in-out 0.25s',
    '&:hover': {
      backgroundColor: '#000000c2',
      color: 'white',
    },
  },
  buttonEdit: {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: '#333',
    padding: 0,
  },
  buttonUbication: {
    background: 'transparent',
    cursor: 'pointer',
    border: 'none',
    fontWeight: 600,
    color: 'green',
    '&:hover': {
      textShadow: '5px 5px 5px #ccc',
    },
  },
  detailMap: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: 15,
    textAlign: 'center',
  },
  paragraph: {
    color: '#6E6E6E',
    margin: 0,
    maxWidth: 300,
    fontSize: '0.75rem',
    marginTop: '3px',
    fontfamily: 'poppins',
    fontWeight: 400,
    lineHeight: '1.66',
  },
  circularProgressText: {
    fontSize: '11px',
    color: 'green',
    marginLeft: '5px',
  },
  inlandProvince: {},
  purchasingResponsable: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
}))
