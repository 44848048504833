import './Products.css'

import { Grid, useScrollTrigger } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import classNames from 'classnames'
import React, { useEffect, useMemo, useRef, useState } from 'react'

// components
import CartSummary from '../../components/CartSummary/CartSummary'
// data
import InfiniteProducts from '../../components/InfiniteList/InfiniteList'
import AddParam from '../../components/Layout/LocationComponent'
import ProductCard from '../../components/Product/ProductCard'
import config from '../../config'
import { HideHeader } from '../../context/LayoutContext'
import { usePriceList, usePriceListIDContext } from '../../context/PriceListContext'
import { useProductsDispatch, useProductsState } from '../../context/ProductsContext'
import { useUserData } from '../../context/UserContext'
import Filters from './Filters'


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 280,
        margin: 'auto',
        marginTop: 15,
        position: 'relative',
    },
    thumbnail: {
        backgroundSize: 'contain',
        height: 0,
        paddingTop: '100%',
    },
    media: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        backgroundSize: 'contain',
        height: 0,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    cardContent: {
        height: 100,
    },
    title: {
        'fontWeight': '600',
        'fontSize': '0.9rem',
        'letterSpacing': '-0.05rem',
    },
    titleCard: {
        height: 85,
        overflow: 'hidden',
        '& p': {
            'whiteSpace': 'nowrap',
            'textOverflow': 'ellipsis',
            'overflow': 'hidden'
        }
    },
    price: {
        'fontWeight': '600',
        color: theme.palette.primary.main,
        'fontSize': '1.5rem',
        'fontFamily': '"Helvetica Neue Lt Std", sans-serif',
        'lineHeight': '1.235',
        display: 'flex',
        'width': 'fit-content',
        'margin': 'auto',
    },
    priceStrikethrough: {
        'textDecoration': 'line-through',
        'marginLeft': '5px',
        'fontSize': '0.6em',
        'fontWeight': 'lighter',
        'color': '#8a8a8a',
        'lineHeight': '1.235',
    },
    priceSuggested: {
        'marginLeft': '5px',
        'fontSize': '0.6em',
        'fontWeight': 'lighter',
        'color': '#8a8a8a',
        'lineHeight': '0.9em',
        'textAlign': 'left',
        '& > span': {
            'fontSize': '0.6em',
            'lineHeight': '0.9em',
            'whiteSpace': 'nowrap',
        }
    },
    addCart: {
        textTransform: 'none',
        width: 'stretch',
        height: 41,
        fontWeight: '500',
        margin: '0 10px 10px 10px',
        [theme.breakpoints.down('md')]: {
            margin: '0 10px 0 10px',
        },
    },
    addCartOnlyBigScreen: {
        marginLeft: '4px',
        [theme.breakpoints.down(350)]: {
            display: 'none',
        }
    },
    btnBuyed: {
        'borderRadius': '10px',
        'backgroundColor': '#ffc80a',
        'border': 'none',
        height: 41,
        'color': '#4A4A4A',
        'textAlign': 'center',
        'fontSize': '20px',
        'padding': '6px',
        'width': '100%',
        'transition': 'all 0.5s',
        margin: '0 10px 10px 10px',
        'fontWeight': '500',
        'display': 'table',
        'tableLayout': 'fixed',
        '& > div': {
            'display': 'table-cell',
            'verticalAlign': 'top',
            'textAlign': 'center'
        }
    },
    addCartButton: {
        cursor: 'pointer',
    },
    infiniteListProds: {
        zIndex: 999,
        marginTop: '-109px',

        animation: 'all 0.5s cubic-bezier(0.785, 0.135, 0.150, 0.860) 0.5s',
        scrollBehavior: 'smooth',
        [theme.breakpoints.up(600)]: {
            marginTop: '-64px',
        }
    },
}))

export default function Products() {

    const priceListID = usePriceListIDContext()
    const priceList = usePriceList()
    const { parsed } = AddParam({ urlListener: '/app/products' })
    const parsedData = parsed
    const infinityRef = useRef()
    const min400 = useMediaQuery('(min-width:440px)')
    const min600 = useMediaQuery('(min-width:600px)')
    const trigger = useScrollTrigger({
        target: infinityRef?.current ? infinityRef.current.children[0] : undefined,
        threshold: 1000,
    })
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setLoading(false), 100)
    }, [])

    const { onlyDry } = useUserData()

    HideHeader(!min600 && !!trigger)

    const q = parsedData?.q || ''

    const classes = useStyles()

    const ProductsDispatch = useProductsDispatch()
    const { facets } = useProductsState()

    const parsedFacetFilters = Object.keys(parsedData).filter(f => facets.map(f => f.field).includes(f)).map(f => parsedData[f].split(',').map(fa => f + ':' + fa)).flat()
    const facetFilters = JSON.stringify(['active:true', onlyDry && config.onlyDry && 'conservation:seco', ['showAll:true', 'showOnly:' + priceListID, 'showOnly:' + priceList?.mother], ...parsedFacetFilters].filter(v => v))
    const filters = !['g', 'd'].includes(priceListID) ? '' : `price.${priceListID} > ${(parsedData?.minPrice || 0) / 1.21} AND price.${priceListID} < ${(parsedData?.maxPrice || 100000) / 1.21}`

    const ListElement = useMemo(() =>
        !loading && <div>
            <InfiniteProducts
                index={'products'}
                facetFilters={facetFilters}
                filters={filters}
                facets={facets.map(f => f.field)}
                setFilters={filters => ProductsDispatch({ filters })}
                q={q || ''}
                gridSize={min600 ? 3 : 2}
                infinityRef={infinityRef}
                // maxWidth={900}
                Item={({ hit }) =>
                    <ProductCard product={hit}
                        classes={classes} />
                }
                LoadingItem={() => <ProductCard product={{}}
                    classes={classes} />}
            />
        </div>, [min600, min400, q, ProductsDispatch, classes, facets, facetFilters, filters, loading])

    const FiltersMemo = useMemo(() => <Filters />, [])

    return (
        <>
            {((!trigger || min600)) && min600 && FiltersMemo}
            <Grid container
                spacing={0} >
                <Grid item
                    xs={12} >
                    <div className={classNames({ [classes.infiniteListProds]: !min600 })}
                    >
                        <CartSummary />
                        {ListElement}
                    </div>
                </Grid>
            </Grid>
        </>
    )
}



