import { Box, Divider, List, ListItem, ListItemText } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ErrorIcon from '@material-ui/icons/Error'
import React from 'react'
import { Link } from 'react-router-dom'

import { useStores } from '../../context/UserContext'
import ProfileNav from '../profile/ProfileNav'
import useStyles from './styles'

export default function MyStores() {
  const stores = useStores()
  const classes = useStyles()
  const less1100px = useMediaQuery('(min-width:1100px)')
  const less950px = useMediaQuery('(min-width:950px)')

  return (
    <div className={classes.flexSidebar}>
      {less950px ? <ProfileNav /> : <></>}
      <Box
        style={{
          display: 'flex',
          background: '#F4F4F4',
          width: less950px ? '90%' : '98%',
          maxHeight: '90vh',
          marginLeft: less950px ? 40 : 0,
          borderRadius: '32px',
        }}
        sx={{ '& ::-webkit-scrollbar': { display: 'none' } }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '0 auto',
            gap: 3,
            overflowY: 'scroll',
          }}
        >
          {stores.map((store) => (
            <Link
              style={{ textDecoration: 'none', color: 'black' }}
              to={`/app/storesDetail/${store.id}`}
              key={store?.storeName}
              className={
                less950px
                  ? less1100px
                    ? classes.rootDiv
                    : classes.rootDivPhone
                  : classes.rootDivPhone2
              }
            >
              <Box className={classes.listBox}>
                <List className={classes.list}>
                  <ListItem className={classes.listItemsName}>
                    <Box>
                      <ListItemText>
                        <p className={classes.listNameStore}>
                          {store?.storeName}
                        </p>
                      </ListItemText>
                      <ListItemText>
                        {store?.validated ? (
                          ''
                        ) : (
                          <Box
                            display={'flex'}
                            p={2}
                            className={classes.errorValidated}
                          >
                            <ErrorIcon color="secondary" />
                            <p className={classes.listNameValidatedMessage}>
                              Ya registramos tu Tienda y pronto será habilitada
                              por uno de nuestros representante de ventas.
                            </p>
                          </Box>
                        )}
                      </ListItemText>
                    </Box>
                  </ListItem>
                  <Divider />
                  <ListItem className={classes.listItems}>
                    <ListItemText primary="Razon social" />
                    <ListItemText
                      className={classes.listItemsInfo}
                      primary={store?.razonsocial}
                    />
                  </ListItem>
                  <ListItem className={classes.listItems}>
                    <ListItemText primary="Cuit" />
                    <ListItemText
                      className={classes.listItemsInfo}
                      primary={store?.cuit}
                    />
                  </ListItem>
                  <ListItem className={classes.listItems}>
                    <ListItemText primary="Correo Electronico" />
                    <ListItemText
                      className={classes.listItemsInfo}
                      primary={store?.email}
                    />
                  </ListItem>
                </List>
              </Box>
            </Link>
          ))}
        </div>
      </Box>
    </div>
  )
}
