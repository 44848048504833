import './Header.css'

import {
    AppBar,
    IconButton,
    InputBase,
    Slide,
    Toolbar,
    Typography
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
    Close as CloseIcon,
    Search as SearchIcon,
    ShoppingCartOutlined as CartIcon,
} from '@material-ui/icons'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import config from '../../config'
import { useCartContext } from '../../context/CartContext'
import { useLayoutState } from '../../context/LayoutContext'
// components
import { useSelectedStore, useStores, useUserData } from '../../context/UserContext'
import Filters from '../../pages/products/Filters/Filters'
import AddParam from '../Layout/LocationComponent'
import LoginModal from '../loginModal'
import { MainNotification } from '../Notification/MainNotification'
import SelectStoreModal from '../Store/components/SelectStoreModal'
import { Badge } from '../Wrappers/Wrappers'
import Profile from './IconComponents/Profile'
import Stores from './IconComponents/Stores'
import SendTo from './SendTo'
// styles
import useStyles from './styles'
import WhatsappButton from './Whatsapp'

export default function Header() {
    const classes = useStyles()
    const isSearchOpen = true
    const layoutState = useLayoutState()
    const stores = useStores()
    const { isAnonymous } = useUserData()
    const isAuthenticated = !isAnonymous
    const min600 = useMediaQuery('(min-width:600px)')
    let history = useHistory()
    const { parsed, addParam } = AddParam()
    const [searchValue, setSearchValue] = useState(parsed.q || '')
    const isStoreValidated = stores?.some(a => a.validated)

    let actualLocation = useLocation()

    useEffect(() => {
        setSearchValue(parsed.q || '')
    }, [parsed.q])
    useEffect(() => {
        setSearchValue(parsed.q || '')
    }, [parsed.q])

    useEffect(() => {
        // 👇 add class to body element
        document.body.classList.add('overflowFix')
    })

    let arrowBack = (
        <ArrowLeftIcon
            fontSize="large"
            style={{ color: '#4A4A4A', marginRight: '-6px', marginTop: '2px' }}
        />
    )

    const backHandler = () => {
        if (
            history.length > 2 &&
            actualLocation.pathname.includes('/app/product/') &&
            !min600
        ) {
            history.goBack()
        } else if (actualLocation.pathname !== '/app/homepage') {
            history.push('/app')
        }
    }
    const { store } = useSelectedStore()

    // let isNotHotSale = !(new Date("2023-05-08T00:00:00.000Z") === new Date(Date.now()))
    // let isNotHotSale = !(new Date("2023-05-08T00:00:00.000Z") === new Date(Date.now()))

    return (
        <>
            <Slide direction="down"
                in={layoutState.showHeader}>
                <AppBar position="absolute"
                    color="inherit"
                    className={classes.appBar}>
                    <MainNotification headerMessage={stores?.length && !isStoreValidated ? '¡Estamos validando tu comercio! Pronto podrás comprar con precios exclusivos' : ''}/>
                    <Toolbar className={classes.toolbar}>
                        <div className={classNames([classes.firstRow, 'fillAvailable'])}>
                            <div style={{display: 'flex', alignItems:'center',  minWidth: 100 }} onClick={() => backHandler()}
                                >
                                {!min600 && !(actualLocation.pathname === '/app/homepage') ? (
                                    arrowBack
                                ) : (
                                    <span style={{ padding: '.3rem' }}></span>
                                )}
                                <img alt={'vivet'}
                                    src={config.logo}
                                    className={classes.logo} />
                            </div>

                            {config.plant_web_view && <SendTo />}

                            {!config.plant_web_view && store?.storeName && !(config.show_prices_only_validated_store && isAnonymous) &&
                                <SelectStoreModal Element={({ setOpen }) => <>
                                    <div className={classes.storeNameContainer}
                                        onClick={(e) => { e.stopPropagation(); setOpen(true) }}>
                                        <Typography className={classes.storeNameTextBefore}
                                            color="textSecondary"
                                            style={{ fontSize: 10 }}>Comprando para</Typography>
                                        <Typography className={classes.storeName}
                                            color="textSecondary">{store?.storeName}</Typography>
                                    </div>
                                </>} />}
                            <div className={classes.grow} />


                            {min600 && (
                                <>
                                    <div
                                        className={classNames(classes.search, {
                                            [classes.searchFocused]: isSearchOpen,
                                        })}
                                    >
                                        <div
                                            className={classNames(classes.searchIcon, {
                                                [classes.searchIconOpened]: isSearchOpen,
                                            })}
                                            // onClick={() => setSearchOpen(!isSearchOpen)}
                                            onClick={() => {
                                                // !searchValue && setSearchOpen(!isSearchOpen);
                                                searchValue &&
                                                    addParam({ specs: '', brand: '', q: '' })
                                                searchValue && setSearchValue('')
                                            }}
                                        >
                                            {searchValue ? (
                                                <CloseIcon classes={{ root: classes.headerIcon }} />
                                            ) : (
                                                <SearchIcon classes={{ root: classes.headerIcon }} />
                                            )}
                                        </div>
                                        <InputBase
                                            onChange={(e) => {
                                                setSearchValue(e.target.value)
                                                e.target.value?.length > 2 &&
                                                    addParam({
                                                        specs: '',
                                                        brand: '',
                                                        q: e.target.value,
                                                    })
                                            }}
                                            value={searchValue}
                                            placeholder="Conectá con lo que querés"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput,
                                            }}
                                        />
                                    </div>
                                    {layoutState.buttonShowProducts && (
                                        <Button
                                            className={classes.showProducts}
                                            onClick={() => history.push('/app/products')}
                                        >
                                            Ver Productos
                                        </Button>
                                    )}
                                    <div className={classes.grow} />
                                  <WhatsappButton />
                                </>
                            )}

                            {isAuthenticated ? (
                                <>

                                    {config.stores && <Stores classes={classes} />}
                                    <Profile classes={classes} />
                                    {/*<Notifications classes={classes}/>*/}
                                    {(isStoreValidated || !config.stores) && <HeaderCartIcon classes={classes} />}
                                </>
                            ) : (
                                <>
                                    <LoginModal />
                                    {!config?.stores ? (
                                        <HeaderCartIcon classes={classes} />
                                    ) : null}
                                </>
                            )}
                        </div>
                    </Toolbar>
                    {
                        !min600 && layoutState.showSearchBar && (
                            <div className={classes.secondRow}>
                                <div
                                    className={classNames(classes.search, classes.searchFocused, {
                                        [classes.searchFocusedMinimized]:
                                            layoutState.buttonShowProducts,
                                    })}
                                >
                                    <div
                                        className={classNames(classes.searchIcon, {
                                            [classes.searchIconOpened]: isSearchOpen,
                                        })}
                                        // onClick={() => setSearchOpen(!isSearchOpen)}
                                        onClick={() => {
                                            addParam({ specs: '', brand: '', q: '' })
                                            setSearchValue('')
                                        }}
                                    >
                                        {searchValue ? (
                                            <CloseIcon
                                                classes={{ root: classes.headerIcon }}
                                            ></CloseIcon>
                                        ) : (
                                            <SearchIcon classes={{ root: classes.headerIcon }} />
                                        )}
                                    </div>
                                    <InputBase
                                        onChange={(e) => setSearchValue(e.target.value)}
                                        value={searchValue}
                                        onKeyPress={(e) =>
                                            e.key === 'Enter' &&
                                            addParam({ specs: '', brand: '', q: e.target.value })
                                        }
                                        onBlur={(e) =>
                                            addParam({ specs: '', brand: '', q: e.target.value })
                                        }
                                        placeholder="¿Qué estás Buscando?"
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput,
                                        }}
                                    />
                                </div>
                                {layoutState.buttonShowProducts && (
                                    <Button
                                        className={classes.showProducts}
                                        onClick={() => history.push('/app/products')}
                                    >
                                        Ver Productos
                                    </Button>
                                )}
                            </div>
                        )
                        // <Tabs
                        //     value={tabID}
                        //     onChange={(e, id) => setTabID(id)}
                        //     indicatorColor="primary"
                        //     textColor="primary"
                        //     centered
                        //     classes={{root: classes.tabs}}
                        // >
                        //   <Tab label="Home" classes={{root: classes.tab}}/>
                        //   <Tab label="Productos" classes={{root: classes.tab}}/>
                        // </Tabs>
                    }

                    {actualLocation.pathname === '/app/products' && !min600 && (
                        <div style={{ position: 'relative' }}>
                            <Filters />
                        </div>
                    )}
                </AppBar>
            </Slide>
        </>
    )
}

function HeaderCartIcon({ classes }) {
    const cart = useCartContext()
    let history = useHistory()

    const total = Object.keys(cart).reduce(function (previous, key) {
        return previous + cart[key].q
    }, 0)
    return (
        <IconButton
            // href={'/app/carrito'}
            color="inherit"
            aria-haspopup="true"
            aria-controls="mail-menu"
            onClick={(e) => {
                // setMailMenu(e.currentTarget);
                // setIsMailsUnread(false);
                e.stopPropagation()
                history.push('/app/buy/cart')
            }}
            className={classes.headerMenuButton}
        >
            <Badge color={'secondary'}
                badgeContent={total || null}>
                <CartIcon classes={{ root: classes.headerIcon }} />
            </Badge>
        </IconButton>
    )
}
