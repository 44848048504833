import { createNullCache } from '@algolia/cache-common'
import algoliasearch from 'algoliasearch'
import _ from 'lodash'

import config from './config'

const algoliaconfig = config('algolia')
let indexAlgolia = {}

let initIndex = (index) => {
    if (!indexAlgolia[index]) {
        const app =
            algoliaconfig?.find((d) => d.indexes.includes(index)) ||
            _.last(algoliaconfig)
        indexAlgolia[index] = algoliasearch(app.id, app.privatekey, {
            responsesCache: createNullCache(),
            requestsCache: createNullCache(),
        }).initIndex(index)
    }
}

let algoliaIndex = (index) => {
    initIndex(index)
    return indexAlgolia[index]
}

let algoliaIndexBrowser = algoliaIndex


export {
    algoliaIndex,
    algoliaIndexBrowser,
}
