import { Typography } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Modal from '@material-ui/core/Modal'
import {
    Store as StoreIcon,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { usePriceListsDataContext } from '../../../context/PriceListContext'
import { useSelectedStore, useStores } from '../../../context/UserContext'


const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'absolute',
        // width: '80%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 2),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 'max-content',
    },
    modalContent: {
        maxHeight: '20rem',
        overflow: 'auto',
    },
    noValidated: {
        color: 'red',
    }
}))



export default function SelectStoreModal({ Element = () => <></> }) {

    const stores = useStores()
    const { store, setSelectedStore } = useSelectedStore()
    const priceLists = usePriceListsDataContext()
    const classes = useStyles()
    let history = useHistory()

    const [open, setOpen] = React.useState(false)

    const handleClose = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setOpen(false)
    }


    return <>
        <Element setOpen={setOpen} />
        <Modal
            open={open}
            onClose={handleClose}
        >
            <div className={classes.modal}>
                <Typography component={'h3'}>Seleccione una Tienda</Typography>
                <List className={classes.modalContent}>
                    {stores.map(s => (
                        <ListItem
                            key={s.id}
                            button={s.validated}
                            selected={s.id === store.id}
                            onClick={(e) => {
                                e.stopPropagation()
                                if (s.id !== store.id && s.validated) {
                                    setOpen(false)
                                    setSelectedStore(s.id)
                                }
                            }}
                        >
                            <ListItemIcon>
                                <StoreIcon />
                            </ListItemIcon>
                            <ListItemText primary={s.storeName}
                                secondary={<>{priceLists[s.priceList?.v]?.name}
                                    {!s.validated && <div className={classes.noValidated}>No validado</div>}</>} />
                        </ListItem>))}
                    <ListItem
                        button
                        onClick={(e) => {
                            e.stopPropagation()
                            setOpen(false)
                            history.push('/app/newStore')
                        }}
                    >
                        <ListItemIcon>
                            <StoreIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Nueva Tienda'} />
                    </ListItem>
                </List>
            </div>
        </Modal>
    </>
}
