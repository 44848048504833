import {useEffect, useState} from 'react'

import Populate from '../../../algolia/populate'
import {GetPricesTotals} from '../../../components/Product/Price'
import {useCartContext} from '../../../context/CartContext'


export default function GetTotals() {
    const cart = useCartContext()
    const [hits,setHits] = useState({})

    useEffect(()=>{
        let active=true
        Object.keys(cart).reduce((p,a)=>p||!hits[a],false) &&
        Populate({hits:[{products:Object.keys(cart)}],props:[{index:'products',field:'products'}]})
            .then(h=>active&&setHits(h[0].products))
        return ()=>active=false
    },[cart,hits])


    return GetPricesTotals({products:Object.keys(cart).filter(a=>cart[a]&&cart[a].q&&!!hits[a]).map(a=>({...hits[a],q:cart[a].q}))})
}
