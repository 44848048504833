import { Box, CircularProgress, List, ListItem, ListItemText, useMediaQuery } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import {
  useHistory
} from 'react-router-dom'

import { useMarketContext } from '../../../context/MarketContext'
import { useProcessDispatch, useProcessState } from '../../../context/ProcessContext'
import { useSelectedStore, useUserData } from '../../../context/UserContext'
import { updateDoc } from '../../../firebase/database'
import { useCartList } from '../cart/CartList'
import { DaySelect } from './DaySelect'
import { SelectNewCarrier } from './SelectNewCarrier'
import useStylesCarrier from './styles'

export default function CarrierSelect() {

  const history = useHistory()
  const [hits] = useCartList()
  const max425 = useMediaQuery('(max-width:425px)')

  const carrier = {
    'padding': '0 1rem',
    'marginTop': '5px',
    'textAlign': 'center',
    'marginBottom': '5px',
    'cursor': 'pointer',
    '&:hover': {
      'background': '#ba6f50 !important',
    },
    '&:hover > div': {
      color: '#fff !important'
    },
    '@media (pointer:coarse)': {
      '&:hover': {
        backgroundColor: 'unset',
        '& > div': {
          color: 'unset'
        },
      },
    }
  }
  const carrierSelected = {
    'marginBottom': '5px',
    'marginTop': '5px',
    'padding': '0 1rem',
    'textAlign': 'center',
    'cursor': 'pointer',
    // "@media (pointer:coarse)": {
    //     backgroundColor: 'unset'
    // },
    'backgroundColor': '#ba6f50',
    'color': '#fff'
  }

  const processDispatch = useProcessDispatch()
  const processState = useProcessState()
  const user = useUserData()

  const { carriers } = useMarketContext()
  const { store } = useSelectedStore()

  const conservations = _.uniq(Object.entries(hits || {}).map(([, p]) => (p.conservation)))
  const [filteredCarriers, setFilteredCarriers] = useState([])
  const [carriersToOrder, setCarriersToOrder] = useState([])
  const [userWillChange, setUserWillChange] = useState({})
  const [, setOpenDaysSelect] = useState(false)

  const cartHasConservation = () => {
    Object.entries(carriers || {}).map(([, carrier]) => {
      conservations.map(c => {
        if (carrier.conservations.some(x => x === c)) {
          setFilteredCarriers((filteredCarriers) => ({ ...filteredCarriers, [carrier.objectID]: carrier.conservations }))
        }
      })
    })
    Object.entries(store?.carriers || {}).map(([type, id]) => {
      conservations.map(c => {
        if (type === c) {
          setCarriersToOrder((filteredCarriersToOrder) => ({ ...filteredCarriersToOrder, [c]: id.carrierID }))
        }
      })
    })
  }

  useEffect(() => {
    hits && cartHasConservation()
  }, [hits, carriers])

  const saveCarriers = (conservation, carrierID) => {

    if (carriersToOrder[conservation] === carrierID) {
      if (userWillChange?.id !== carrierID || userWillChange?.cons !== conservation) {
        setUserWillChange({ cons: conservation, id: carrierID })
      } else {
        setUserWillChange({})
      }
    } else {
      setCarriersToOrder((carriers) => ({ ...carriers, [conservation]: carrierID }))
      setOpenDaysSelect(true)
      setUserWillChange({})
    }
  }

  const updateStore = (newCarrier) => {
    const filterCarriers = () => {
      if (!Object.values(newCarrier || {})?.length) {
        let changedCarriers = Object.entries(store?.carriers || {}).reduce((p, [c, id]) => ({ ...p, [c]: id }), {})
        Object.entries(store?.carriers || {}).map(([c, idCarrier]) => {
          {
            Object.entries(carriersToOrder || {}).map(([conservation, id]) => {
              if (idCarrier !== id) {
                changedCarriers = { ...changedCarriers, [conservation]: { carrierID: id, preferenceDay: store?.carriers[conservation]?.preferenceDay?.length ? store?.carriers[conservation]?.preferenceDay : '' } }
              } else {
                changedCarriers = { ...changedCarriers, [c]: { carrierID: idCarrier, preferenceDay: store?.carriers[conservation]?.preferenceDay?.length ? store?.carriers[conservation]?.preferenceDay : '' } }
              }
            }
            )
          }
        }
        )
        return changedCarriers
      }
    }

    let changedCarriers = !Object.values(newCarrier || {})?.length ? filterCarriers() : newCarrier
    let dataToHit = Object.values(changedCarriers || {})?.length ? { ...changedCarriers } : { ...carriersToOrder }

    updateDoc({
      indexName: 'stores', id: processState.storeID || user?.lastStore?.id,
      hit: {
        ['carriers']: dataToHit
      }
    })

    updateDoc({
      indexName: 'users', id: user?.uid,
      hit: {
        ['lastStore.carriers']: dataToHit
      }
    })
  }

  return (
    <>
      {
        !Object.entries(hits || {}).length ? <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress color="primary" /></div> :
          <>
            <Button style={{ display: 'flex', margin: 'auto' }}
              onClick={() => history.push('cart')}><ArrowBackIcon /> Volver</Button>
            <Typography style={{ textAlign: 'center', marginTop: 20, fontWeight: 'bold' }}>{conservations.length > 1 ? 'Seleccioná el transportista por categoria' : 'Seleccioná el transportista'}</Typography>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15, flexDirection: max425 && 'column' }}>
              {conservations.map((c) =>
                <List style={{ margin: '0 1rem' }}
                  key={c}>
                  {conservations?.length > 1 && <Typography style={{ fontSize: '1.2rem', textAlign: 'center', fontWeight: 'bold' }}>{c}s</Typography>}
                  <Typography color='primary'
                    style={{ fontSize: '.8rem', textAlign: 'center' }}>día de despacho:{store?.carriers?.[c]?.preferenceDay}</Typography>

                  {Object.entries(filteredCarriers || {})?.filter(([, cc]) => cc.some(x => x === c)).map(([cID]) =>
                    <>
                      <ListItem key={cID}
                        onClick={() => saveCarriers(c, cID)}>
                        <ListItemText style={carriersToOrder?.[c] !== cID ? { ...carrier } : { ...carrierSelected }}
                          primary={carriers[cID].name} />
                      </ListItem>
                      <DaySelect conservation={c}
                        carrierID={cID}
                        userWillChange={userWillChange?.['id'] === cID && userWillChange?.['cons'] === c}
                        setUserWillChange={setUserWillChange}
                        active={carriersToOrder?.[c] === cID} />
                    </>
                  )}
                </List>
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <SelectNewCarrier saveCarriers={saveCarriers}
                updateStore={updateStore}
                setCarriersToOrder={setCarriersToOrder}
                conservations={conservations}
                carriers={carriers} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
              <Button
                onClick={() => { processDispatch({ carriers: carriersToOrder }); updateStore(); history.push('/app/buy/cart') }}
                variant='contained'
                color='secondary'
                disabled={Object.values(carriersToOrder || {}).length < conservations.length}>
                CONTINUAR
              </Button>
            </div>
          </>
      }
    </>
  )
}

export function CarrierTemplate({ carriersFromOrder, hits }) {

  const history = useHistory()
  const classes = useStylesCarrier()
  const { carriers } = useMarketContext()
  const conservations = _.uniq(Object.entries(hits || {}).map(([, p]) => (p.conservation)))
  let filteredCarriersToOrder = {}

  const cartHasConservation = () => {

    Object.entries(carriersFromOrder || {}).map(([type, id]) => {
      conservations.map(c => {
        if (type === c) {
          filteredCarriersToOrder = { ...filteredCarriersToOrder, [c]: id.carrierID }
        }
      })
    })
  }

  hits && cartHasConservation()

  const separateCarriers = () => {

    let carriersSeparated = Object.entries(filteredCarriersToOrder || {}).reduce((p, [, idCarrier]) => {

      return { ...p, [idCarrier]: Object.entries(filteredCarriersToOrder || {}).filter(([, id]) => id === idCarrier).map(x => x[0]) }
    }, {})

    return Object.entries(carriersSeparated || {})
  }

  const carriersFormatted = separateCarriers()
  return (
    <Box className={classes.carrierBox}
      onClick={() => history.push('/app/buy/carrierselect')}
      padding='1rem'
      marginBottom={1}
      borderRadius={8} >
      <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>{_.uniq(Object.values(carriersFromOrder || {})).length > 1 ? 'Lista de transportistas' : 'transportista'}</Typography>
      <Box display='flex'
        justifyContent={'center'}
        flexWrap='wrap'>
        {
          carriersFormatted?.map(([carrierID, conservations]) => {
            return (
              <Box key={carrierID} style={{ margin: '0 1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography>{carriers[carrierID]?.name}</Typography>
                <LocalShippingIcon />
                {conservations?.map(type => <Typography key={type} style={{ fontSize: '.9rem' }}>{type}</Typography>)}
              </Box>
            )
          })
        }
      </Box>
    </Box>
  )
}