import {
  Box,
  Button,
  CircularProgress,
  ListItemText,
  Typography,
} from '@material-ui/core'
//icons de Font Awesome
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSearch } from '@fortawesome/free-solid-svg-icons';
//x icons de Font Awesome x
//styles
import { makeStyles } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Store } from '@material-ui/icons'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import { Skeleton } from '@material-ui/lab'
import moment from 'moment/moment'
import React, {
  useEffect,
  useState,
  // useEffect
} from 'react'
import {
  Route,
  Switch, //useHistory
} from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import { numberWithCommas } from '../../components/Utils/NumberWithCommas'
import { useChangeTitle } from '../../components/Utils/useChangeTitle'
import config from '../../config/config'
import { useUserData } from '../../context/UserContext'
import { db } from '../../firebase'
import ProfileNav from '../profile/ProfileNav'
import OrderDetails from './OrderDetails'

// import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  storeMainBox: {
    marginTop: 30,
    marginRight: 5,
    marginLeft: 5,
    maxWidth: 450,
    cursor: 'pointer',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '95%',
      padding: '6px 8px 3px 8px',
    },
    borderRadius: '10px',
    marginBottom: '2rem',
    background: '#fff',
    padding: '18px 20px 10px 20px',
    boxShadow: '2px 4px 8px 1px rgba(0, 0, 0, 0.09)',
    height: '200px',
    '&:hover': {
      boxShadow: '2px 4px 8px 5px rgba(0, 0, 0, 0.09)',
    },
    transition: 'all ease-in-out 0.15s',
  },
  listStoreBox: {
    marginTop: 30,
  },
  listNameStore: {
    fontSize: '32px',
    fontWeight: 'bold',
  },
  headerDetail: {
    padding: 5,
    [theme.breakpoints.down('xs')]: {
      padding: 2,
    },
    flex: 1,
    borderRadius: 5,
  },
  datosEnvioWrap: {
    flex: 1,
  },
  storeBox: {
    backgroundColor: '#E8E8E8',
    borderRadius: '10px',
    height: '39px',
    paddingLeft: 10,
    paddingRight: 10,
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  datosEnvioInfo: {
    justifyContent: 'flex-start',
  },
  datosEnvioInfoText: {
    color: '#898989',
    margin: 0,
    whiteSpace: 'nowrap',
  },

  contentDetailBox: {
    backgroundColor: '#fff',
    padding: '.5rem',
  },
  datosNombreText: {
    margin: 0,
    fontSize: 15,
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  datosNombreStore: {
    margin: 0,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },

  footerText: {
    margin: 0,
    padding: '.5rem',
  },
  skeletonBox: {
    margin: '0 auto',
    width: '400px',
    height: '200  px',
    padding: 10,
  },
}))

//components

export default function MyOrders() {
  config?.plant_web_view === true
    ? useChangeTitle(' Mis Ordenes - Plant')
    : useChangeTitle(' Mis Ordenes - Plant business')
  return (
    <Switch>
      <Route path="/app/myorders"
        exact
        component={MyOrdersList} />
      <Route path="/app/myorders/:orderId">
        <OrderDetails />
      </Route>
    </Switch>
  )
}

const options = { year: 'numeric', month: 'long', day: 'numeric' }

function MyOrdersList() {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [isEnd, setIsEnd] = useState(false)
  const [cursor, setCursor] = useState(false)
  const orders = GetOrders({ cursor, isEnd, setIsEnd, setIsLoading })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  const less950px = useMediaQuery('(min-width:950px)')
  const less1024px = useMediaQuery('(min-width:1024px)')
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: 4,
        marginTop: less950px ? '20px' : '2px',
        marginRight: less1024px ? '12px' : '2px',
      }}
      sx={{ '& ::-webkit-scrollbar': { display: 'none' } }}
    >
      {less950px ? <ProfileNav /> : <></>}
      <div
        style={{
          display: 'flex',
          background: '#F4F4F4',
          width: less950px ? '90%' : '98%',
          maxHeight: '90vh',
          marginLeft: less1024px ? 40 : 5,
          borderRadius: '32px',
          overflowY: 'auto',
        }}
      >
        <div
          style={{
            paddingLeft: less1024px ? 120 : 0,
            paddingRight: less1024px ? 30 : 0,
            margin: '0 auto',
          }}
        >
          {!!orders?.length && (
            <Box className={classes.listStoreBox}>
              <ListItemText>
                <h3>Mis Compras</h3>
              </ListItemText>
            </Box>
          )}
          {loading
            ? orders.map((order, i) => (
              <Box key={i}
                pt={0.8}
                className={classes.skeletonBox}>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  height={180}
                  width="100%"
                />{' '}
                <Skeleton width="100%" />
              </Box>
            ))
            : orders.map((order) => <OrderCard key={order.id}
              order={order} />)}
          {!isEnd && orders?.length ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={() => setCursor(!cursor)}
                variant="contained"
                style={{ marginBottom: 10 }}
                color="primary"
              >
                {isLoading ? <CircularProgress /> : 'Ver más'}
              </Button>
            </div>
          ) : (
            <Box style={{ margin: 'auto', marginTop: 100 }}>
              {isLoading && <CircularProgress />}
            </Box>
          )}
        </div>
      </div>
    </Box>
  )
}

export const OrderCard = (props) => {
  const classes = useStyles()

  const less620px = useMediaQuery('(min-width:1111px)')
  const history = useHistory()

  const { order } = props
  return (
    <Box
      onClick={() => history.push(`/app/order/${order.id}`)}
      className={classes.storeMainBox}
      display="flex"
      justifyContent="space-between"
    >
      <Box
        className={classes.headerDetail}
        display="flex"
        justifyContent={'space-between'}
      >
        <Box
          className={classes.datosEnvioWrap}
          display="flex"
          justifyContent={'space-between'}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-between'}
            style={{ flex: 1 }}
          >
            <Box display={'flex'}>
              <Box display={'flex'}
                flexDirection="column"
                style={{ flex: 1 }}>
                <Box display={'flex'}
                  style={{ flex: 1 }}>
                  <Box
                    display={'flex'}
                    style={{ width: '100%' }}
                    flexDirection={'column'}
                  >
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      style={{ gap: 8, width: '100%' }}
                    >
                      {order.data.user.name ? (
                        <p className={classes.datosNombreStore}>
                          {order.data.user.name}
                        </p>
                      ) : (
                        <p className={classes.datosNombreStore}>
                          {order.addressee}
                        </p>
                      )}
                      <div className={classes.storeBox}>
                        <Store />
                        <p
                          className={classes.datosNombreText}
                          style={{
                            whiteSpace: 'nowrap',
                            fontWeight: 600,
                          }}
                        >
                          {order?.data?.store?.storeName}
                        </p>
                      </div>
                    </Box>
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      style={{ width: '100%', marginTop: 4 }}
                    >
                      <p className={classes.datosEnvioInfoText}>
                        {new Date(
                          order?.timestamp?.seconds * 1000
                        ).toLocaleDateString('es-ES', options)}
                      </p>
                      <span
                        style={{
                          paddingLeft: 10,
                          display: 'flex',
                          gap: 5,
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <p
                          style={{
                            margin: 0,
                            whiteSpace: 'nowrap',
                            color: '#444',
                            fontWeight: 600,
                            fontSize: '14px',
                          }}
                        >
                          {Object.keys(order.items || {})?.length}
                          {Object.keys(order.items || {})?.length > 1
                            ? ' Productos'
                            : ' Producto'}
                        </p>
                      </span>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* storename */}
            </Box>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'flex-end'}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                maxWidth={less620px ? '100%' : '40%'}
                style={{
                  paddingRight: less620px ? 20 : 0,
                  gap: 4,
                }}
              >
                <OrderChip
                  less620px={less620px}
                  label={order.status === 'payed' ? 'pagado' : 'Pago pendiente'}
                  bgColor={order.status === 'payed' ? '#dcf1f1' : '#FE9696'}
                  labelColor={order.status === 'payed' ? '#648157' : '#C91515'}
                  iconBgColor={order.status === 'payed' ? '#C7EAB6' : '#FFC3C3'}
                  Icon={AttachMoneyIcon}
                />
                <OrderChip
                  less620px={less620px}
                  label={
                    order.delivered
                      ? `Entregado : ${moment(
                        new Date(order.delivered_timestamp * 1000)
                      ).format('DD/MM/yyyy HH:mm')}`
                      : order.shipmentState === 'pending'
                        ? 'Pendiente'
                        : order.shipmentState === 'assigned'
                          ? 'Confirmado'
                          : 'Pendiente'
                  }
                  secondaryLabel={
                    order.shipmentState === 'assigned'
                      ? order.shippingDate
                        ? `${moment(
                          new Date(order.shippingDate + ' GMT-0300')
                        ).format('DD/MM/yyyy HH:mm')}`
                        : ''
                      : ''
                  }
                  bgColor={
                    order.delivered
                      ? '#dcf1f1'
                      : order.shipmentState === 'pending'
                        ? '#FE9696'
                        : order.shipmentState === 'assigned'
                          ? '#FEC296'
                          : '#FE9696'
                  }
                  labelColor={
                    order.delivered
                      ? 'black'
                      : order.shipmentState === 'pending'
                        ? '#C91515'
                        : order.shipmentState === 'assigned'
                          ? '#C96115'
                          : '#C91515'
                  }
                  iconBgColor={
                    order.delivered
                      ? '#dcf1f1'
                      : order.shipmentState === 'pending'
                        ? '#FFC3C3'
                        : order.shipmentState === 'assigned'
                          ? '#FFE0C3'
                          : '#FFC3C3'
                  }
                  Icon={LocalShippingIcon}
                />
              </Box>
              <p
                style={{ color: '#565656' }}
                className={classes.datosNombreStore}
              >
                ${numberWithCommas(order.total || order.subTotal * 1.21, 0)}
              </p>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

function GetOrders({ cursor, isEnd, setIsEnd, setIsLoading }) {
  const { uid } = useUserData()
  const [orders, setOrders] = useState([])
  const [lastVisited, setLastVisited] = useState(null)

  const firstFetch = () => {
    let active = true

    db.collection('orders')
      .where('user', '==', uid)
      .orderBy('timestamp', 'desc')
      .limit(10)
      .get()
      .then((docs) => {
        let ndocs = []
        const lastVisible = docs.docs[docs.docs.length - 1]
        setLastVisited(lastVisible)

        docs.forEach(function (doc) {
          let data = doc.data()
          ndocs.push({ ...data, id: doc.id })
        })

        active && setOrders(ndocs)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    let active = true
    setIsLoading(true)

    if (uid)
      if (!lastVisited && !isEnd) {
        firstFetch()
      } else if (lastVisited && !isEnd) {
        db.collection('orders')
          .where('user', '==', uid)
          .orderBy('timestamp', 'desc')
          .startAfter(lastVisited)
          .limit(10)
          .get()
          .then((docs) => {
            let ndocs = orders
            const lastVisibleDocs = docs.docs[docs.docs.length - 1]
            if (lastVisibleDocs) {
              setLastVisited(lastVisibleDocs)
            }

            // Fetchea por segunda vez para revisar si quedan más elementos tomando como referencia el último elemento
            db.collection('orders')
              .where('user', '==', uid)
              .orderBy('timestamp', 'desc')
              .startAfter(lastVisibleDocs)
              .limit(10)
              .get()
              .then((docs2) => {
                const lastVisible = docs2.docs[docs2.docs.length - 1]
                if (!lastVisible) {
                  setIsEnd(true)
                }
              })

            docs.forEach(function (doc) {
              let data = doc.data()
              ndocs.push({ ...data, id: doc.id })
            })

            setIsLoading(false)
            active && setOrders(ndocs)
          })
      }

    return () => (active = false)
  }, [uid, cursor])
  return orders
}

const OrderChip = ({
  Icon,
  label,
  secondaryLabel,
  bgColor,
  iconBgColor,
  labelColor,
  less620px,
}) => {
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '5px',
        backgroundColor: bgColor,
        height: '34px',
        paddingRight: 8,
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          borderTopLeftRadius: '5px',
          borderBottomLeftRadius: '5px',
          height: '34px',
          width: '30%',
          marginRight: 6,
          paddingLeft: 4,
          paddingRight: 4,
          justifyContent: 'center',
          background: iconBgColor,
        }}
      >
        <Icon
          style={{ color: labelColor, fontSize: less620px ? '18px' : '15px' }}
        />
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          style={{
            color: labelColor,
            whiteSpace: 'nowrap',
            lineHeight: 1.2,
            fontSize: less620px ? '14px' : '12px',
          }}
        >
          {label}
        </Typography>
        <Typography
          style={{
            whiteSpace: 'nowrap',
            fontSize: less620px ? '12px' : '10px',
            color: labelColor,
            opacity: 80,
            margin: 0,
            lineHeight: 1,
            textAlign: 'center',
          }}
        >
          {secondaryLabel}
        </Typography>
      </Box>
    </Box>
  )
}

export const demoOrder = {
  id: 'id1',
  priceList: 'g',
  address: 'XIitHLuMchAxLG8eTD0e',
  total: 1115,
  onlinePayment: true,
  user: 'RZhwHX9RU8YQwoSFybs07uDm2Vy2',
  shippings: ['ITZDTsFT0HdpDD3Rxaf6'],
  data: {
    user: {
      name: 'Mica',
    },
    address: {
      details: '5, c ',
      name: 'Av. Cnel. Niceto Vega 5656',
      address_components: [
        {
          types: ['street_number'],
          short_name: '5656',
          long_name: '5656',
        },
        {
          short_name: 'Av. Cnel. Niceto Vega',
          long_name: 'Avenida Coronel Niceto Vega',
          types: ['route'],
        },
        {
          long_name: 'Palermo Hollywood',
          short_name: 'Palermo Hollywood',
          types: ['neighborhood', 'political'],
        },
        {
          long_name: 'Palermo',
          types: ['sublocality_level_1', 'sublocality', 'political'],
          short_name: 'Palermo',
        },
        {
          types: ['administrative_area_level_2', 'political'],
          short_name: 'Comuna 14',
          long_name: 'Comuna 14',
        },
        {
          types: ['administrative_area_level_1', 'political'],
          short_name: 'CABA',
          long_name: 'Buenos Aires',
        },
        {
          types: ['country', 'political'],
          short_name: 'AR',
          long_name: 'Argentina',
        },
        {
          long_name: 'C1414',
          short_name: 'C1414',
          types: ['postal_code'],
        },
      ],
      formatted_address: 'Av. Cnel. Niceto Vega 5656, C1414 CABA, Argentina',
      shippings: ['ITZDTsFT0HdpDD3Rxaf6'],
      last_used: {
        _seconds: 1596739196,
        _nanoseconds: 715000000,
      },
      geometry: {
        location: {
          lng: -58.4399702,
          lat: -34.5863116,
        },
      },
      users: ['RZhwHX9RU8YQwoSFybs07uDm2Vy2'],
      place_id: 'ChIJpdKeLY21vJURxHu-AON6yoU',
    },
  },
  seller: 'v',
  shipping: 'ITZDTsFT0HdpDD3Rxaf6',
  discount: 0,
  mp_comission: 0,
  shipping_cost: 350,
  subTotal: 632.2314049586778,
  store: false,
  items: {
    nyR23tEzgAfobNza3zyS: {
      q: 1,
      s: 'v',
      price: 301.6528925619835,
      timestamp: {
        _seconds: 1596738475,
        _nanoseconds: 29000000,
      },
    },
    jVnglk7JyZBudu6QRRxd: {
      s: 'v',
      price: 54.54545454545455,
      timestamp: {
        _seconds: 1596738290,
        _nanoseconds: 415000000,
      },
      q: 1,
    },
    zJjDkm9g7GPN4VbD1Rjg: {
      timestamp: {
        _seconds: 1596737881,
        _nanoseconds: 889000000,
      },
      q: 1,
      s: 'v',
      price: 108.2644628099174,
    },
    bF2lbJjfClz058cKxdN2: {
      s: 'v',
      timestamp: {
        _seconds: 1596738381,
        _nanoseconds: 445000000,
      },
      price: 119.0082644628099,
      q: 1,
    },
    WVWSSHHOWEw9nhpip4dA: {
      price: 48.7603305785124,
      s: 'v',
      q: 1,
      timestamp: {
        _seconds: 1596738926,
        _nanoseconds: 90000000,
      },
    },
  },
  timestamp: {
    _seconds: 1596740040,
    _nanoseconds: 727000000,
  },
  copied: false,
  status: 'pending_payment',
  porcentDiscount: 0,
  refunded: 0,
  mercadoPagoId: '314922091-be184b07-2737-44dc-8b79-f774136417ff',
  approved: true,
  messageSent: true,
  __v: 0,
  modified_date: '2020-08-06T18:54:02.881Z',
  objectID: 'NMnSmKdT23xPjifnJvIp',
}
