import CircularProgress from '@material-ui/core/CircularProgress'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import React, { useEffect, useState } from 'react'

import { useMarketContext } from '../../../../context/MarketContext'
import useStyles from './styles'

export default function NewAddress({
  Template,
  setNewAddressValue,
  autoFocus = true,
  label = 'Nueva Direccion',
  defaultAddress = '',
}) {
  const classes = useStyles()
  const [inpMapValue, setInpMapValue] = useState('')
  const [addresses, setAddresses] = useState([])
  const [loading, setLoading] = useState(false)
  const shippings = useMarketContext().shippings || {}

  const displaySuggestions = function (predictions, status) {
    if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
      // alert(status);
      return
    }
    setLoading(false)
    // console.log(predictions);
    setAddresses(
      predictions //.filter(p=>p.types.includes('street_address'))
    )
  }

  useEffect(() => {
    let active = true
    if (window.google && inpMapValue.length > 2 && !/^\d+$/.test(inpMapValue)) {
      if (!window.AutocompleteSessionToken)
        window.AutocompleteSessionToken =
          new window.google.maps.places.AutocompleteSessionToken()
      if (!window.AutocompleteService)
        window.AutocompleteService =
          new window.google.maps.places.AutocompleteService()
      setLoading(true)
      setTimeout(
        () =>
          active &&
          window.AutocompleteService.getPlacePredictions(
            {
              input: 'argentina ' + inpMapValue,
              sessionToken: window.AutocompleteSessionToken,
            },
            displaySuggestions
          ),
        500
      )
    }
    return () => {
      active = false
      setLoading(false)
    }
  }, [inpMapValue])

  return (
    <>
      {/*<div className={classes.title}><Typography variant={'h2'} align={'center'}>Añadir una dirección de entrega</Typography></div>*/}

      {/*{!showMap && <img src={mapEmptyImg} alt={"map"}/>}*/}
      {/*{GoogleMap}*/}
      {/*<MapLeaf/>*/}

      <TextField
        label={label}
        className={classes.inpMap}
        value={inpMapValue || defaultAddress}
        onChange={(e) => {
          setInpMapValue(e.target.value)
        }}
        id={'inpMap'}
        onKeyPress={(e) => e.key === 'Enter' && e.target.blur()}
        // onBlur={e=>e.target.value!==hit[f.field]&&update({field:f.field,value:e.target.value})}
        //         rowsMax="1"
        autoFocus={autoFocus}
        variant="outlined"
        InputProps={
          loading
            ? {
              endAdornment: (
                <InputAdornment position="end">
                  <CircularProgress color="inherit"
                    size={20} />
                </InputAdornment>
              ),
            }
            : {}
        }
      />

      {addresses?.map((a) => (
        <Template
          key={a.place_id}
          onClick={() => {
            AddNewAddressHandle(a.place_id, shippings, setNewAddressValue)
            setAddresses([])
          }}
          collection={'addresses'}
          title={''}
          address={a.structured_formatting.main_text}
          details={a.structured_formatting.secondary_text}
          id={a.place_id}
        />
      ))}
    </>
  )
}

async function AddNewAddressHandle(place_id, shippings, setNewAddressValue) {
  let place = await geocoderF(place_id)
  // const shippingsContains = checkContainsLocation(place.geometry.location,shippings);

  let nnaa = JSON.parse(JSON.stringify(place))

  let newAddress = {
    geometry: nnaa.geometry,
    name: nnaa.name || '',
    formatted_address: nnaa.formatted_address,
    place_id: nnaa.place_id,
    address_components: nnaa.address_components,
    // shippings: shippingsContains.map(s=>s.objectID)
  }

  setNewAddressValue(newAddress)
}

function geocoderF(place_id) {
  if (!window.geocoder) window.geocoder = new window.google.maps.Geocoder()
  return new Promise((resolve, error) => {
    window.geocoder.geocode({ placeId: place_id }, function (results, status) {
      if (status !== 'OK') {
        // window.alert('Geocoder failed due to: ' + status);
        error()
        return
      }
      // console.log(results[0]);
      resolve(results[0])
    })
  })
}
