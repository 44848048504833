import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { faMinusCircle, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import { makeStyles } from '@material-ui/core/styles'
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant'
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { useCartContext, useCartDataSetContext } from '../../context/CartContext'
import { useUserData, useUserDataSet } from '../../context/UserContext'
import { FieldValue } from '../../firebase/database'
import LoginModal from '../loginModal/Modal'


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 300,
        margin: 'auto',
        zIndex: 1
    },
    addCart: {
        textTransform: 'none',
        width: '100%',
        height: 41,
        whiteSpace: 'nowrap',
        fontWeight: '500',
        margin: '0 10px 10px 10px',
        backgroundColor: '#C3E6E6',
        border: 'none',
        color: 'black',
        '&:hover': {
            backgroundColor: '#5bb2b5'
        }
    },
    addCartOnlyBigScreen: {
        marginLeft: '4px',
        [theme.breakpoints.down(350)]: {
            display: 'none',
        }
    },
    btnBuyed: {
        'borderRadius': '10px',
        // "backgroundColor": "#ffc80a",
        'border': 'none',
        height: 41,
        color: '#fff',
        backgroundColor: '#4A4A4A',
        'textAlign': 'center',
        'fontSize': '20px',
        'padding': '6px',
        'width': '100%',
        'transition': 'all 0.5s',
        margin: '0 0 10px 0',
        'fontWeight': '500',
        'display': 'table',
        'tableLayout': 'fixed',
        'borderColor': '#0275d8',
        'borderWidth': 'thin',
        'borderStyle': 'solid',
        '& > div': {
            'display': 'table-cell',
            'verticalAlign': 'top',
            'textAlign': 'center'
        }
    },
    nostockContainer: {
        'display': 'flex',
        'flexDirection': 'row',
        'justifyContent': 'center',
        'gap': '10px',
        'alignItems': 'center',
        'width': '100%',
    },
    nostockNotification: {
        'display': 'block',
        'backgroundColor': '#fcf8e3',
        'color': '#8a6d3b',
        'width': '100%',
        'borderColor': '#faebcc',
        'padding': '8px',
        'border': '1px solid transparent',
        'borderRadius': '15px',
        'textAlign': 'center',
        'marginBottom': '5px',
        'transition': 'all 0.3s ease-in-out',
        '&:hover': {
            'cursor': 'pointer',
            'backgroundColor': '#ede4b4',
            'color': '#525252',
        }
    },
    nostockNotificationQuit: {
        'display': 'block',
        'backgroundColor': '#c888835c',
        'color': '#7e5855',
        'width': '100%',
        'padding': '8px',
        'border': '1px solid transparent',
        'borderRadius': '15px',
        'textAlign': 'center',
        'marginBottom': '5px',
        'transition': 'all 0.3s ease-in-out',
        '&:hover': {
            'cursor': 'pointer',
            'backgroundColor': '#791a1378',
            'color': '#fafafa',
        }
    },
    nostock: {
        'backgroundColor': '#fdfdfb',
        'color': '#8a6d3b',
        'borderColor': '#faebcc',
        'padding': '8px',
        'border': '1px solid transparent',
        'borderRadius': '15px',
        'width': '100%',
        'textAlign': 'center',
        'alignContent': 'center',
        // "height": "41px",
        // "marginBottom": "10px",
    },
    loading: {
        'backgroundColor': '#b2b2b0',
        'color': '#090808',
        'borderColor': '#2f2e2b',
        'padding': '8px',
        'border': '1px solid transparent',
        'borderRadius': '15px',
        'width': '100%',
        'textAlign': 'center',
        'height': '41px',
        'marginBottom': '10px',
    },
    addCartButton: {
        cursor: 'pointer',
    },
    productStock: {
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
        'marginTop': '0',
        'marginBottom': '5px',
        // "fontWeight": "600",
        // color red warning
        'fontSize': '0.75rem',
        'color': theme.palette.error.main,
        'textAlign': 'center',
    }
}))

export default function ProductAggregationDetail({ product, onlyActions, style = {} }) {

    const classes = useStyles()

    const { isAnonymous, letmeknow } = useUserData()

    const userDataSet = useUserDataSet()

    const prevAlerts = !!letmeknow?.[product.objectID]

    const [canAdd, setCanAdd] = useState(true)

    const handleFirstAdd = () => {
        cartUpdate({ product: product, q: 1 })
    }

    const stockHandler = () => isAnonymous === false && userDataSet({
        algoliaUpdated: false,
        ['letmeknow.' + product.objectID]: prevAlerts ? FieldValue.delete() : true
    }, 'update')

    const cart = useCartContext()
    const inCart = ((cart && product && cart[product.objectID] && cart[product.objectID].q) || 0)
    const cartUpdate = useCartDataSetContext()

    useEffect(() => {

    }, [cart[product.objectID?.q]])

    useEffect(() => {
        setCanAdd(true)
    }, [product.objectID])


    const handleAvailableStock = (qty) => {
        if (product.available) {
            if (product.available >= qty) {
                setCanAdd(true)
            } else {
                setCanAdd(false)
            }
        }
    }

    const actions = product.objectID ? !product.nostock ?

        !inCart
            ? <Button onClick={handleFirstAdd}
                className={classNames([classes.addCart, 'fillAvailable'])}
                variant={'outlined'}>Agregar al carrito</Button>
            :

            <div style={style}
                className={classNames([classes.btnBuyed, 'fillAvailable'])}>
                {inCart > 1 ? <div onClick={() => cartUpdate({ product: product, q: inCart - inCart })}
                    className={classes.addCartButton}><FontAwesomeIcon icon={faTimesCircle} />
                </div> : null}
                <div onClick={() => {
                    cartUpdate({ product: product, q: inCart - 1 }), handleAvailableStock(inCart - 1)
                }}
                    className={classes.addCartButton}><FontAwesomeIcon icon={inCart === 1 ? faTrash : faMinusCircle} />
                </div>
                <div>{inCart}</div>
                {canAdd ? <div onClick={() => {
                    cartUpdate({ product: product, q: inCart + 1 }), handleAvailableStock(inCart + 1)
                }}
                    className={classes.addCartButton}><FontAwesomeIcon icon={faPlusCircle} /></div>
                    : <div style={{ opacity: '0.5' }}
                        className={classes.addCartButton}><FontAwesomeIcon
                            icon={faPlusCircle} /></div>}

            </div> :
        <div className={classes.nostockContainer}>
            {isAnonymous ?
                <LoginModal fullWidth
                    style={{ textAlign: 'center' }}
                    text={<div className={classes.nostockNotification}>AGOTADO AVISARME
                        STOCK <NotificationImportantIcon style={{ fontSize: '18px', paddingTop: '3px' }} />
                    </div>} />
                :
                prevAlerts ?
                    <>
                        <button className={classes.nostockNotificationQuit}
                            onClick={() => stockHandler(product.objectID)}>AGOTADO <br />QUITAR AVISO DE
                            STOCK <NotificationsOffIcon style={{ fontSize: '18px', paddingTop: '3px' }} /></button>
                    </>
                    :
                    <>
                        <button className={classes.nostockNotification}
                            onClick={() => stockHandler(product.objectID)}>AGOTADO <br />AVISARME
                            STOCK <NotificationImportantIcon style={{ fontSize: '18px', paddingTop: '3px' }} /></button>
                    </>

            }

        </div>
        : <div className={classes.loading}></div>

    if (onlyActions) return actions

    return (<>
        {!canAdd && product.available && <span
            className={classes.productStock}>Sólo hay {product.available} unidad{product.available > 1 && 'es'} disponible{product.available > 1 && 's'}</span>}
        {!product.autoItem &&
            <CardActions disableSpacing
                onClick={e => {
                    e.preventDefault()
                }}>
                {actions}
            </CardActions>
        }
    </>
    )
}
