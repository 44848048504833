import { faMinusCircle, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { useCartContext, useCartDataSetContext } from '../../context/CartContext'

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 300,
        zIndex: 1,
        margin: 'auto',
        position: 'relative'
    },
    addCart: {
        textTransform: 'none',
        width: '100%',
        margin: '0 10px 10px 10px',
        height: 41,
        whiteSpace: 'nowrap',
        fontWeight: '500',
        backgroundColor: 'black',
        border: 'none',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#3d3d3d'
        }
    },
    addCartOnlyBigScreen: {
        marginLeft: '4px',
        [theme.breakpoints.down(350)]: {
            display: 'none',
        }
    },
    btnBuyed: {
        'borderRadius': '10px',
        // "backgroundColor": "#ffc80a",
        'border': 'none',
        height: 41,
        color: '#fff',
        backgroundColor: '#4A4A4A',
        'textAlign': 'center',
        'fontSize': '20px',
        'padding': '6px',
        'width': '100%',
        'transition': 'all 0.5s',
        margin: '0 0 10px 0',
        'fontWeight': '500',
        'display': 'table',
        'tableLayout': 'fixed',
        'borderColor': '#0275d8',
        'borderWidth': 'thin',
        'borderStyle': 'solid',
        '& > div': {
            'display': 'table-cell',
            'verticalAlign': 'top',
            'textAlign': 'center'
        }
    },
    nostockContainer: {
        'display': 'flex',
        'flexDirection': 'row',
        'justifyContent': 'center',
        'gap': '10px',
        'alignItems': 'center',
        'width': '100%',
    },
    nostockNotification: {
        'display': 'block',
        'backgroundColor': '#fcf8e3',
        'color': '#8a6d3b',
        'width': '100%',
        'borderColor': '#faebcc',
        'padding': '8px',
        'border': '1px solid transparent',
        'borderRadius': '15px',
        'textAlign': 'center',
        'marginBottom': '5px',
        'transition': 'all 0.3s ease-in-out',
        '&:hover': {
            'cursor': 'pointer',
            'backgroundColor': '#ede4b4',
            'color': '#525252',
        }
    },
    nostockNotificationQuit: {
        'display': 'block',
        'backgroundColor': '#c888835c',
        'color': '#7e5855',
        'width': '100%',
        'padding': '8px',
        'border': '1px solid transparent',
        'borderRadius': '15px',
        'textAlign': 'center',
        'marginBottom': '5px',
        'transition': 'all 0.3s ease-in-out',
        '&:hover': {
            'cursor': 'pointer',
            'backgroundColor': '#791a1378',
            'color': '#fafafa',
        }
    },
    nostock: {
        'backgroundColor': '#fdfdfb',
        'color': '#8a6d3b',
        'borderColor': '#faebcc',
        'padding': '8px',
        'border': '1px solid transparent',
        'borderRadius': '15px',
        'width': '100%',
        'textAlign': 'center',
        'alignContent': 'center',
        // "height": "41px",
        // "marginBottom": "10px",
    },
    loading: {
        'backgroundColor': '#b2b2b0',
        'color': '#090808',
        'borderColor': '#2f2e2b',
        'padding': '8px',
        'border': '1px solid transparent',
        'borderRadius': '15px',
        'width': '100%',
        'textAlign': 'center',
        'height': '41px',
        'marginBottom': '10px',
    },
    addCartButton: {
        cursor: 'pointer',
    }
}))

export default function ProductAggregationPack({ product, onlyActions, style = {}, setOpen }) {

    const [canAdd, setCanAdd] = useState(true)

    const classes = useStyles()
    const handleFirstAdd = () => {
        cartUpdate({ product: product, q: product.pack })
    }

    const cart = useCartContext()
    const inCart = ((cart && product && cart[product.objectID] && cart[product.objectID].q) || 0)
    const cartUpdate = useCartDataSetContext()

    useEffect(() => {
        if (!inCart)
            setOpen(true)

    }, [inCart])

    const handleAvailableStock = (qty) => {
        // product.available ? setAvailableStock(product.available) : setAvailableStock(0)
        if (product.available) {
            if (product.available > qty) {
                setCanAdd(true)
            } else {
                setCanAdd(false)
            }
        }
    }

    const actions = product.objectID ? !product.nostock ?
        !inCart || ((cart[product.objectID]?.q || 0) < product.pack)
            ? <Button onClick={handleFirstAdd}
                className={classNames([classes.addCart, 'fillAvailable'])}
                variant={'outlined'}>Comprar por pack</Button>
            :
            (cart[product.objectID]?.q || 0) < product.pack &&
            <div style={style}
                className={classNames([classes.btnBuyed, 'fillAvailable'])}>
                {

                    <div onClick={() => { cartUpdate({ product: product, q: inCart - product.pack }); handleAvailableStock(inCart - product.pack) }}
                        className={classes.addCartButton}>
                        <FontAwesomeIcon icon={inCart === 1 ? faTrash : faMinusCircle} />
                    </div>
                }
                <div>{inCart}</div>

                {
                    canAdd ?
                        <div onClick={() => {
                            cartUpdate({ product: product, q: inCart + product.pack }),
                            handleAvailableStock(inCart + product.pack)
                        }}
                            className={classes.addCartButton}>
                            <FontAwesomeIcon icon={inCart === 1 ? faTrash : faPlusCircle} />
                        </div> :
                        <div className={classes.addCartButton}>
                            <FontAwesomeIcon icon={inCart === 1 ? faTrash : faPlusCircle} />
                        </div>
                }

            </div> :
        <div className={classes.nostockContainer}>
        </div>
        : <div className={classes.loading}> </div>

    if (onlyActions) return actions

    return (<> {!product.autoItem &&
        <CardActions disableSpacing
            onClick={e => {
                e.preventDefault()
            }}>
            {actions}
        </CardActions>
    }
    </>
    )
}
