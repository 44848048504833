import { Box, FormControl, FormHelperText, TextField } from '@material-ui/core'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import useIntersection from '../../../components/Utils/useIntersection'
import { useUserDataSet } from '../../../context/UserContext'
import { AddressesTemplate } from '../address'
import useStylesAdress from '../address/styles'
import { MenuFacturacion } from '../inputFacturacion/menuFacturacion'
import PaymentOptionTemplate from '../payment/PaymentOptionTemplate'
import { ShipmentBox } from '../shipoptionselect'
import useStylesShipment from '../shipoptionselect/styles'
import { CommentBox, DiscountCodeBox } from './DiscountCode'


export function OrderDetails({
    addressee,
    subtotal_plus_iva,
    paymentMethod,
    shipping,
    discounts,
    priceList,
    iva,
    subtotal,
    shipment,
    address,
    store,
    isStore,
    classes,
    setInViewPort,
    paymentOptions,
    setAddressee
}) {

    const classes_shipment = useStylesShipment()
    const classes_address = useStylesAdress()

    const ref = useRef()
    const inViewport = useIntersection(ref, '0px')

    useEffect(() => {
        inViewport && setInViewPort?.(true)
    }, [inViewport])

    const history = useHistory()

    const userUpdate = useUserDataSet()


    return <div>
        <h3 style={{ fontSize: '1.7rem' }}>Detalles de la entrega</h3>
        <Box className={classes.personName}
            display={'flex'}
            alignItems='center'
            marginBottom={1}
            borderRadius={8}>
            <Box style={{
                width: '70px',
                height: '80px',
                display: 'flex',
                fontSize: '24px',
                boxSizing: 'border-box',
                lineHeight: '80px',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <PersonOutlineIcon fontSize='large' />
            </Box>
            <FormControl className={classes.formAddressee}>
                <TextField aria-describedby="my-helper-text"
                    value={addressee}
                    label={'Recibe'}
                    error={!addressee}
                    onChange={(e) => setAddressee(e.target.value)}
                    onBlur={() => addressee && userUpdate({ addressee }, 'update')} />
                {!addressee && <FormHelperText error={!addressee}>El nombre es requerido</FormHelperText>}
            </FormControl>
        </Box>
        <AddressesTemplate onClick={() => history.push('/app/buy/addressselect')}
            classes={classes_address}
            collection={isStore ? 'store' : 'addresses'}
            title={isStore ? store?.storeName : address?.title}
            address={address?.formatted_address}
            details={address?.details}
            id={address?.id || store?.id} />
        {shipment && ShipmentBox({
            shipping,
            classes: classes_shipment,
            total: subtotal - discounts + (priceList.iva_included ? iva : 0),
            priceList,
            next_shipment: shipment
        })}
        {/*{isStore && !shippingsMatch?.length ? <CarrierTemplate carriersFromOrder={carriersFromOrder} hits={hits || {}} />:<></>}*/}
        {/* Descomentar las lineas para tener store.hours (objeto) */}
        {/*{isStore?//&&user?.priceList?.v !== employeePriceList?*/}
        {/*<Box className={classes.personName} display={'flex'} alignItems='center' marginBottom={1} borderRadius={8}>*/}
        {/*        <AccessTimeIcon fontSize='large'/>*/}
        {/*    </Box>*/}
        {/*    <div onClick={handleOpen} className={classes.formAddressee}>*/}
        {/*            <h3>Cambiar Horarios</h3>*/}
        {/*        <Modal*/}
        {/*            aria-labelledby="transition-modal-title"*/}
        {/*            aria-describedby="transition-modal-description"*/}
        {/*            className={classes.modal}*/}
        {/*            open={open}*/}
        {/*            onClose={handleClose}*/}
        {/*            closeAfterTransition*/}
        {/*            BackdropComponent={Backdrop}*/}
        {/*            BackdropProps={{*/}
        {/*            timeout: 500,*/}
        {/*            }}*/}
        {/*        >*/}
        {/*            <Fade in={open}>*/}
        {/*            <div className={classes.paper}>*/}
        {/*                <SchedulesMap storeID={store.id}></SchedulesMap>*/}
        {/*            </div>*/}
        {/*            </Fade>*/}
        {/*        </Modal>*/}
        {/*        {!hours && <FormHelperText error={!hours}>Debe agregar los horarios en que esta abierto el comercio.</FormHelperText>}*/}
        {/*    </div>*/}
        {/*</Box>:<></>}*/}
        <CommentBox />
        <div ref={ref} />
        <h3 style={{ fontSize: '1.7rem' }}>Método de pago</h3>
        {!paymentMethod &&
            <FormHelperText error={!paymentMethod}>Debe seleccionar un método de pago</FormHelperText>}
        <PaymentOptionTemplate {...{ paymentMethod, onClick: () => paymentOptions.length > 1 && history.push('/app/buy/payselect') }} />
        {priceList.discountCodes && <Box className={classes.personName}
            borderRadius={8}>
            <DiscountCodeBox
                order={{ subtotal: subtotal_plus_iva }} /></Box>}
        {!isStore && <MenuFacturacion />}
    </div>

}
