import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import React from 'react'
import {
    Route,
    Switch,
    useHistory, useLocation
} from 'react-router-dom'

import { HideSearchBar } from '../../../context/LayoutContext'
import { useProcessDispatch, useProcessState } from '../../../context/ProcessContext'
import useStyles from './styles'

export default function Takeaway() {

    const classes = useStyles()
    const history = useHistory()
    let location = useLocation()
    const processDispatch = useProcessDispatch()
    const ProcessState = useProcessState()
    const vivetDirection = ({
        'address': {
            'geometry': {
                'location': {
                    'lat': -34.8780255,
                    'lng': -58.61616149999999
                },
                'location_type': 'ROOFTOP',
                'viewport': {
                    'south': -34.8791640802915,
                    'west': -58.6176747302915,
                    'north': -34.8764661197085,
                    'east': -58.61497676970849
                }
            },
            'name': '',
            'formatted_address': 'Puente del Inca 2050, B1812 Carlos Spegazzini, Provincia de Buenos Aires, Argentina',
            'place_id': 'ChIJJRVOVhLbvJURstSMf2dFNs0',
            'address_components': [
                {
                    'long_name': '2050',
                    'short_name': '2050',
                    'types': [
                        'street_number'
                    ]
                },
                {
                    'long_name': 'Puente del Inca',
                    'short_name': 'Puente del Inca',
                    'types': [
                        'route'
                    ]
                },
                {
                    'long_name': 'Carlos Spegazzini',
                    'short_name': 'Carlos Spegazzini',
                    'types': [
                        'locality',
                        'political'
                    ]
                },
                {
                    'long_name': 'Ezeiza',
                    'short_name': 'Ezeiza',
                    'types': [
                        'administrative_area_level_2',
                        'political'
                    ]
                },
                {
                    'long_name': 'Provincia de Buenos Aires',
                    'short_name': 'Provincia de Buenos Aires',
                    'types': [
                        'administrative_area_level_1',
                        'political'
                    ]
                },
                {
                    'long_name': 'Argentina',
                    'short_name': 'AR',
                    'types': [
                        'country',
                        'political'
                    ]
                },
                {
                    'long_name': 'B1812',
                    'short_name': 'B1812',
                    'types': [
                        'postal_code'
                    ]
                }
            ]
        }
    })

    ProcessState.newAddress && location.pathname === '/app/buy/takeaway' && (setTimeout(() => processDispatch({ newAddress: undefined, address: undefined, shippings: undefined }), 300))

    HideSearchBar()

    return (

        <div className={classes.root}>
            <Button onClick={() => history.goBack()}><ArrowBackIcon /> Volver</Button>
            <div className={classes.title}><Typography variant={'h3'}
                align={'center'} >Quiere retirar su producto en el deposito?</Typography></div>
            <Switch>
                <Route exact
                    path="/app/buy/takeaway">
                    <div className={classes.location_list__add}>
                        {
                            <div style={{ display: 'flex' }}>
                                <Button startIcon={<FontAwesomeIcon icon={faPlusCircle}
                                    style={{ minHeight: '6vh' }} />}
                                    onClick={() => { processDispatch({ takeaway: true, ...vivetDirection }); history.push('/app/buy/payselect') }}
                                    variant="contained"
                                    color="secondary">Retirar en el depósito</Button>
                                <Button startIcon={<FontAwesomeIcon icon={faPlusCircle}
                                    style={{ minHeight: '6vh' }} />}
                                    onClick={() => { processDispatch({ takeaway: false }); history.push('/app/buy/addressselect') }}
                                    variant="contained"
                                    color="secondary">Eligir una dirección de envío</Button>
                            </div>
                        }

                    </div>
                </Route>
            </Switch>
        </div>
    )
}