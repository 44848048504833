import queryString from 'query-string'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'


export default function AddParam({ urlListener = '' } = {}) {
    let history = useHistory()
    let location = useLocation()
    const search = location.search
    const [parsed, setParsed] = useState(queryString.parse(search))

    useEffect(() => {
        if (!urlListener || urlListener === location.pathname) setParsed(queryString.parse(search))
    }, [location, urlListener, search])

    function addParam(param, value) {
        if (typeof param === 'object') {
            if (!shallowEqual(removeEmpty({ ...param }), parsed)) value && value.replace ?
                history.replace('/app/products?' + queryString.stringify(removeEmpty({ ...parsed, ...param })))
                : history.push('/app/products?' + queryString.stringify(removeEmpty({ ...parsed, ...param })))
        }
        else if (parsed[param] !== value && (parsed[param] || value)) {
            value ? (parsed[param] = value) : (parsed[param] && (delete parsed[param]))
            history.push('/app/products?' + queryString.stringify(parsed))
        }
    }

    return { parsed, addParam }

}

const removeEmpty = (obj) => {
    Object.keys(obj).forEach(key => {
        if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key])
        else if (!obj[key]) delete obj[key]
    })
    return obj
}

function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)

    if (keys1.length !== keys2.length) {
        return false
    }

    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            return false
        }
    }

    return true
}
