import { IconButton, Menu, MenuItem } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {
  ContactMailOutlined as ContactMailIcon,
  PersonOutlined as AccountIcon,
  ShoppingBasketOutlined as ShoppingBasketIcon,
} from '@material-ui/icons'
import classNames from 'classnames'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { signOut } from '../../../context/UserContext'
import { Typography } from '../../Wrappers/Wrappers'
import useStyles from '../styles'
import Favorites from './Favorites'
const Profile = () => {
  const classes = useStyles()
  const [profileMenu, setProfileMenu] = useState(null)
  let history = useHistory()
  return (
    <>
      <IconButton
        aria-haspopup="true"
        color="inherit"
        className={classes.headerMenuButton}
        aria-controls="profile-menu"
        onClick={(e) => setProfileMenu(e.currentTarget)}
      >
        <AccountIcon classes={{ root: classes.headerIcon }} />
      </IconButton>

      <Menu
        id="profile-menu"
        open={Boolean(profileMenu)}
        anchorEl={profileMenu}
        onClose={() => setProfileMenu(null)}
        className={classes.headerMenu}
        classes={{ paper: classes.profileMenu }}
        disableAutoFocusItem
      >
        <div className={classes.profileMenuUser}>
          <Typography variant="h4"
            weight="medium">
            {name || 'Mi Usuario'}
          </Typography>
        </div>
        <MenuItem
          className={classNames(
            classes.profileMenuItem,
            classes.headerMenuItem
          )}
          onClick={() => {
            history.push('/app/profile')
            setProfileMenu(null)
          }}
        >
          <ContactMailIcon className={classes.profileMenuIcon} />
          <Typography style={{ color: '#333' }}> Mis Datos</Typography>
        </MenuItem>
        <MenuItem
          className={classNames(
            classes.profileMenuItem,
            classes.headerMenuItem
          )}
          onClick={() => {
            history.push('/app/myorders')
            setProfileMenu(null)
          }}
        >
          <ShoppingBasketIcon className={classes.profileMenuIcon} />
          <Typography style={{ color: '#333' }}>Mis Compras</Typography>
        </MenuItem>
        <Favorites classes={classes} />
        <div>
          <Button onClick={() => signOut()}
            className={classes.profileMenuLink}>
            <Typography>Cerrar Sesión</Typography>
          </Button>
        </div>
      </Menu>
    </>
  )
}

export default Profile
