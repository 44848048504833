import { faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CircularProgress, Icon, Modal } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CheckIcon from '@material-ui/icons/Check'
import DownloadIcon from '@material-ui/icons/GetApp'
import PrintIcon from '@material-ui/icons/Print'
import ViewButton from '@material-ui/icons/Visibility'
import { makeStyles } from '@material-ui/styles'
import { ajax } from 'can'
import printJS from 'print-js'
import React, { useEffect, useState } from 'react'

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    width: '80%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 2),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  iframe: {
    // height: '-webkit-fill-available',
    height: '100vh',
    width: '100%'
  }
}))


export default function DriveFileViewButtonModal({ driveFileId }) {

  const classes = useStyles()
  const [open, setOpen] = React.useState(false)


  const handleOpen = (e) => {
    e.stopPropagation()
    setOpen(true)
  }

  const handleClose = (e) => {
    e.stopPropagation()
    setOpen(false)
  }

  return (
    <>
      {/*<Button variant="contained" size="small" color="primary" onClick={handleOpen}>Ver Archivo</Button>*/}
      <IconButton onClick={handleOpen}>
        <ViewButton />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className={classes.modal}>
          <iframe title={driveFileId}
            src={`https://drive.google.com/file/d/${driveFileId}/preview`}
            className={classes.iframe} />
        </div>
      </Modal>
    </>
  )
}

const DriveFilePrintButton = ({ driveFileId }) => {
  const [loading, setLoading] = useState(false)

  const handleClick = async (e) => {
    e.stopPropagation()
    setLoading(true)

    await ajax({
      crossDomain: true,
      url: 'https://script.google.com/macros/s/AKfycbwov3pdmHC2B3rXfoD49jO9Qxxl-QBYVsz_wRM_4GCYCPt5Gp0u/exec?id=' + driveFileId,
      type: 'GET'
    }).then(r => r.response).then(base64 => printJS({ printable: base64, type: 'pdf', base64: true }))

    setLoading(false)
  }

  return <>
    <IconButton onClick={handleClick}
      aria-label="print"
      disabled={loading}>
      <PrintIcon />
    </IconButton>
    {loading && <CircularProgress />}
  </>
}

const DriveFileDownloadButton = ({ driveFileId, invoice }) => {
  const [loading, setLoading] = useState(false)
  const [downloaded, setDownloaded] = useState(false)

  useEffect(() => setDownloaded(false), [driveFileId])

  const handleClick = async (e) => {
    e.stopPropagation()
    setLoading(true)

    // window.open('https://drive.google.com/uc?export=download&id='+driveFileId);
    const file = await ajax({
      crossDomain: true,
      url: 'https://script.google.com/macros/s/AKfycbyHDAvdN_yK5Ol-e13XVLzeOmMtQDncU1DFz3cYKwjROXr3KOS_d1e17bJr0ZW_3FK3/exec?id=' + driveFileId,
      type: 'GET'
    })
    setDownloaded(true)

    downloadBase64File(file)

    setLoading(false)
  }

  return <>
    {downloaded ? <Icon>
      <CheckIcon />
    </Icon>
      :
      <IconButton onClick={handleClick}
        aria-label="download"
        disabled={loading}>
        {invoice ? <FontAwesomeIcon icon={faFileInvoice} /> : <DownloadIcon />}
      </IconButton>
    }
    {loading && <CircularProgress />}
  </>
}

function downloadBase64File({ base64, filename, mimetype }) {
  const linkSource = `data:${mimetype};base64,${base64}`
  const downloadLink = document.createElement('a')
  const fileName = filename

  downloadLink.href = linkSource
  downloadLink.download = fileName
  downloadLink.click()
  downloadLink.remove()
}


export { DriveFileDownloadButton, DriveFilePrintButton }
