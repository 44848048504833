import { Box, Fade, Modal, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useState } from 'react'

import { useSelectedAddress } from '../../context/UserContext'
import { Addresses, AddressesTemplate } from '../../pages/orderProcess/address'
import ConfirmNewAddress from '../../pages/orderProcess/address/NewAddress/ConfirmNewAddress'
import NewAddress from '../../pages/orderProcess/address/NewAddress/NewAddress'


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // minHeight: '400px',
    maxHeight: '70vh',
    overflow: 'auto',
    width: 400,
    '@media (max-width: 400px)': {
        width: 350
    },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

function ModalUbicacion({ handleClose }) {
    const [newAdress, setNewAdress] = useState(false)
    const [openConfirmAddress, setOpenConfirmAddress] = useState(false)
    const { setSelectedAddress } = useSelectedAddress()

    const classes = useStylesAddressTemplate()

    const handleOpenConfirmAddress = (addr) => {
        setNewAdress(addr)
        setOpenConfirmAddress(true)
    }

    return (<Modal
        open={true}
        onClose={handleClose}
    >
        <Fade in={open}
            style={{ transformOrigin: 'center' }}>
            <Box sx={{...style, minHeight: '615px'}}>
                <Typography variant="h5"
                    component="h2">
                    Elige tu ubicación
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    Selecciona una dirección de envío en Argentina para ver productos y las opciones de envío.
                </Typography>
                <div className={classes.inputContainer}>
                    {openConfirmAddress &&
                        <ConfirmNewAddress
                            close={() => setOpenConfirmAddress(false)}
                            isPushDisable
                            {...{ addressDispatch: ({id})=>{setSelectedAddress(id); handleClose()}, address: newAdress }}
                        />}
                    {!openConfirmAddress && <NewAddress setNewAddressValue={handleOpenConfirmAddress}
                        autoFocus={false}
                        label={'Direccion'}
                        Template={props => AddressesTemplate({ ...props, classes })} />}
                </div>
                {!openConfirmAddress &&
                    <Addresses Template={props => AddressesTemplate({ ...props, onClick: () => {
                        setSelectedAddress(props?.hit?.id)
                        handleClose()
                      }, classes })} />}
                {/*<div style={{ display: 'flex', justifyContent: 'space-between'}}>*/}
                {/*    <Button variant="outlined" onClick={handleClose}>*/}
                {/*        Cancelar*/}
                {/*    </Button>*/}
                {/*    <Button variant="contained" onClick={handleClose}>*/}
                {/*        Confirmar*/}
                {/*    </Button>*/}
                {/*</div>*/}
            </Box>
        </Fade>
    </Modal>
    )
}

const useStylesAddressTemplate = makeStyles(theme => ({

    'root': {
        'width': '75%',
        'maxWidth': '390px',
        'margin': 'auto',
        'marginBottom': '70px',
        'WebkitBoxShadow': '0 11px 18px -4px #777',
        'MozBoxShadow': '0 11px 18px -4px #777',
        'boxShadow': '0 11px 18px -4px #777',
        'padding': '1rem',
        [theme.breakpoints.down('sm')]: {
            'width': '95%',
        }
    },
    inputContainer: {
        margin: '10px 0 10px 0',
    },
    'title': {
        'margin': '1rem 0',
    },
    'location_list': {
        // "height": "260px",
        // "overflowY": "scroll"
    },
    'location_list__location__selected': {},
    'location_list__location': {
        // "height": "80px",
        // "width": "70%",
        // "maxWidth": "360px",
        'border': '0px',
        'borderRadius': '10px',
        'margin': '0 auto 10px',
        'transition': '.3s',
        'cursor': 'pointer',
        'display': 'flex',
        'flexWrap': 'wrap',
        'boxShadow': '1px 1px 1px 1px rgba(0,0,0,0.2)',
        '&:hover': {
            'boxShadow': '0 2px 20px 0 rgba(0,0,0,.1)',
        }
    },
    'location_list__location__flag': {
        'display': 'flex',
        'width': '70px',
        'lineHeight': '80px',
        'height': '80px',
        'fontSize': '24px',
        'justifyContent': 'center',
        'alignItems': 'center',
        'boxSizing': 'border-box',
        '&> img': {
            'marginTop': '13px',
            'marginLeft': '10px',
            'height': '50px',
            'opacity': '0.6'
        }
    },
    'location_list__location__checkbox': {
        'display': 'flex',
        'width': '70px',
        'lineHeight': '80px',
        'height': '80px',
        'color': '#2abb9b',
        'fontSize': '24px'
    },
    'location_list__location__address': {
        'width': 'calc(100% - 70px)',
        'padding': '8px 8px 8px 0',
        'textAlign': 'left',
        'display': 'flex',
        'justifyContent': 'center',
        'flexDirection': 'column'
    },
    'location_list__location__address__details': {
        'color': '#9b9b9b',
        'margin': '5px 0'
    },
    'location_list__location__address__title': {
        'fontWeight': '600',
        'fontSize': '1rem',
    },
    'location_list__location__address__text': {
        'overflow': 'hidden',
        // "textOverflow": "ellipsis",
        // "whiteSpace": "nowrap",
        'fontSize': '1rem'
    },
    'location_list__add': {
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'center',
        'fontSize': '16px',
        'lineHeight': '1.33',
        'margin': '20px auto 20px',
        'cursor': 'pointer',
        'color': '#2abb9b',
        'fontWeight': '500',
        // "maxWidth": "80%",
        // "&:hover":{
        //     "opacity": "0.8"
        // }
    },
}))

export default ModalUbicacion
