import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core'

import { useUserData, useUserDataSet } from '../../../context/UserContext'

export const RadioFacturacion = ({ lastUsed, disabled }) => {
  const userData = useUserData()
  const userDataSet = useUserDataSet()

  const handleChangeKey = (e) => {
    userDataSet({ ['invoiceData.' + (e.target.value || 0) + '.lastUsed']: new Date() / 1000 }, 'update')
  }

  return (
    <>
      <Typography style={{ fontSize: '1.2rem' }}>Datos de facturacion</Typography>
      {!disabled && (
        <Box style={{ display: 'flex' }}>
          <RadioGroup
            defaultValue={lastUsed}
            aria-label="cuit"
            name="cuit"
            onChange={handleChangeKey}
          >
            {Object.entries(userData?.invoiceData || {}).map(([key, value]) => (
              <>
                <FormControlLabel
                  value={key}
                  control={<Radio />}
                  label={value.cuit}
                />
                <Typography style={{ fontSize: '.8rem' }}>{value.nombre}</Typography>
                <Typography style={{ fontSize: '.8rem' }}>{value.direccionFacturacion}</Typography>
              </>
            ))}
          </RadioGroup>
        </Box>
      )}
    </>
  )
}
