import React, { useState } from 'react'

import { useMarketContext } from './MarketContext'
import { usePriceListIDContext } from './PriceListContext'
import { useUserData, useUserDataSet } from './UserContext'

const CartDataContext = React.createContext()
const CartDataSetContext = React.createContext()
const ExtraDiscountContext = React.createContext()
const ExtraDiscountSetContext = React.createContext()

function CartProvider({ children }) {
    const user = useUserData()
    const cart = Object.entries(user.cart || {}).filter(([, v]) => !!v?.q).reduce((p, [id, value]) => ({ ...p, [id]: value }), {})
    const userUpdate = useUserDataSet()
    const { seller, priceList } = useMarketContext()
    const priceLists = priceList
    const priceListID = usePriceListIDContext()

    const [extraDiscount, setExtraDiscount] = useState(0)

    const cartUpdate = ({ product, q }) => {
        if (priceLists) {
            const id = product.objectID
            const prevQ = cart[id] ? Number(cart[id].q) : 0
            // const packprice = getPrice({ product,priceLists,priceListID, packUnitPrice : true});
            // const unitprice = getPrice({ product,priceLists,priceListID });
            const pL = priceLists[priceListID]
            const multipleUnits = Math.max(
                // MinUnitsByProduct({product, priceListID, priceLists}) ,
                product.pack ? (
                    pL.buyByPack === 'always' ? product.pack
                        : pL.buyByPack === 'afterFirst' && q >= product.pack ? product.pack
                            : pL.buyByPack === 'onlyFirst' && q < product.pack ? product.pack
                                : 1
                ) : 1,
                product.pack ? (
                    product.buyByPack === 'always' ? product.pack
                        : product.buyByPack === 'afterFirst' && q >= product.pack ? product.pack
                            : product.buyByPack === 'onlyFirst' && q < product.pack ? product.pack
                                : 1
                ) : 1
                // packprice && packprice < unitprice && product.pack && q>=product.pack ? product.pack : 1
            )
            if (multipleUnits > 1) {
                if (!Number.isInteger(q / multipleUnits))
                    q = q >= prevQ ? (Math.trunc(q / multipleUnits) + 1) * (multipleUnits) : Math.trunc(q / multipleUnits) * (multipleUnits)
            }
            if (product.available && q > product.available) q = product.available
            if (q && prevQ && prevQ !== q) return userUpdate({ ['cart.' + id + '.q']: q }, 'update')
            if (prevQ && !q) {
                delete cart[id]
                return userUpdate({ cart }, 'update')
            }
            if (!prevQ) {
                window.dataLayer?.push({ ecommerce: null })  // Clear the previous ecommerce object.
                window.dataLayer?.push({
                    'event': 'addToCart',
                    'ecommerce': {
                        'currencyCode': 'ARS',
                        'add': {                                // 'add' actionFieldObject measures.
                            'products': [{                        //  adding a product to a shopping cart.
                                'name': product.name,
                                'id': id,
                                'quantity': q
                            }]
                        }
                    }
                })
                cart[id] = {
                    q: q,
                    s: seller,
                    timestamp: new Date()
                }
                // console.log({contents:Object.entries(cart).map(([id,value])=>({id, 'quantity': value.q}))});
                userUpdate({ cart: cart })
            }
        }
    }

    return <CartDataContext.Provider value={cart}>
        <CartDataSetContext.Provider value={cartUpdate}>
            <ExtraDiscountContext.Provider value={extraDiscount}>
                <ExtraDiscountSetContext.Provider value={setExtraDiscount}>
                    {children}
                </ExtraDiscountSetContext.Provider>
            </ExtraDiscountContext.Provider>
        </CartDataSetContext.Provider>
    </CartDataContext.Provider>
}


function useCartContext() {
    const context = React.useContext(CartDataContext)
    if (context === undefined) {
        throw new Error('CartStateContext must be used within a CartProvider')
    }
    return context
}

function useCartDataSetContext() {
    const context = React.useContext(CartDataSetContext)
    if (context === undefined) {
        throw new Error('CartDataSetContext must be used within a CartProvider')
    }
    return context
}

function useExtraDiscountContext() {
    const context = React.useContext(ExtraDiscountContext)
    if (context === undefined) {
        throw new Error('ExtraDiscountStateContext must be used within a ExtraDiscountProvider')
    }
    return context
}

function useExtraDiscountDataSetContext() {
    const context = React.useContext(ExtraDiscountSetContext)
    if (context === undefined) {
        throw new Error('ExtraDiscountDataSetContext must be used within a ExtraDiscountProvider')
    }
    return context
}


export { CartProvider, useCartContext, useCartDataSetContext, useExtraDiscountContext, useExtraDiscountDataSetContext }

/**
 * @return {number}
 */
// function MinUnitsByProduct({product,priceListID,priceLists}) {
//         if (!product.minUnits) return 1;
//         let pL = priceLists[priceListID];
//         return product.minUnits[priceListID] || ( pL.mother ? product.minUnits[pL.mother] : product.minUnits['g'] )  || 1;
//     }
