// const env = 'consumidor'
const env = 'mayorista'

const consumidor = {
  logo: '/images/plant_logo.png',
  plant_web_view: true,
  defaultPriceList: 'g',
  out_of_the_zone_notification: true,
  title: 'Plant',
  defaultShipping: 'me2',
  onlyDry: true
}

const mayorista = {
  logo: '/images/business.webp',
  title: 'Plant Business',
  phoneNumber: '5491130159584',
  show_prices_only_validated_store: true,
  stores: true,
  plant_web_view: false,
  defaultPriceList: 'd',
  out_of_the_zone_notification: false,
}

const VARIABLES = {
  mayorista,
  consumidor,
}

export default VARIABLES[env]
