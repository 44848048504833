import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    root: {
        margin: 'auto',
        maxWidth: 1000,
        position: 'sticky',
        top: 40
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    listWrap: {
        //display: 'flex', overflow: 'hidden', maxWidth: 900, margin: 'auto', position:'relative', left:'200px'}
        display: 'flex',
        overflow: 'hidden',
        maxWidth: 900,
        margin: 'auto',
        position: 'relative',
        left: '150px',
        [theme.breakpoints.down('md')]: {
            maxWidth: 700,
        },
        '@media (min-width:1520px)': {
            left: '0px',
        },
        '@media (max-width:1023px)': {
            position: 'static'
        },
        '@media (max-width:600px)': {
            marginTop: '18vh',
        },
        '@media (max-width:550px)': {
            marginTop: '20vh',
        },
        '@media (max-width:500px)': {
            marginTop: '22vh',
        },
        '@media (max-width:450px)': {
            marginTop: '24vh',
        },
        '@media (max-height:400px)': {
            marginTop: '26vh',
        },
        '@media (max-width:350px)': {
            marginTop: '28vh',
        },
        '@media (max-width:300px)': {
            marginTop: '30vh',
        },
    },
    cart: {
        position: 'fixed',
        textWrap: 'nowrap',
        right: '0',
        maxWidth: '20vw',
        zIndex: 2,
        '@media (min-width:1600px)': {
            right: '2vw',
        },
        '@media (min-width:1650px)': {
            right: '2.5vw',
        },
        '@media (min-width:1700px)': {
            right: '3vw',
        },
        '@media (min-width:1750px)': {
            right: '3.5vw',
        },
        '@media (min-width:1800px)': {
            right: '4vw',
        },
        '@media (min-width:1850px)': {
            right: '4.5vw',
        },
        '@media (min-width:1900px)': {
            right: '5vw',
        },
    },
    yellowBackground: {
        position: 'relative',
        padding: '8px 8px 32px 8px',
        background: 'rgba(0,0,0,0.1)',
        // background: "#dddccf",
        width: '94%',
        margin: '18px',
        borderRadius: '20px',
        '&::after': {
            background: 'linear-gradient(-45deg, #ffffff 16px, transparent 0), linear-gradient(45deg, #ffffff 16px, transparent 0)',
            backgroundPosition: 'left-bottom',
            backgroundRepeat: 'repeat-x',
            backgroundSize: '32px 32px',
            content: '\'\'',
            display: 'block',
            position: 'absolute',
            bottom: '0px',
            left: '0px',
            width: '100%',
            height: '32px',
        }
    },
    greyBackground: {
        position: 'relative',
        padding: '8px 8px 32px 8px',
        background: 'rgba(0,0,0,0.1)',
        // background: "#dddccf",
        width: '94%',
        margin: '5px',
        borderRadius: '20px',
        '&::after': {
            background: 'linear-gradient(-45deg, #ebebeb 16px, transparent 0), linear-gradient(45deg, #ebebeb 16px, transparent 0)',
            backgroundPosition: 'left-bottom',
            backgroundRepeat: 'repeat-x',
            backgroundSize: '32px 32px',
            content: '\'\'',
            display: 'block',
            position: 'absolute',
            bottom: '0px',
            left: '0px',
            width: '100%',
            height: '32px',
        }
    },

    yellowBackground750px: {
        backgroundColor: 'rgba(0,0,0,0.1)',
        boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.2)',
        border: '.2px solid rgba(0,0,0,0.1)',
        borderRadius: 5,
        fontSize: 15,
        paddingBottom: '65px'
    },
    dividerCart: {
        width: '90%',
        textAlign: 'center',
        margin: 'auto',
    },
    confirmButton: {
        'color': 'black',
        'borderRadius': '24px',
        'fontWeight': '700',
        'backgroundColor': '#C3E6E6',
        'boxShadow': '#16a085',
    },
    PaymentMessage: {
        margin: '1rem',
        textAlign: 'center',
    },
    PaymentMessageLogo: {
        height: '2rem',
    },
    confirmButtonElement: {
        textAlign: 'center',
    },
    confirmButtonElementCellphone: {
        textAlign: 'center',
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: '0px',
        zIndex: 1,
    },
    centerGridItem: {
        padding: 0,
        textAlign: 'center',
        // color: theme.palette.text.secondary,
    },
    title: {
        color: 'var(--helio-color-primaryText)',
        fontSize: '25px',
        fontWeight: 500,
        lineHeight: 1.3,
        marginLeft: '20px'
    },
    titleMobile: {
        textAlign: 'center',
        fontSize: '30px',
        fontWeight: 500,
    },
    leftGridItem: {
        paddingLeft: '2rem',
        textAlign: 'left',
        fontSize: '1.1rem',
        padding: '5px',
        paddingBottom: '10px'
        // color: theme.palette.text.secondary,
    },
    rightGridItem: {
        paddingRight: '2rem',
        textAlign: 'right',
        fontSize: '1.1rem',
        padding: '5px',
        paddingBottom: '10px'
        // color: theme.palette.text.secondary,
    },
    leftGridItemMobile: {
        paddingLeft: '2rem',
        textAlign: 'left',
        // color: theme.palette.text.secondary,
    },
    rightGridItemMobile: {
        paddingRight: '2rem',
        textAlign: 'right',
        // color: theme.palette.text.secondary,
    },

    comisionPaymentMessageLogo: {
        'height': '15px',
    },
    formAdresee: {
        'marginLeft': '10px'
    },
    personName: {
        'boxShadow': '1px 1px 1px 1px rgba(0,0,0,0.2)',
        '&:hover': {
            'boxShadow': '0 2px 20px 0 rgba(0,0,0,.1)',
        }
    },
    boxConfirmSeparator: {
        display: 'flex',
        flexDirection: 'row-reverse',
        [theme.breakpoints.down(1090)]: {
            flexDirection: 'column'
        }
    },
    formFactu: {
        'boxShadow': '1px 1px 1px 1px rgba(0,0,0,0.2)',
        '&:hover': {
            'boxShadow': '0 2px 20px 0 rgba(0,0,0,.1)',
        },
        display: 'flex',
        gap: 10
    },
    payButton: {
        backgroundColor: '#C3E6E6',
        borderRadius: 100,
        width: '14.5vw',
        textAlign: 'center',
        padding: '15px',
        fontWeight: 'bold',
        color: 'black',
        cursor: 'pointer'
    },
    cartWithoutProducts: {
        textWrap: 'balance',
        borderRadius: 50,
        width: '14.5vw',
        textAlign: 'center',
        padding: '10px',
        color: '#4A4A4A',
    },
    // ESTILOS DE DiscountCode.js
    form: {
        display: 'flex',
        minWidth: 240,
        [theme.breakpoints.down(340)]: {
            minWidth: 180
        },
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 2,
        paddingTop: '20px'
    },
    divider: {
        height: 28,
        margin: 4,
    },
    buttonComentt: {
        margin: 10,
        width: 264,
        backgroundColor: '#fff',
        boxShadow: '0 2px 0 rgba(35, 34, 35, 0.3)',
        '&:hover': {

            backgroundColor: '#ccc'
        },
    },
    formComment: {
        width: '80%',
        marginTop: 6
    },
    commentForm: {
        display: 'flex',
        marginBottom: 1,
        borderRadius: 8,
        'boxShadow': '1px 1px 1px 1px rgba(0,0,0,0.2)',
        '&:hover': {
            'boxShadow': '0 2px 20px 0 rgba(0,0,0,.1)',
        }
    },
}))
