import { Button, Link } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

import Login from './Login'

const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'absolute',
        // width: '80%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 2),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    }
}))


export default function LoginModal({ text, className, ...rest }) {

    const classes = useStyles()
    const [open, setOpen] = React.useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <Button {...rest}
                className={className || classes.login}
                component={Link}
                onClick={() => setOpen(true)}
            // variant={"contained"} color={"secondary"}
            >{text || 'Ingresá'}</Button>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <div className={classes.modal}>
                    <Login />
                </div>
            </Modal>
        </>
    )
}
