import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({

    process: {
        width: '100%',
    },
    header: {
        margin: '5rem 1rem',
        display: 'flex',
    },
    header_image: {
        width: '100%',
        maxWidth: '500px',
        margin: 'auto',
    },
    boxCart: {
        padding: 10,
        margin: '0 auto',
        maxWidth: 1000,
        borderRadius: 10,
        backgroundColor: '#fff'
    },
    cartBox: {
        minHeight: '660px',
        padding: 10,
        margin: '0 auto',
        maxWidth: 1000,
        borderRadius: 10,
        backgroundColor: '#fff',
    }
}))
