import { faMinusCircle, faPlusCircle, faShoppingCart, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import { makeStyles } from '@material-ui/core/styles'
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant'
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff'
import classNames from 'classnames'
import React, { useState } from 'react'

import { useCartContext, useCartDataSetContext } from '../../context/CartContext'
import { useUserData, useUserDataSet } from '../../context/UserContext'
import { FieldValue } from '../../firebase'
import LoginModal from '../loginModal/Modal'

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 280,
        margin: 'auto',
        marginTop: 15,
        position: 'relative',
        zIndex: 1,
        padding: '0',
    },
    addCart: {
        textTransform: 'none',
        height: '45px',
        width: '100%',
        fontWeight: '500',
        margin: '0 10px 10px 10px',
        [theme.breakpoints.down(480)]: {
            fontSize: '0.65rem',
            minWidth: '50px',
        },
    },
    btnBuyed: {
        'borderRadius': '10px',
        // "backgroundColor": "#ffc80a",
        'border': 'none',
        height: '45px',
        color: '#fff',
        backgroundColor: '#4A4A4A',
        'textAlign': 'center',
        'fontSize': '20px',
        'padding': '6px',
        'width': '100%',
        'marginLeft': '10px',
        'marginRight': '10px',
        'margin': '8px',
        'transition': 'all 0.5s',
        'fontWeight': '500',
        'display': 'table',
        'tableLayout': 'fixed',
        'borderColor': '#0275d8',
        'borderWidth': 'thin',
        'borderStyle': 'solid',
        '& > div': {
            'display': 'table-cell',
            'verticalAlign': 'top',
            'textAlign': 'center'
        }
    },
    nostockContainer: {
        'display': 'flex',
        'flexDirection': 'row',
        'justifyContent': 'center',
        'gap': '10px',
        'alignItems': 'center',
        'width': '100%',
    },
    nostockNotification: {
        'display': 'block',
        'backgroundColor': '#fcf8e3',
        'color': '#8a6d3b',
        'width': '100%',
        'borderColor': '#faebcc',
        'padding': '8px',
        'border': '1px solid transparent',
        'borderRadius': '15px',
        'textAlign': 'center',
        'marginBottom': '5px',
        'transition': 'all 0.3s ease-in-out',
        '&:hover': {
            'cursor': 'pointer',
            'backgroundColor': '#ede4b4',
            'color': '#525252',
        }
    },
    nostockNotificationQuit: {
        'display': 'block',
        'backgroundColor': '#c888835c',
        'color': '#7e5855',
        'width': '100%',
        'padding': '8px',
        'border': '1px solid transparent',
        'borderRadius': '15px',
        'textAlign': 'center',
        'marginBottom': '5px',
        'transition': 'all 0.3s ease-in-out',
        '&:hover': {
            'cursor': 'pointer',
            'backgroundColor': '#791a1378',
            'color': '#fafafa',
        }
    },
    nostock: {
        'backgroundColor': '#fdfdfb',
        'color': '#8a6d3b',
        'borderColor': '#faebcc',
        'padding': '8px',
        'border': '1px solid transparent',
        'borderRadius': '15px',
        'width': '100%',
        'textAlign': 'center',
        'alignContent': 'center',
        // "height": "41px",
        // "marginBottom": "10px",
    },
    loading: {
        'backgroundColor': '#b2b2b0',
        'color': '#090808',
        'borderColor': '#2f2e2b',
        'padding': '8px',
        'border': '1px solid transparent',
        'borderRadius': '15px',
        'width': '100%',
        'textAlign': 'center',
        'height': '41px',
        'marginBottom': '10px',
    },
    addCartButton: {
        cursor: 'pointer',
    }
}))

export default function ProductAggregation({ product, onlyActions, style = {}, canAddToCart }) {

    const classes = useStyles()

    const { isAnonymous, letmeknow } = useUserData()

    const userDataSet = useUserDataSet()

    const prevAlerts = !!letmeknow?.[product.objectID]

    const stockHandler = () => isAnonymous === false && userDataSet({
        algoliaUpdated: false,
        ['letmeknow.' + product.objectID]: prevAlerts ? FieldValue.delete() : true
    }, 'update')

    const cart = useCartContext()
    const inCart = ((cart && product && cart[product.objectID] && cart[product.objectID].q) || 0)
    const cartUpdate = useCartDataSetContext()
    const [canAdd, setCanAdd] = useState(true)

    const handleAvailableStock = (qty) => {
        // product.available ? setAvailableStock(product.available) : setAvailableStock(0)
        if (product.available) {
            if (product.available > qty || inCart !== product.available) {
                setCanAdd(true)
                canAddToCart(true)
            } else {
                setCanAdd(false)
                canAddToCart(false)
            }
        }
    }

    //<span className={classes.productStock}>No hay más unidades disponibles para agregar</span>
    // style={{pointerEvents: !canAddToCart ? 'none' : 'auto'}}

    const actions = product.objectID ? !product.nostock ?
        !inCart
            ? <Button style={{ margin: '8px' }}
                onClick={() => {
                    cartUpdate({ product: product, q: 1 }), handleAvailableStock(1)
                }}
                endIcon={<FontAwesomeIcon icon={faShoppingCart} />}
                className={classNames([classes.addCart, 'fillAvailable'])}
                variant={'outlined'}>Agregar al</Button>
            : <div style={style}
                className={classNames([classes.btnBuyed, 'fillAvailable'])}>
                <div onClick={() => {
                    cartUpdate({ product: product, q: inCart - 1 }), handleAvailableStock(inCart - 1)
                }}
                    className={classes.addCartButton}><FontAwesomeIcon icon={inCart === 1 ? faTrash : faMinusCircle} /></div>
                <div>{inCart}</div>
                {canAdd ? <div onClick={() => {
                    cartUpdate({ product: product, q: inCart + 1 }), handleAvailableStock(inCart + 1)
                }}
                    className={classes.addCartButton}><FontAwesomeIcon icon={faPlusCircle} /></div>
                    :
                    <div style={{ pointerEvents: 'none', opacity: '0.5' }}
                        className={classes.addCartButton}><FontAwesomeIcon
                            icon={faPlusCircle}
                            style={{ opacity: '0.5' }} /></div>}
            </div>

        :
        <div className={classes.nostockContainer}>
            {isAnonymous ?
                <LoginModal fullWidth
                    style={{ textAlign: 'center' }}
                    text={<div className={classes.nostockNotification}>AGOTADO AVISARME
                        STOCK <NotificationImportantIcon style={{ fontSize: '18px', paddingTop: '3px' }} />
                    </div>} />
                :
                prevAlerts ?
                    <>
                        <button className={classes.nostockNotificationQuit}
                            onClick={() => stockHandler()}>AGOTADO <br />QUITAR AVISO DE STOCK <NotificationsOffIcon
                                style={{ fontSize: '18px', paddingTop: '3px' }} /></button>
                    </>
                    :
                    <>
                        <button className={classes.nostockNotification}
                            onClick={() => stockHandler()}>AGOTADO <br />AVISARME STOCK <NotificationImportantIcon
                                style={{ fontSize: '18px', paddingTop: '3px' }} /></button>
                    </>

            }

        </div>
        : <div className={classes.loading}></div>

    if (onlyActions) return actions

    return (<> {!product.autoItem &&
        <CardActions style={{ padding: '0', margin: '0' }}
            className={'fillAvailable'}
            disableSpacing
            onClick={e => {
                e.preventDefault()
            }}>
            {actions}
        </CardActions>
    }
    </>
    )
}
