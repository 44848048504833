import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useParams } from 'react-router-dom'

import { demoOrder } from '../MyOrders'
import useStyles from './styles.js'


export default function OrderDetails() {
    let { orderId } = useParams()
    const order = demoOrder
    const classes = useStyles()

    return <div className={classes.root}>
        <Typography component={'h2'}>Detalles de Compra {orderId}</Typography>
        <div className={classes.orderNumber}>546757</div>
        <div className={classes.state}>{order.status}</div>
        <div className={classes.dir}>{order.data.address.formatted_address}</div>
        <div className={classes.date}>{new Date(order.timestamp._seconds * 1000).toLocaleString()}</div>
        <div className={classes.quantity}>7689</div>
        <div className={classes.total}>${order.total}</div>
    </div>
}
