import * as React from 'react'
import Carousel from 'react-multi-carousel'
import { Link } from 'react-router-dom'

import { useMarketContext } from '../../context/MarketContext'
import imgurWebp from '../Utils/imgurWebp'

export default function Brands() {
    const { brands } = useMarketContext()
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 6,
            slidesToSlide: 6
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
            slidesToSlide: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 4,
            slidesToSlide: 4
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            slidesToSlide: 2
        },
    }

    return !Object.values(brands || {})?.filter(b => b.image).length ? <></> : (
        <>
            <div style={{ width: '100%', margin: 'auto', maxWidth: 1450 }}>
                <h3 style={{ textAlign: 'center' }}>Nuestras Marcas</h3>
                <Carousel centerMode={true}
                    responsive={responsive}
                    infinite={true}
                    slidesToSlide={5}
                    speed={500}>
                    {
                        Object.values(brands)
                            ?.filter(b => b.image_bw && b.active)?.sort((a, b) => (a.ranking || 1000) - (b.ranking || 1000)).map(brand =>
                                <Link key={brand.objectID}
                                    to={`/app/products?brand=${brand.objectID}`}>
                                    <img key={brand.objectID}
                                        alt={brand.name}
                                        style={{ width: '100px', height: '100px', borderRadius: '100px', position: 'relative', right: 50 }}
                                        src={imgurWebp(brand.image_bw)} />
                                </Link>)
                    }
                </Carousel>
            </div>
        </>
    )
}
