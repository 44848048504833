import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid } from '@material-ui/core'
import React from 'react'

import config from '../../config'
import useStyles from './styles'

export default function Footer() {
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <Grid container>
        <Grid item
          sm={12}
          className={classes['contenedor_footer']}>
          <Grid container>
            <Grid item
              xs={11}
              sm={4}
              className={classes['logo_footer']}>
              <img alt={'vivet'}
                width="120"
                src={config.logo} />
              <a
                href="http://qr.afip.gob.ar/?qr=kXRzdOrELSz3cgAk8VwCGw,,"
                target="_F960AFIPInfo"
                style={{ marginLeft: '10px' }}
              >
                <img
                  alt={'LOGO QR AFIP'}
                  src="https://i.imgur.com/1g3x8o0_d.webp?maxwidth=200&shape=thumb&fidelity=high"
                  border="0"
                  width="30"
                />
              </a>
            </Grid>

            <Grid item
              xs={11}
              sm={4}
              className={classes['descripcion_footer']}>
              <h2>Conectando Conciencia</h2>
            </Grid>

            <Grid item
              xs={11}
              sm={4}
              className={classes.social}>
              <a href="https://www.facebook.com/vivetmarket"
                target="_blanck">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a href="https://www.instagram.com/vivetmarket/"
                target="_blanck">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="https://www.linkedin.com/company/v%C3%ADvet/"
                target="_blanck">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <div className={classes['text_social']}>
                <p>
                  Seguinos en <br /> nuestras redes
                </p>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item
          xs={12}
          className={classes.copy}>
          <p>
            <span className={classes.copy_text}>
              2024 © Plant Todos los derechos reservados
            </span>
          </p>
        </Grid>
      </Grid>
    </footer>
  )
}
