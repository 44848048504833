import { Grid } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React from 'react'

import Brands from '../../components/Brands/Brands'
import Categories from '../../components/Categories/Categories'
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop'
import { useChangeTitle } from '../../components/Utils/useChangeTitle'
import config from '../../config/config'
// components
import { useMarketContext } from '../../context/MarketContext'
import { usePriceListIDContext } from '../../context/PriceListContext'
import { useUserData } from '../../context/UserContext'
import HomepageElement from './components/Elements'
import {ModalNotification} from './components/ModalNotification'
// styles
import useStyles from './styles'

export default function Homepage() {
  const classes = useStyles()
  const { homepage } = useMarketContext()
  const { isAnonymous } = useUserData()
  const isAuthenticated = !isAnonymous
  const priceListID = usePriceListIDContext()
  const min600 = useMediaQuery('(min-width:600px)')
  config?.plant_web_view === true ? useChangeTitle('Plant') : useChangeTitle('Plant business')

  let elements =
    homepage &&
    homepage.elements &&
    homepage.elements.filter(
      (el) =>
        !!(
          (!el.allPriceListExcept || el.allPriceListExcept !== priceListID) &&
          (el.allPriceListExcept || !el.priceList || el.priceList === priceListID) &&
          ((el.mobile && !min600) || (el.desktop && min600) || (!el.desktop && !el.mobile)) &&
          (!el.startTimeStamp || el.startTimeStamp < ((Date.now() / 1000) | 0)) &&
          (!el.endTimeStamp || el.endTimeStamp > ((Date.now() / 1000) | 0))
        ),
    )

  const modalNotificationHit = homepage?.modalNotification?.find(v=>
    (!v.priceList || v.priceList === config.defaultPriceList) &&
    (v.timestamp_start < ((Date.now() / 1000) | 0)) &&
    (v.timestamp_end > ((Date.now() / 1000) | 0)) &&
    (!isAuthenticated === !v.isAuthenticated || v.isAuthenticated === undefined)
  )


  return (
    <>
      <ScrollToTop />
      {modalNotificationHit && <ModalNotification hit={modalNotificationHit}/>}
      {elements?.[0] && (
        <El
          el={elements?.[0]}
          classes={classes}
        />
      )}
      <div className={classes.wrapperElementsCarrousels}>
        {elements?.slice(1)?.map((el, i) => (
          <El
            key={i}
            el={el}
            classes={classes}
          />
        ))}
      </div>
      <Brands />
      <Categories />
    </>
  )
}

function El({ el, classes }) {
  const gs = el.gridSize || {}
  return (
    <Grid
      item
      xs={12}
      sm={gs.sm || 12}
      md={gs.md || gs.sm || 12}
      lg={gs.lg || gs.md || gs.sm || 12}
      xl={gs.xl || gs.lg || gs.md || gs.sm || 12}
    >
      <HomepageElement
        el={el}
        classes={classes}
      />
    </Grid>
  )
}
