export const formatTextToUrl = (str) => {
  if (!str) return ''

  const space = new RegExp(' ', 'g')
  const per = new RegExp('%', 'g')
  const slash = new RegExp('/', 'g')

  const formattedString = str
    .replace(space, '-')
    .replace(per, '')
    .replace(slash, '-')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()

  return formattedString
}
