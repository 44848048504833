import { Box, Grid, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { makeStyles } from '@material-ui/styles'
import * as React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { useLayoutState } from '../../context/LayoutContext'
import { useMarketContext } from '../../context/MarketContext'
import { usePriceListIDContext } from '../../context/PriceListContext'


const useStyles = makeStyles((theme) => ({
  container: {
  },
  gridStyle: {
    textDecoration: 'none',
    margin: '0.5vw',
    color: 'black',
    maxWidth: '200px',

    [theme.breakpoints.down('md')]: {
      maxWidth: '200px',
      minWidth: '120px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90px',
    },
    '&:hover': {
      color: 'white',
      '& img': {
        filter: ' brightness(50%)'
      }
    },
    cursor: 'pointer',
    backgroundColor: 'rgba(0,0,0,10)',
    boxShadow: '0 1px 1px 0 rgb(0 0 0 / 30%)'
  },
  image: {
    opacity: '0.7',
    width: '%100',
    backgroundColor: 'rgba(0,0,0, 30)',
    filter: 'brightness(90%)',

    [theme.breakpoints.down('md')]: {
      maxWidth: '200px',
      minWidth: '120px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '90px',
      },
    },
  },

  viewMoreButton: {
    marginLeft: 5,
    border: 'none',
    borderRadius: '2.65rem',
    color: '#4A4A4A',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#4A4A4A',
      color: '#fff'
    }
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    height: '100%',
    position: 'relative'
  },

  title: {
    color: '#fff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontWeight: 700,
    fontSize: 21,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: 240,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% - 240px)',
      marginLeft: 240,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: 240,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  center: {
    backgroundColor: theme.palette.background.paper,
    margin: '10px', padding: 5,
    cursor: 'pointer',
    width: '100%',
    maxWidth: 300,
    color: theme.palette.primary.main,
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white'
    }
  },
  buttonView: {
    marginLeft: '10px',
    border: 'none',
    borderRadius: '2.65rem',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#4A4A4A',
      border: 'none',
      cursor: 'pointer',
      borderRadius: '2.65rem',
    }
  },
  icon: {
    fontSize: '1rem',
  }
}))

export default function Categories() {

  const classes = useStyles()
  const [state, setState] = useState({ left: false })

  const { specs } = useMarketContext()
  const priceListID = usePriceListIDContext()

  const alphabeticalOrder = Object.entries(specs || {}).filter(([, a]) => a.name).sort((c, d) => c[1]?.name?.localeCompare(d?.[1].name))
  const { isSidebarOpened } = useLayoutState()

  const items = Object.entries(specs || {}).filter(([, x]) => x.name && x?.image && x?.popular?.[priceListID === 'g' ? 'g' : 'd']).map(([id, x]) => ({ id, ...x }))
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    setState({ ...state, [anchor]: open })
  }
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <h2 style={{ fontSize: '21px', display: 'flex', justifyContent: 'center', color: '#666' }}>CATEGORÍAS</h2>
      <List>
        {alphabeticalOrder.map(([id, x]) =>

          <ListItem disablePadding
            key={id}
            className={classes.center}
            component={Link}
            to={`/app/products?specs=${id}`}>
            <Typography> {titleCase(x.name)} </Typography>
            <ArrowForwardIosIcon className={classes.icon} />
          </ListItem>
        )}
      </List>
    </Box>
  )


  return (
    <>

      <Box style={{ width: '100%', margin: '0 auto', marginBottom: '15px' }}>

        {
          isSidebarOpened == true &&

          <h2 style={{
            fontSize: '21px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '40px'
          }}>
            CATEGORIAS POPULARES {['Ver todas'].map((anchor) => (
              <React.Fragment key={anchor}>
                <Button onClick={toggleDrawer(anchor, true)}
                  className={classes.buttonView}>{anchor}</Button>
                <SwipeableDrawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  onOpen={toggleDrawer(anchor, true)}
                >
                  {list(anchor)}
                </SwipeableDrawer>
              </React.Fragment>
            ))}
          </h2>


        }

        <Grid container
          justifyContent="center"
          spacing={0}
          className={classes.container}>

          {items?.map(x =>
            <Grid component={Link}
              to={`/app/products?specs=${x.id}`}
              className={classes.gridStyle}
              item
              xs={items.length < 8 ? 3 : 3}
              sm={items.length < 8 ? 4 : 3}
              md={items.length >= 8 ? 3 : 4}
              lg={items.length >= 8 ? 2 : 4}
              key={x.id}>
              <Box className={classes.box}>
                <img alt={x.name}
                  src={x.homeImage}
                  className={classes.image} />
                <Typography className={classes.title}>{titleCase(x.name)}  </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  )
}

export function titleCase(str) {
  let splitStr = str.toLowerCase().split(' ')
  for (let i = 0; i < splitStr.length; i++) {
    (splitStr[i].length > 2 || i === 0) && (splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1).toLowerCase())
  }
  return splitStr.join(' ')
}
