import React from 'react'

var ProductsStateContext = React.createContext()
var ProductsDispatchContext = React.createContext()

function productsReducer(state, action) {
  if (action.reset) return {
    facets: [{ name: 'Marcas', field: 'brand', index: 'brands', indexField: 'name' }, { name: 'Categorias', field: 'specs', index: 'specs', indexField: 'name' }],
    filters: [],
    scrollLocation: 0
  }
  if (action.field) return { ...state, scrollLocation: 0, [action.field]: action.value }
  return removeEmpty({ ...state, scrollLocation: 0, ...action })
}

const removeEmpty = (obj) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key])
    else if (obj[key] === undefined) delete obj[key]
  })
  return obj
}

function ProductsProvider({ children }) {
  var [state, dispatch] = React.useReducer(productsReducer, {
    facets: [{ name: 'Marcas', field: 'brand', index: 'brands', indexField: 'name', imageField: 'image', sortingField: 'name' }, { name: 'Categorias', field: 'specs', index: 'specs', indexField: 'name', sortingField: 'ranking' }],
    filters: [],
    scrollLocation: 0
  })
  return (
    <ProductsStateContext.Provider value={state}>
      <ProductsDispatchContext.Provider value={dispatch}>
        {children}
      </ProductsDispatchContext.Provider>
    </ProductsStateContext.Provider>
  )
}

function useProductsState() {
  var context = React.useContext(ProductsStateContext)
  if (context === undefined) {
    throw new Error('useProductsState must be used within a ProductsProvider')
  }
  return context
}

function useProductsDispatch() {
  var context = React.useContext(ProductsDispatchContext)
  if (context === undefined) {
    throw new Error('useProductsDispatch must be used within a ProductsProvider')
  }
  return context
}

export { ProductsProvider, useProductsDispatch, useProductsState }

