/* eslint-disable no-console */
/* eslint-disable no-undef */
import { Button, Popover, TextField, Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import TextsmsIcon from '@material-ui/icons/Assignment'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import React, { useEffect, useState } from 'react'
import { useGoogleMapsApi } from 'react-hook-google-maps'

import { useMarketContext } from '../../../context/MarketContext'
import { checkContainsLocation } from '../../../pages/orderProcess/address'
import NewAddress from '../../../pages/orderProcess/address/NewAddress'
import useStyles from '../styles'

const DirectionUser = ({ storeData, updateStoreData }) => {
  const classes = useStyles()
  const [, setAddressEdit] = useState(false)
  const [, setEditingAddress] = useState(false)
  const [loading, setLoading] = useState(false)
  const marketContext = useMarketContext()
  const [anchorEl, setAnchorEl] = useState(null)
  const [address, setAddress] = useState({})
  const [showLocationPopup, setShowLocationPopup] = useState(false)
  const [showNewAddress, setShowNewAddress] = useState(true)
  const [showMap, setShowMap] = useState(false)
  const [mapAddress, setMapAddress] = useState('')

  const google = useGoogleMapsApi(
    process.env.REACT_APP_MAPS_API + '&libraries=places,geometry,drawing'
  )

  useEffect(() => {
    if (storeData?.address && !showMap) {
      setAddress(storeData?.address)
    }
  }, [storeData.address])

  useEffect(() => {
    const lat = address?.geometry?.location?.lat
    const lng = address?.geometry?.location?.lng
    if (google && lat && lng && marketContext.shippings) {
      let point = new google.maps.LatLng(lat, lng)
      let shippings = checkContainsLocation(point, marketContext.shippings)
      const updatedStoreData = {
        address,
        shippings: shippings.map((s) => s.objectID),
      }
      updateStoreData(updatedStoreData)
      setAddressEdit(false)
    }
  }, [marketContext.shippings, google, address])

  const handleOpen = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(async function (position) {
        const latitude = position.coords.latitude
        const longitude = position.coords.longitude

        setShowMap(true)
        const mapOptions = {
          center: {
            lat: latitude,
            lng: longitude,
          },
          zoom: 15,
          draggable: true,
          draggableCursor: 'grab',
          gestureHandling: 'greedy',
        }

        const mapInstance = new window.google.maps.Map(
          document.getElementById('map-container'),
          mapOptions
        )

        mapInstance.addListener('drag', function () {
          const center = mapInstance.getCenter()
          marker.setPosition(center)
        })

        mapInstance.addListener('dragend', function () {
          const center = mapInstance.getCenter()
          marker.setPosition(center)
          handleMapChange({ lat: center.lat(), lng: center.lng() })
        })

        const markerOptions = {
          position: {
            lat: latitude,
            lng: longitude,
          },
          draggable: false,
          map: mapInstance,
          icon: {
            path: window.google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
            scale: 10,
            fillColor: '#43a047',
            fillOpacity: 1,
            strokeWeight: 0,
          },
        }

        const marker = new window.google.maps.Marker(markerOptions)
        const apiKey = 'AIzaSyDCZba19ghH6HwjbSds1MCr-L4JultfEGY'

        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
        )

        const data = await response.json()

        if (data.status === 'OK') {
          if (data.results[0]) {
            const direccionObtenida = data.results[0].formatted_address

            setMapAddress(direccionObtenida || '')
            setAddress({ ...address, formatted_address: direccionObtenida })

            const updatedAddress = {
              ...address,
              formatted_address: direccionObtenida,
              address_components: data.results[0].address_components,
              geometry: {
                location: {
                  lat: latitude,
                  lng: longitude,
                },
              },
            }
            setAddress(updatedAddress)
            updateStoreData({ address: updatedAddress })
          } else {
            console.log('No se encontraron resultados de geocodificación.')
          }
        } else {
          console.log('Error de geocodificación: ' + data.status)
        }
      })
    } else {
      alert('Geolocalización no soportada por este navegador.')
    }
  }

  const handleMapChange = (markerPosition) => {
    if (markerPosition) {
      const { lat, lng } = markerPosition

      const geocoder = new window.google.maps.Geocoder()
      geocoder.geocode({ location: markerPosition }, (results, status) => {
        if (status === 'OK' && results[0]) {
          const formattedAddress = results[0].formatted_address
          setMapAddress(formattedAddress)
          setAddress({
            ...address,
            formatted_address: formattedAddress,
          })
          const updatedAddress = {
            ...address,
            formatted_address: formattedAddress,
            address_components: results[0].address_components,
            geometry: {
              location: {
                lat: lat,
                lng: lng,
              },
            },
          }
          setAddress(updatedAddress)
          updateStoreData({ address: updatedAddress })
        }
      })
    }
  }

  const handleAutocomplete = async (event) => {
    event.preventDefault()
    setLoading(true)
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        async function (position) {
          setShowMap(true)

          setShowNewAddress(false)
          const latitude = position.coords.latitude
          const longitude = position.coords.longitude
          const mapOptions = {
            center: {
              lat: latitude,
              lng: longitude,
            },
            zoom: 15,
            draggable: true,
            draggableCursor: 'grab',
            gestureHandling: 'greedy',
          }

          const mapInstance = new window.google.maps.Map(
            document.getElementById('map-container'),
            mapOptions
          )

          mapInstance.addListener('drag', function () {
            const center = mapInstance.getCenter()
            marker.setPosition(center)
          })

          mapInstance.addListener('dragend', function () {
            const center = mapInstance.getCenter()
            marker.setPosition(center)
            handleMapChange({ lat: center.lat(), lng: center.lng() })
          })

          const markerOptions = {
            position: {
              lat: latitude,
              lng: longitude,
            },
            draggable: false,
            map: mapInstance,
            icon: {
              path: window.google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
              scale: 10,
              fillColor: '#43a047',
              fillOpacity: 1,
              strokeWeight: 0,
            },
          }

          const marker = new window.google.maps.Marker(markerOptions)
          const apiKey = 'AIzaSyDCZba19ghH6HwjbSds1MCr-L4JultfEGY'

          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
          )

          const data = await response.json()

          if (data.status === 'OK') {
            if (data.results[0]) {
              const direccionObtenida = data.results[0].formatted_address

              setMapAddress(direccionObtenida || '')
              setAddress({ ...address, formatted_address: direccionObtenida })

              const updatedAddress = {
                ...address,
                formatted_address: direccionObtenida,
                address_components: data.results[0].address_components,
                geometry: {
                  location: {
                    lat: latitude,
                    lng: longitude,
                  },
                },
              }
              setAddress(updatedAddress)
              updateStoreData({ address: updatedAddress })
            } else {
              console.log('No se encontraron resultados de geocodificación.')
            }
          } else {
            console.log('Error de geocodificación: ' + data.status)
          }
          setLoading(false)
        },
        function (error) {
          console.log('Error de geolocalización: ' + error.code)
          setShowLocationPopup(true)
          setAnchorEl(event.currentTarget)
          setShowMap(false)
          setLoading(false)
        }
      )
    } else {
      setShowLocationPopup(true)
      setAnchorEl(event.currentTarget)
      setShowMap(false)
      setLoading(false)
    }
  }

  const handleNewAddressChange = (event) => {
    setMapAddress(event.target.value)
  }
  const saveAddressDetail = (value) => {
    if (storeData?.address) {
      const updatedAddress = {
        ...storeData.address,
        details: value,
      }
      setAddress(updatedAddress)
    } else {
      const newAddress = {
        ...defaultAddress,
        details: value,
      }
      setAddress(newAddress)
    }
  }

  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <div style={{ maxWidth: 500 }}>
        {showNewAddress && !showMap && !address.geometry && (
          <div>
            {loading && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CircularProgress style={{ color: 'green' }}
                  size={11} />
                <Typography className={classes.circularProgressText}>
                  Cargando tu ubicación...
                </Typography>
              </div>
            )}

            <Popover
              //className={classes.popover}
              open={showLocationPopup}
              anchorEl={anchorEl}
              onClose={() => setShowLocationPopup(false)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              className={classes.popover}
            >
              {/* //Debes activar la ubicación para utilizar esta función. */}
              <Typography
                style={{
                  background: '#e8f5e9',
                  padding: '5px',
                  fontSize: '11px',
                }}
              >
                Has bloqueado a Vívet para rastrear tu ubicación. Para
                permitirlo, cambia la configuración de ubicación de tu
                navegador.
              </Typography>
            </Popover>
          </div>
        )}
      </div>

      <div style={{ display: showMap ? 'block' : 'none' }}>
        <div id="map-container"
          style={{ height: '195px' }} />
        <p style={{ color: 'green' }}>
          Puedes establecer tu ubicación moviendo el cursor.
        </p>
      </div>

      <div>
        <div>
          {showNewAddress && !showMap ? (
            <div>
              <div
                style={{
                  display: 'flex',
                  gap: 12,
                  maxWidth: 300,
                  alignItems: 'flex-start',
                }}
              >
                {!address.geometry && (
                  <div className={classes.containerAutocomplete}>
                    <Tooltip title="Rellenar automáticamente con tu ubicación actual.">
                      <button
                        className={classes.buttonAutocomplete}
                        onClick={handleAutocomplete}
                        aria-describedby={id}
                      >
                        Autocompletar
                      </button>
                    </Tooltip>
                  </div>
                )}
                <div
                  style={{ display: 'flex', flexDirection: 'column', gap: 6 }}
                >
                  <NewAddress
                    defaultAddress={storeData?.address?.formatted_address}
                    setNewAddressValue={setAddress}
                    autoFocus={false}
                    fullWidth
                    label={
                      <span
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        <LocationOnOutlinedIcon
                          style={{ fontSize: '18px', margin: '5px' }}
                        />
                        Dirección
                      </span>
                    }
                    Template={(props) =>
                      AddressesTemplate({
                        ...props,
                        classes: {},
                      })
                    }
                    onChange={(e) => setEditingAddress(e.target.value)}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.fieldIcons}>
              <TextField
                variant="outlined"
                label={
                  <span style={{ alignItems: 'center', display: 'flex' }}>
                    <LocationOnOutlinedIcon
                      style={{ fontSize: '18px', margin: '5px' }}
                    />
                    Dirección
                  </span>
                }
                fullWidth
                multiline
                value={mapAddress}
                onClick={() => setShowNewAddress(true)}
                onChange={handleNewAddressChange}
              />
            </div>
          )}
        </div>
      </div>
      <div style={{ maxWidth: '500px' }}>
        <div className={classes.fieldIcons}></div>
        {!showMap && address?.geometry?.location?.lat && (
          <img
            alt={'map'}
            src={
              'https://maps.googleapis.com/maps/api/staticmap?zoom=15&size=600x300&maptype=roadmap' +
              '&markers=color:blue%7C%7C' +
              address.geometry?.location.lat +
              ',' +
              address.geometry?.location.lng +
              '&key=' +
              process.env.REACT_APP_MAPS_API
            }
            style={{ width: '90vw', maxWidth: '100%' }}
          />
        )}

        <div>
          {!showMap && (
            <Button
              onClick={handleOpen}
              style={{ color: 'green', display: 'flex', margin: '0 auto' }}
            >
              o establece tu ubicación en el mapa.
            </Button>
          )}
        </div>
      </div>
      <div>
        <div className={classes.fieldIcons}>
          <TextFieldStore
            multiline
            style={{ width: 'stretch' }}
            onKeyPress={(e) => e.key === 'Enter' && e.target.blur()}
            label={
              <span style={{ alignItems: 'center', display: 'flex' }}>
                <TextsmsIcon style={{ fontSize: '18px', margin: '5px' }} />
                Detalles
              </span>
            }
            value={storeData?.address?.details || ''}
            setValue={saveAddressDetail}
            maxRows="4"
            fullWidth
            variant="outlined"
          />
        </div>
        <div>
          <p className={classes.paragraph}>
            Piso, Depto, Local o Cualquier informacion que nos ayude a encontrar
            la dirección.
          </p>
        </div>
      </div>
    </>
  )
}

const useStylesAddressTemplate = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    backgroundColor: 'rgba(0,0,0,0.1)',
    padding: 4,
    paddingLeft: 6,
    borderRadius: 3,
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.2)',
    },
    '& > *': {
      margin: theme.spacing(1) + ', 0',
    },
  },
}))

function AddressesTemplate({
  address,
  details,
  onClick,
}) {
  const classes = useStylesAddressTemplate()

  return (
    <div onClick={onClick}
      className={classes.root}>
      {/* location_list__location__selected">*/}
      <div className={classes.location_list__location__address}>
        <div className={classes.location_list__location__address__text}>
          {address}
        </div>
        <div className={classes.location_list__location__address__details}>
          {details}
        </div>
      </div>
    </div>
  )
}

function TextFieldStore({ value: V, setValue: S, ...props }) {
  const [value, setValue] = useState(V || '')

  useEffect(() => {
    setValue(V || '')
  }, [V])

  return (
    <TextField
      {...props}
      value={value}
      fullWidth
      onChange={(e) => value !== e.target.value && setValue(e.target.value)}
      onBlur={(e) => S && S(e.target.value)}
    />
  )
}

export default DirectionUser
