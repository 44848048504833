import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
    'carrierBox': {
        'display': 'flex',
        'flexDirection': 'column',
        'alignItems': 'flex-start',
        'boxShadow': '1px 1px 1px 1px rgba(0,0,0,0.2)',
        'fontSize': '1rem',
        'cursor': 'pointer',
        '&:hover': {
            'boxShadow': '0 2px 20px 0 rgba(0,0,0,.1)',
        }
    },
    'carrierIcon': {
        'marginRight': '20px'
    }
}))