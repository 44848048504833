import { faMinusCircle, faPlusCircle, faShoppingCart, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import React, { useMemo } from 'react'

// import classNames from 'classnames';
import { useCartContext, useCartDataSetContext } from '../../context/CartContext'
import imgurWebp from '../Utils/imgurWebp'
import { numberWithCommas } from '../Utils/NumberWithCommas'
import DetailPrice from './DetailPrice'
import GetPrices from './Price'

export default function ProductCard({ hit, classes }) {

    const cart = useCartContext()
    const inCart = ((cart && hit && cart[hit.objectID] && cart[hit.objectID].q) || 0)
    const cartUpdate = useCartDataSetContext()

    const content = useMemo(() => <>
        <CardMedia
            className={classes.thumbnail}
            image={imgurWebp(hit.thumbnail)}
            title={hit.name}
        />
        <CardMedia
            className={classes.media}
            image={imgurWebp(hit.image)}
            title={hit.name}
        />

        <CardContent className={classes.cardContent}>
            <div className={classes.titleCard}>
                <Typography align='center'
                    className={classes.title}
                    fontWeight={500}
                    color="primary"
                    component="p">
                    {hit.name.toUpperCase()}
                </Typography>
                <Typography align='center'
                    variant="subtitle2"
                    color="textSecondary"
                    component="p">
                    {hit.quantity ? (hit.quantity + hit.unit) : ''}
                </Typography>
                <Typography align='center'
                    variant="subtitle2"
                    color="textSecondary"
                    component="p">
                    {hit.taste}
                </Typography>
            </div>
        </CardContent>
        <DetailPrice product={hit}
            classes={classes} />
    </>, [hit, classes])


    const actionButtons = <CardActions disableSpacing>
        {!inCart
            ? <Button onClick={() => cartUpdate({ product: hit, q: 1 })}
                endIcon={<FontAwesomeIcon icon={faShoppingCart} />}
                className={classes.addCart}
                variant={'outlined'}>Agregar<span className={classes.addCartOnlyBigScreen}>al</span></Button>
            : <div className={classes.btnBuyed}>
                <div onClick={() => cartUpdate({ product: hit, q: inCart - 1 })}
                    className={classes.addCartButton}><FontAwesomeIcon icon={inCart === 1 ? faTrash : faMinusCircle} /></div>
                <div>{inCart}</div>
                <div onClick={() => cartUpdate({ product: hit, q: inCart + 1 })}
                    className={classes.addCartButton}><FontAwesomeIcon icon={faPlusCircle} /></div>
            </div>
        }
    </CardActions>

    return (<>
        <Card className={classes.root}>
            {content}
            {actionButtons}
        </Card>
    </>
    )
}

// export function PricesContent({hit,classes}) {
//
//     const prices = GetPrices({ product:hit });
//     return <div className={classes.price}>
//                 <span className={classNames('MuiTypography-colorPrimary')}>
//                         ${(prices.price).toFixed(2).replace('.',',')}
//                 </span>
//         {prices.suggested ?
//             <span className={classes.priceSuggested}>
//                         <span>Sugerido al </span><br/><span>Público: </span>
//                     ${(prices.suggested).toFixed(0).replace('.',',')}
//                 </span>
//             :
//             prices.original &&
//             <span className={classes.priceStrikethrough}>
//                     ${(prices.original).toFixed(0).replace('.',',')}
//                 </span>}
//     </div>
// }

/**
 * @return {string}
 */
export function PriceTotalPerProduct({ hit, q, customDiscounts }) {

    const prices = GetPrices({ product: hit, customDiscounts })
    const price = prices.price * q * (1 - prices.discount)
    return !!price && ('$ ' + numberWithCommas(price, price > 1000 ? 0 : 2, 0))
}
