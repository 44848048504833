/* eslint-disable no-undef */
import { isValidPhoneNumber } from 'libphonenumber-js'
import { groupBy, mapValues, pickBy } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'

import config from '../config'
import firebase, { auth, db, FieldValue, useDocumentDataOnce } from '../firebase'

const UserDataContext = React.createContext()
const UserDataSetContext = React.createContext()
const StoresContext = React.createContext()
const SelectedStoreContext = React.createContext()
const SelectedAddressContext = React.createContext()


function UserProvider({ children }) {

    const [userData, setUserData] = React.useState({
        ...JSON.parse(localStorage?.getItem('userData') || '{"isAnonymous": true }'),
        isOnlineData: false
    })
    const [userID, setUserID] = React.useState(localStorage?.getItem('userID') || false)

    const [ipData, setIpData] = useState()

    useEffect(() => {
        let active = true
        fetch('https://ipapi.co/json/')
            .then((response) => {
                return response.json()
            })
            .then((ipData) => {
                active && setIpData({ ...ipData, t: new Date() / 1000 | 0 })
            })
        return () => active = false
    }, [])

    React.useEffect(() => {
        let active = true
        const onAuthStateChanged = auth.onAuthStateChanged(user => {
            user?.uid && localStorage?.setItem('userID', user.uid)

            const logintoken = window.location.search.split('&')?.find(a => a.includes('logintoken='))?.split('logintoken=')?.[1]
            logintoken && !user?.phoneNumber && auth.signInWithCustomToken(logintoken).catch(() => {
                window.history.replaceState('', '', window.location.search.replace(new RegExp(`logintoken=${logintoken}` + '&?'), ''))
            }
            )
            const updateUser = () => {
                if (user && active) {
                    logintoken && window.history.replaceState('', '', window.location.search.replace(new RegExp(`logintoken=${logintoken}` + '&?'), ''))
                    setUserID(user.uid)
                    let userDoc = db.collection('users').doc(user.uid)
                    let e = osBrowser()
                    let update = {}
                    update.os = e.os
                    update.browser = e.browser
                    if (user.phoneNumber) {
                        update.phoneNumber = user.phoneNumber
                        update.modified_date = FieldValue.serverTimestamp()
                        update.algoliaUpdated = false
                        update.isAnonymous = false
                    } else {
                        update.isAnonymous = true
                    }
                    update.market_v = process.env.REACT_APP_MARKET_V
                    update.lastSignIn = FieldValue.serverTimestamp()
                    update.lastHost = window.location.host
                    const campaign = window.location.search.split('&')?.find(a => a.includes('campaign='))?.split('campaign=')?.[1]
                    campaign && (update.campaigns = { [campaign]: FieldValue.serverTimestamp() })
                        && window.history.replaceState('', '', window.location.search.replace(new RegExp(`campaign=${campaign}` + '&?'), ''))
                        && (update.campaignsArray = [campaign])
                    // console.log({ campaign })
                    if (navigator && navigator.userAgent) update.navigator = navigator.userAgent
                    document.referrer && (update.referrer = { [btoa(document.referrer)]: FieldValue.serverTimestamp() })
                    ipData && (update.ipData = ipData)
                    document.cookie && (
                        update.cookie = pickBy(mapValues(groupBy(document.cookie?.split('; ').map(d => ({
                            id: d.charAt(0) == '_' ? d.split('=')[0] : false,
                            d: d.split('=')[1]
                        })), 'id'), ([v]) => ({ d: v.d, t: new Date() / 1000 | 0 })), (v, k) => k !== 'false')
                    )
                    userDoc.set(update, {
                        merge: true
                    })
                }
            }
            !ipData ? setTimeout(updateUser, 3000) : updateUser()
            if (!user && active) {
                !logintoken && auth.signInAnonymously()
            }
        }
        )
        return () => {
            onAuthStateChanged()
            active = false
        }
    }, [ipData])

    React.useEffect(() => {
        let active = true
        let stopListener
        if (userID) stopListener = db.collection('users').doc(userID).onSnapshot(uD => {
            if (active) {
                setUserData(pd => {
                    const data = {
                        ...(uD.metadata.hasPendingWrites && uD.exists ? pd : {}),
                        ...(uD.exists ? uD.data() : { isAnonymous: true }),
                        uid: userID,
                        isOnlineData: pd.isOnlineData || !uD.metadata.hasPendingWrites
                    }
                    localStorage?.setItem('userData', JSON.stringify(data))
                    return data
                })
            }
        })
        return () => {
            active = false
            stopListener?.()
        }
    }, [userID])

    const updateUserData = useCallback((data, type) => {
        if (userID) {
            let userDoc = db.collection('users').doc(userID)
            if (type === 'update') userDoc.update(data)
            else userDoc.set(data, { merge: true })
        }
    }, [userID])

    const [stores, setStores] = React.useState(JSON.parse(localStorage?.getItem('stores') || '[]'))

    React.useEffect(() => {
        let active = true
        const storesSnapshot = !userData.uid || !config.stores ? () => '' : db.collection('stores').where('users', 'array-contains', userData.uid).limit(30).onSnapshot(docs => {
            active && setStores(docs.docs?.map(d => ({
                ...d.data(),
                id: d.id
            })).filter(d => !d.permanently_closed) || [])
            active && localStorage?.setItem('stores', JSON.stringify(docs.docs?.map(d => ({
                ...d.data(),
                id: d.id
            })) || []))
        })
        return () => {
            active = false
            storesSnapshot()
        }
    }, [userData.uid])

    const setSelectedStore = useCallback((storeID) => updateUserData({ selectedStore: storeID }, 'update'), [updateUserData])
    const setSelectedAddress = useCallback((addressID) => updateUserData({ selectedAddress: addressID }, 'update'), [updateUserData])

    const [address] = useDocumentDataOnce('addresses', userData?.selectedAddress)

    React.useEffect(() => {
        if (userData.logintoken) auth.signInWithCustomToken(userData.logintoken)
    }, [userData.logintoken])

    React.useEffect(() => {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container',
            {
                size: 'invisible'
                // other options
            })
    }, [])


    return (<>
        <UserDataContext.Provider value={userData}>
            <UserDataSetContext.Provider value={updateUserData}>
                <StoresContext.Provider value={stores}>
                    <SelectedStoreContext.Provider value={{
                        setSelectedStore,
                        store: (userData?.selectedStore &&
                            stores && stores?.find?.(s => s.validated && s.id === userData.selectedStore)) || stores?.filter?.(s => s.validated)[0]
                    }}>
                        <SelectedAddressContext.Provider value={{
                            setSelectedAddress,
                            address
                        }}>
                            {children}
                        </SelectedAddressContext.Provider>
                    </SelectedStoreContext.Provider>
                </StoresContext.Provider>
            </UserDataSetContext.Provider>
        </UserDataContext.Provider>

    </>
    )
}

function useUserData() {
    var context = React.useContext(UserDataContext)
    if (context === undefined) {
        throw new Error('useUserData must be used within a UserProvider')
    }
    return context
}

function useUserDataSet() {
    var context = React.useContext(UserDataSetContext)
    if (context === undefined) {
        throw new Error('useUserDataSet must be used within a UserProvider')
    }
    return context
}

function useStores() {
    var context = React.useContext(StoresContext)
    if (context === undefined) {
        throw new Error('StoresContext must be used within a UserProvider')
    }
    return context
}

function useSelectedStore() {
    var context = React.useContext(SelectedStoreContext)
    if (context === undefined) {
        throw new Error('SelectedStoreContext must be used within a UserProvider')
    }
    return context
}

function useSelectedAddress() {
    var context = React.useContext(SelectedAddressContext)
    if (context === undefined) {
        throw new Error('SelectedAddressContext must be used within a UserProvider')
    }
    return context
}

export { loginUser, signOut, UserProvider, useSelectedAddress, useSelectedStore, useStores, useUserData, useUserDataSet }

// ###########################################################


function loginUser({
    areaPhone,
    phoneNumber,
    setIsLoading,
    setError,
    userDataSet,
    type,
    setSMSCodeSet,
}) {
    let regex = /[^0-9.]/gi
    const validate011 = (phone) => {
        let phoneFormatted = phone
        let v = phone.split('').some((x, index) => x === '0' && index === 0)

        return v ? phoneFormatted.split('').slice(1).join('') : phone
    }
    const fixedPhoneNumber = '+' + (areaPhone + (areaPhone === '+54' ? '9' : '') + validate011(phoneNumber)).replace(regex, '')

    setIsLoading(true)
    if (isValidPhoneNumber(fixedPhoneNumber)) {
        type === 'whatsapp' && userDataSet({
            phoneNumber: fixedPhoneNumber,
            wcodeSent: false
        })

        type === 'sms' && userDataSet({
            phoneNumber: fixedPhoneNumber
        })
        type === 'sms' && auth
            .signInWithPhoneNumber(fixedPhoneNumber, window.recaptchaVerifier)
            .then(confirmResult => {
                setSMSCodeSet(confirmResult)
                setIsLoading(false)
                // success
            })
            .catch(error => {
                // error
                // eslint-disable-next-line no-console
                console.log(error)
            })

    } else {
        setError(true)
        setIsLoading(false)
    }
}

function signOut() {
    localStorage?.removeItem('userData')
    localStorage?.removeItem('userID')
    auth.signOut()
}


function osBrowser() {

    var module = {
        options: [],
        header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera],
        dataos: [
            { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
            { name: 'Windows', value: 'Win', version: 'NT' },
            { name: 'iPhone', value: 'iPhone', version: 'OS' },
            { name: 'iPad', value: 'iPad', version: 'OS' },
            { name: 'Kindle', value: 'Silk', version: 'Silk' },
            { name: 'Android', value: 'Android', version: 'Android' },
            { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
            { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
            { name: 'Macintosh', value: 'Mac', version: 'OS X' },
            { name: 'Linux', value: 'Linux', version: 'rv' },
            { name: 'Palm', value: 'Palm', version: 'PalmOS' }
        ],
        databrowser: [
            { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
            { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
            { name: 'Safari', value: 'Safari', version: 'Version' },
            { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
            { name: 'Opera', value: 'Opera', version: 'Opera' },
            { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
            { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
        ],
        init: function () {
            var agent = this.header.join(' '),
                os = this.matchItem(agent, this.dataos),
                browser = this.matchItem(agent, this.databrowser)

            return { os: os, browser: browser }
        },
        matchItem: function (string, data) {
            var i = 0,
                j = 0,
                regex,
                regexv,
                match,
                matches,
                version

            for (i = 0; i < data.length; i += 1) {
                regex = new RegExp(data[i].value, 'i')
                match = regex.test(string)
                if (match) {
                    regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i')
                    matches = string.match(regexv)
                    version = ''
                    if (matches) {
                        if (matches[1]) {
                            matches = matches[1]
                        }
                    }
                    if (matches) {
                        matches = matches.split(/[._]+/)
                        for (j = 0; j < matches.length; j += 1) {
                            if (j === 0) {
                                version += matches[j] + '.'
                            } else {
                                version += matches[j]
                            }
                        }
                    } else {
                        version = '0'
                    }
                    return {
                        name: data[i].name,
                        version: parseFloat(version)
                    }
                }
            }
            return { name: 'unknown', version: 0 }
        }
    }

    var e = module.init()
    return e
}
